$screenSize-small: 600px;
$screenSize-medium: 601px;
$screenSize-large: 1200px;

@media only screen and (max-width: $screenSize-small) {
  .DeliverysmallSidebarContainer {
    display: none;

    // @media all and (orientation: landscape) {
    //   display: inline;
    // }
  }

  .DeliverylargeSidebarContainer {
    display: none;
  }
}

@media only screen and (min-width: $screenSize-medium) {
  .DeliverysmallSidebarContainer {
    display: inline;
  }

  .DeliverylargeSidebarContainer {
    display: none;
  }
}

@media only screen and (min-width: $screenSize-large) {
  .DeliverysmallSidebarContainer {
    display: none;
  }

  .DeliverylargeSidebarContainer {
    display: inline;
  }
}
