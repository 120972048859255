.businessSocialMedias {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0px;

  .socialMediaLink {
    color: black;
    text-decoration: none;

    i {
      font-size: 35px;
    }

    .fa-square-facebook {
      color: #3b5998;
    }

    .fa-instagram {
      background-image: linear-gradient(
        45deg,
        #feda75,
        #fa7e1e,
        #d62976,
        #962fbf,
        #4f5bd5
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .tiktokLogo {
      height: 35px;
      width: 35px;
      object-fit: cover;
      object-position: center center;
    }

    .fa-twitter {
      color: #00acee;
    }

    .fa-youtube {
      color: #c4302b;
    }
  }
}
