@import "../../../../mainColorLibrary.scss";

.putContact {
  margin-top: 10px;

  .inputContainer {
    display: flex;
    justify-content: center;

    margin-bottom: 10px;

    label {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      width: 95%;
      max-width: 450px;

      font-size: 17px;

      .inputTitle {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      p {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 14px;

        height: 12px;
        width: 90%;

        margin-top: 2px;
      }

      input {
        background-color: rgb(235, 235, 235);
        border: 1px solid rgb(141, 141, 141);

        height: 30px;
        width: 100%;
        max-width: 400px;

        border-radius: 5px;
      }
    }
  }

  .saveBtnContainer {
    display: flex;
    justify-content: center;

    button {
      background-color: $CTA-color;
      color: white;
      border: 1px solid $CTA-color-darker;

      padding: 5px 15px;
      border-radius: 5px;

      font-size: 17px;

      transition: 120ms;
    }

    button:hover {
      background-color: $CTA-color-hover;
      border: 1px solid $CTA-color-darker-hover;
    }
  }
}
