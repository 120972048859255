@import "../../mainColorLibrary.scss";

.sponsorCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  aspect-ratio: 3/1.8;
  background-color: white;

  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);

  .sponsorCard {
    padding: 10px;
    text-align: center;

    .cardTitleContainer {
      display: flex;
      justify-content: center;

      .sponsorCardTitle {
        text-align: center;
        width: 80%;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .sponsorCardButton {
      background-color: $CTA-color;
      color: white;

      width: fit-content;
      border-radius: 5px;
      padding: 7px 10px;

      text-decoration: none;
      line-height: 30px;

      margin: auto;
      margin-bottom: 20px;
    }
  }
}
