/* helpers/fixed.scss */

.fixed {
  position: fixed;
  will-change: transform;
}

.fixed--bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

/* layout/base.scss */

$bodyFontFamily: sans-serif;
$bodyLineHeight: 1.5;

body {
  font-family: $bodyFontFamily;
  line-height: $bodyLineHeight;
  margin: 0;
}

/* modules/anchor.scss */

$colorWhite: #fff;
$linkDisclaimerMargin: 15px;

.linkDisclaimer {
  margin-left: $linkDisclaimerMargin;
  color: $colorWhite;
  text-decoration: none;
  border-bottom: 2px solid blue;
}

/* modules/button.scss */

$buttonBorderWidth: 0.0625em;
$buttonPaddingHorizontal: 1em;
$buttonPaddingVertical: 0.5em;
$buttonHoverBackgroundColor: rgb(0, 255, 94);

button {
  background-color: transparent;
  border: 0;
  border-radius: 5px;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  padding: 0;
}

.button {
  border-style: solid;
  border-width: $buttonBorderWidth;
  padding: $buttonPaddingVertical $buttonPaddingHorizontal;
  position: relative;
}

.button::after {
  background-color: $buttonHoverBackgroundColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s;
  will-change: opacity;
  z-index: -1;
}

.button:focus::after,
.button:hover::after {
  opacity: 1;
}

/* modules/cookie-disclaimer.scss */

$cookieDisclaimerBackgroundColor: rgb(10, 178, 72);
$cookieDisclaimerColor: #fff;
$cookieDisclaimerFontSize: 0.78rem;
$cookieDisclaimerPadding: 0.78rem;

.cookie-disclaimer {
  background-color: $cookieDisclaimerBackgroundColor;
  color: $cookieDisclaimerColor;
  font-weight: 500;
  font-size: $cookieDisclaimerFontSize;
  opacity: 0;
  padding: $cookieDisclaimerPadding;
  transform: translateY(100%);
  transition: opacity 0.3s, transform 0.5s;
  z-index: 9999;
}

.cookie-disclaimer.is-active {
  opacity: 1;
  transform: translateY(0);
}

.cookie-disclaimer :first-child {
  margin-top: 0;
}

.cookie-disclaimer :last-child {
  margin-bottom: 0;
}

/* modules/media.scss */

$mediaObjectMarginHorizontal: 1em;

.media {
  display: flex;

  &--center {
    align-items: center;
  }

  &__object--left {
    margin-right: $mediaObjectMarginHorizontal;
  }

  &__object--right {
    margin-left: $mediaObjectMarginHorizontal;
    order: 1;
  }

  &__body {
    flex-grow: 1;
  }
}
