@import "../../mainColorLibrary.scss";

$screenSize-small: 700px;

$navbarHeight: 50px;
$navbarMaxWidth: 600px;

// $iconsContainerWidth: 130px;
$iconsContainerWidth-Mobile: 120px;

.navbar {
  position: fixed;
  display: flex;

  align-items: center;

  background-color: $mainColor-1;
  color: white;

  height: $navbarHeight;
  width: 100%;

  z-index: 101;

  font-family: "Oswald", sans-serif;
  font-weight: 200;

  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    width: 100%;

    .sectionLeft {
      display: flex;

      .logoContainer {
        display: flex;
        align-items: center;
        flex: 1;

        color: white;
        text-decoration: none;

        height: $navbarHeight;

        .logoImg {
          height: $navbarHeight - 5px;
          filter: invert(1);
        }

        .titleContainer {
          font-size: 28px;

          .title {
            color: whitesmoke;
          }
        }
      }

      .downloadGb {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid whitesmoke;
        border-radius: 2px;
        font-weight: 500;
        padding: 4px;
        margin: 4px;
        margin-left: 15px;

        @media only screen and (max-width: 1100px) {
          display: none;
        }
      }
    }

    .iconsContainer {
      position: absolute;
      right: 0;
      flex: 5;

      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;

      font-size: 18px;

      height: $navbarHeight;

      margin-right: 5px;

      &.show {
        width: 100%;
      }

      .iconSearch {
        width: 500px;

        @media only screen and (max-width: 1100px) {
          width: auto;
        }
      }

      .searchwrapper {
        width: 100%;
      }

      .profilePicture {
        $borderRadius: 100%;

        height: 30px;
        width: 30px;

        border-radius: $borderRadius;

        .profilePictureImg {
          height: 100%;
          width: 100%;

          border-radius: $borderRadius;
        }
      }
    }
  }

  .searchBarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    height: 30px;
    width: 100%;

    .searchBarInput {
      background-color: white;
      border: 1px solid rgb(199, 199, 199);
      border-right: 0px solid transparent;

      height: 30px;
      width: 100%;
      max-width: 600px;
      padding: 0px 10px;

      border-radius: 50px;

      &.searchBarInput:focus {
        outline: none;
      }
    }

    .searchBtn {
      color: rgb(84, 84, 84);
      background-color: white;
      border: 0px solid transparent;

      height: 34px;
      width: 34px;

      border-radius: 100px;
    }

    .cancelSearchBtn {
      align-items: center;

      color: black;
      background-color: white;
      border: 0px solid transparent;
      border-top: 1px solid rgb(106, 106, 106);
      border-bottom: 1px solid rgb(106, 106, 106);

      height: 30px;
      min-width: 30px;
      max-width: 30px;
      border-radius: 0px;

      font-size: 18px;
    }

    .searchButtonActive {
      text-align: center;

      $borderRadius: 100%;

      height: 30px;
      width: 30px;

      border-radius: $borderRadius;
    }
  }

  .searchBarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    height: 30px;
    width: 100%;

    .searchBarMobileInput {
      background-color: white;
      border: 1px solid rgb(199, 199, 199);
      border-right: 0px solid transparent;

      height: 30px;
      width: 80%;
      max-width: 600px;
      padding: 0px 10px;

      border-radius: 50px;

      &.searchBarMobileInput:focus {
        outline: none;
      }
    }

    .searchMobileBtn {
      color: rgb(84, 84, 84);
      background-color: white;
      border: 0px solid transparent;

      height: 34px;
      width: 34px;

      border-radius: 100px;
    }

    .cancelSearchBtn {
      align-items: center;

      color: black;
      background-color: white;
      border: 0px solid transparent;
      border-top: 1px solid rgb(106, 106, 106);
      border-bottom: 1px solid rgb(106, 106, 106);

      height: 30px;
      min-width: 30px;
      max-width: 30px;
      border-radius: 0px;

      font-size: 18px;
    }

    .searchButtonMobileActive {
      text-align: center;

      $borderRadius: 100%;

      height: 30px;
      width: 30px;

      border-radius: $borderRadius;
    }
  }

  .mobileSearchbarContainer {
    $_screenSize: $screenSize-small + 1;

    @media only screen and (min-width: $_screenSize) {
      display: none;
    }

    display: flex;
    justify-content: center;

    width: 70%;

    z-index: 101;

    .wrapper {
      width: 100%;
    }

    .searchBar {
      border: 1px solid transparent;

      height: 30px;
      width: 80%;
      max-width: $navbarMaxWidth;

      border-radius: 100px;
    }
  }
}

.darkenBackground {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.5);
}
