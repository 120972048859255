.empty-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-animation {
  width: 100px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
