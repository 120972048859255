.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  .loadingContainer {
    font-weight: 500;
  }
}

.Spinning {
  animation: spin 1s infinite linear;
}

.fa-spinner {
  animation: spin 1s infinite linear;
  font-size: 2em;
  color: #2d6e8a;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
