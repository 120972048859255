.contentDriverPage {
  .TitlePage {
    font-size: 2em;
  }

  .sectionCard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.wrapperCardDriver {
  margin: 5px;
  padding: 5px;
  .contentCard {
    padding: 6px;
    max-width: 300px;
    height: 150px;

    user-select: none;

    border-radius: 5px;

    background-color: rgb(191, 212, 214);
    .sectionTop {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .contentImagePorfile {
          display: flex;
          align-items: center;
          img {
            border-radius: 4px;
            width: 50px;
            object-fit: cover;
          }
        }

        .contentName {
          font-weight: bold;
          font-size: 15px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;

        .status {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .fa-circle {
            margin-left: 5px;
            margin-right: 5px;
          }
        }

        .ordersNum {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .ordersLabel {
            font-size: 15px;
          }

          .num {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 1.2em;
          }
        }
      }
    }

    .sectionBottom {
      margin-top: 5px;
      .hourService {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          font-size: 16px;
        }
        .formatDate {
          margin-left: 5px;
          margin-right: 5px;
          font-size: small;
        }
      }
    }
  }
}
