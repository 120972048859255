@import "./mainColorLibrary.scss";

@mixin Button($bg-color, $txt-color, $borderColor) {
  background-color: $bg-color;

  color: $txt-color;
  text-decoration: none;
  font-size: 17px;

  border: 1px solid $borderColor;
  border-radius: 5px;

  padding: 5px 8px;

  transition: 100ms;
}

@mixin skeletonAnimation() {
  @keyframes example {
    0% {
      background-color: $skeleton-bg-color;
    }
    10% {
      background-color: $skeleton-bg-color;
    }
    50% {
      background-color: rgb(199, 199, 199);
    }
    90% {
      background-color: $skeleton-bg-color;
    }
    100% {
      background-color: $skeleton-bg-color;
    }
  }

  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@mixin textSkeletonPrefab($_height: 16px, $_width: 10px) {
  background-color: $skeleton-bg-color;
  border-radius: 5px;

  height: $_height;
  width: $_width;

  @include skeletonAnimation;
}
