@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

.quantitySelector {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-left: 10px;

  .quantityBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: transparent;
    color: $CTA-color-2;
    border: 2px solid $CTA-color-2;
    border-radius: 3px;

    width: 60px;
    padding: 5px 0px;

    font-size: 14px;

    cursor: pointer;
    transition: background-color 120ms;

    &:hover {
      background-color: $CTA-color-2-hover;
    }

    .amountNumberTxt {
      margin-left: 10px;
    }

    .iconsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      color: $CTA-color-2;

      width: 5px;

      margin-right: 10px;

      .fa-caret-up {
        margin-bottom: -3px;
      }
      .fa-caret-down {
        margin-top: -3px;
      }
    }
  }

  // =================================================
  // =================================================

  .overlayActive {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    .background {
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
    }

    .modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: #f2f2f2;
      border: 1px solid rgb(204, 204, 204);
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.5);

      width: 90%;
      max-width: 320px;
      padding: 8px;
      border-radius: 4px;

      .topSectionContainer {
        display: flex;
        flex-direction: column;
        height: 50px;
        margin-bottom: 10px;

        .titleSectionContainer {
          display: flex;
          justify-content: center;

          max-width: 320px;
          width: 100%;

          .title {
            display: flex;
            justify-content: center;

            font-size: 20px;
            font-weight: bold;
          }
        }

        .quantityAmountText {
          display: flex;
          justify-content: center;

          color: rgb(0, 0, 0);

          width: 100%;

          font-size: 14px;
          font-weight: bold;
        }
      }

      .inputContainer {
        display: flex;
        justify-content: center;

        margin-bottom: 20px;

        .inputUnits {
          text-align: center;
          font-size: 26px;

          border: 1px solid gray;

          padding: 10px 0px;
          border-radius: 5px;
        }
      }

      .saveBtn {
        @include Button($CTA-color, white, $CTA-color);

        width: 100%;
        margin-top: 20px;
      }

      .amountBtnContainer {
        border-bottom: 1px solid grey;

        .content-button-units {
          .btn-units {
            display: inline-block;

            color: #555;
            border: none;
            border-top: 1px solid grey;

            width: 100%;
            padding: 8px 0px;

            font-size: 14px;

            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
              background-color: #e0e0e0;
            }
          }
        }
      }

      .addMoreUnitsContainer {
        margin-top: 20px;

        .addMoreUnitsBtn {
          @include Button($CTA-color, white, $CTA-color);

          width: 100%;
          padding: 8px 0px;

          font-size: 15px;
        }
      }
    }
  }
}
