@import "../../mainColorLibrary.scss";

.allOrderUsers {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .allOrderCardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    width: 100%;
    max-width: 500px;

    margin-top: 10px;

    .notOrders {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }

    .orderCardContainer {
      background-color: white;
      border: 1px solid lightgray;

      width: 100%;
      max-width: 500px;
      padding: 5px;

      border-radius: 7px;

      margin: 5px;
      margin-bottom: 10px;

      .orderCard {
        color: black;
        text-decoration: none;

        width: 100%;

        .topSection {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;

          .orderUserName {
            font-size: 18px;
            font-weight: 500;
          }
        }

        .informationDisplay {
          display: flex;
          text-transform: capitalize;

          .informationTitle {
            color: black;
            font-size: 16px;
          }

          .informationText {
            font-weight: 500;
            margin-left: 5px;
            margin-right: 5px;
          }

          .status__processed {
            color: #1eb4c8;
            font-weight: 500;
          }

          .status__delivered {
            color: #46a836;
          }

          .status__delivery_failed,
          .status__cancelled,
          .status__declined {
            color: #dd4b4b;
          }

          .status {
            font-weight: 500;
            margin-left: 5px;
            margin-right: 5px;
          }

          .loadType {
            font-weight: 500;
            margin-left: 5px;
            margin-right: 5px;
          }

          .keyWord {
            font-weight: 500;
            margin-left: 5px;
            margin-right: 5px;
            color: $CTA-color-3;
            text-decoration: underline;
          }

          .dateTxt {
            font-size: 15px;
            font-weight: 500;
            margin-left: 5px;
            margin-right: 5px;
          }
        }

        .orderItems {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
          place-items: center;
          gap: 5px;

          margin-top: 15px;

          .productImgContainer {
            width: 100%;
            aspect-ratio: 12/16;

            .productImg {
              height: 100%;
              width: 100%;
              border-radius: 5px;
              object-fit: cover;
            }

            // .itemAmount {
            //   min-width: 70px;
            // }

            // .itemName {
            //   margin-left: 10px;
            //   width: 100%;
            // }

            // .itemPrice {
            //   max-width: 85px;
            //   padding-left: 10px;
            // }

            // .currency {
            //   margin: 2px;
            //   margin-left: 5px;
            //   font-size: 12px;
            // }
          }
        }

        .orderTotal {
          margin-top: 10px;
          font-size: 16px;

          color: grey;

          .totalPrice {
            color: black;
            font-size: 18px;
          }
        }
      }
    }
  }

  .loadMoreBtnContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    margin-bottom: 10px;

    .loadMoreBtn {
      background-color: $CTA-color;
      color: white;

      border: 1px solid $CTA-color;
      border-radius: 5px;

      padding: 5px 10px;

      transition: 100ms;
    }

    .loadMoreBtn:hover {
      background-color: $CTA-color-hover;
      color: white;

      border: 1px solid $CTA-color-darker-hover;
      border-radius: 5px;

      padding: 5px 10px;
    }
  }
}
