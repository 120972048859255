@import "../../../mainColorLibrary.scss";

.contentButtonFilter {
  white-space: nowrap;
  position: relative;
  text-align: center;

  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  .selectedStatus {
    padding: 6px;
    background: $delivery-CTA-color;
    color: whitesmoke;
    font-weight: 500;
    width: 150px;
    margin: 5px 10px 5px 10px;
  }

  .btnFilter {
    padding: 6px;
    background: $delivery-CTA-color-darker-hover;
    color: white;
    font-weight: 500;
    width: 150px;
    margin: 5px 10px 5px 10px;
  }
  .btnFilter:hover {
    background: $delivery-CTA-color-hover;
  }
}

.ordersDeliveredOrFailed {
  .pageTitle {
    font-weight: 500;
    font-size: 22px;

    padding: 10px 0px;
    margin-left: 5px;
  }

  .cardsWrapper {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    transition: opacity 0.2s ease;

    margin: 5px;

    .orderCard {
      background-color: #ecebe7;
      border: 1px solid #c9c7b9;
      box-shadow: -2px 2px 5px rgba(122, 119, 102, 0.4);

      border-radius: 7px;

      padding: 5px;

      transition: 200ms ease;

      &:hover {
        background-color: #dedbd0;
        border: 1px solid #b7b4a2;
      }

      .dateDeliveredContainer {
        font-weight: 500;
        margin-bottom: 5px;
      }

      .infoContainer {
        border-bottom: 1px solid rgb(191, 191, 191);
        padding-bottom: 5px;
        margin-bottom: 5px;

        .infoTxt {
          font-size: 17px;
          font-weight: 500;

          .title {
          }

          .info {
          }
        }
      }

      .customerOrderContainer {
        padding-bottom: 5px;

        .sectionTitle {
          font-size: 20px;
          font-weight: 500;
        }

        .businessContainer {
          .businessName {
            font-size: 18px;
            font-weight: 500;
          }

          .totalCompany {
            font-weight: 500;
          }

          .allItemsContainer {
            margin-left: 10px;

            .productContainer {
              font-weight: 500;
              margin-bottom: 5px;

              .sectionAttributes {
                .wrapperAttributes {
                  .categoryTitle {
                    font-size: large;
                  }
                  .li {
                    display: flex;
                    .attributeName {
                      margin-right: 4px;
                    }
                    .attributePrice {
                      margin-left: 2px;
                    }
                  }
                }
              }

              .productName {
                display: flex;
                align-items: center;
                .text {
                  font-size: 16px;
                  margin-right: 5px;
                }
                .name {
                  font-size: large;
                }
              }

              .productPriceUnit {
              }

              .productExtrCostUnit {
                display: flex;
                align-items: center;

                cursor: pointer;

                margin-bottom: 10px;

                .wrapperIcon {
                  margin-left: 4px;

                  .contentIcon {
                    .fas {
                      font-size: 1.3em;
                    }
                  }
                }
              }

              .productDiscuntUnit {
              }

              .productDiscountContainer {
                .productPrice {
                  color: grey;
                  text-decoration: line-through;
                  cursor: pointer;
                  margin-right: 5px;
                }

                .productDiscount {
                  color: rgb(207, 7, 7);
                  margin-right: 5px;
                }

                .productDiscountedPrice {
                  color: rgb(207, 7, 7);
                }
              }

              .productPrice {
                display: flex;
                text-align: center;

                cursor: pointer;

                .text {
                  margin-right: 5px;
                }

                .count {
                  margin-right: 4px;
                }
                span {
                  margin-right: 4px;
                }
                .formatPrice {
                  margin-right: 2px;
                }
                .currency {
                  margin-left: 1px;
                }
              }
            }
          }
        }
      }

      .descriptionContainer {
        border-top: 1px solid rgb(191, 191, 191);
        padding: 5px 0px;

        .title {
          font-weight: 500;
        }

        .info {
        }
      }

      .transactionId {
        border-top: 1px solid rgb(191, 191, 191);
        padding: 5px 0px;

        .title {
          font-weight: 500;
        }

        .info {
        }
      }
    }
  }

  .backAndNextBtnsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;

    width: 100%;

    margin: 20px 0px;

    .currentPage {
      background-color: rgb(24, 189, 192);
      color: black;
      border: 1px solid grey;
      width: 25px;

      border-left: none;
      border-right: none;
    }

    .currentPageNumber {
      background-color: rgb(230, 230, 230);
      color: black;
      border: 1px solid grey;
      width: 25px;
      margin: 0 1px 0 1px;
    }

    .btnPage {
      background-color: $CTA-color;
      color: white;

      padding: 10px 0px;
      width: 70px;

      font-weight: 500;

      &:hover {
        background-color: $CTA-color-hover;
        color: white;
      }

      &:disabled {
        background-color: rgb(194, 194, 194);
        border: 1px solid rgb(162, 162, 162);
        color: grey;
        cursor: not-allowed;
      }
    }

    .btnPage__left {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .btnPage__right {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .currentPageText {
      background-color: rgb(230, 230, 230);
      color: black;
      border: 1px solid grey;
      margin-left: 5px;
      margin-right: 5px;
      width: 50px;
    }
  }
}
