@import '../../mainColorLibrary.scss';

.content-container {
  padding: 10px;

  .sections-buttons {
    display: flex;

    button {
      padding: 10px 10px;
      margin: 0px 2px;
      background: white;
      border-radius: 5px 5px 0px 0px;
      cursor: pointer;
    }

    .active {
      border-bottom: 2px solid $mainColor-1;
      cursor: default;
    }
  }
}
