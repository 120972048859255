@import "../../../mainColorLibrary.scss";

.serviceDetailsPage {
  .serviceImage {
    height: auto;
    width: 100%;

    max-height: 700px;
    max-width: 700px;

    object-fit: cover;
  }

  .infoSection {
    margin: 0px 10px;

    .topSection {
      width: 95%;

      margin: 0px 5px;
      margin-bottom: 10px;

      .productName {
        font-size: 22px;
        margin-bottom: -5px;
      }

      .visitBusinessLink {
        color: rgb(0, 141, 146);
        font-size: 15px;
        text-decoration: none;
        margin-bottom: 10px;
      }
    }

    .descriptionSection {
      display: flex;

      flex-wrap: wrap;

      max-width: 550px;
      margin-top: 20px;

      .sectionTitleText {
        height: 20px;
        width: 95%;
        max-width: 100%;

        font-size: 18px;

        margin-bottom: 10px;
      }

      .descriptionText {
        width: 95%;
        max-width: 100%;

        font-size: 16px;
      }

      .contactPhoneNumber {
        font-size: 18px;
      }
    }

    .serivceNotAvailable {
      color: red;
      font-size: 18px;
    }

    .ProductExtraOptionsSection {
      margin-top: 20px;
      width: 95%;

      user-select: none;

      .nameSection {
        display: flex;
        align-items: center;

        height: 20px;
        max-width: 100%;

        font-size: 18px;

        margin-bottom: 15px;

        .iconQuestion {
          margin-left: 5px;
          color: rgba(0, 146, 175, 0.5921568627);

          cursor: pointer;
        }
      }

      .descriptionCategoryOption {
        position: absolute;
        padding: 5px;

        border-radius: 2px;

        background-color: #0091af;
        p {
          color: whitesmoke;
          font-weight: lighter;
          font-size: 0.8em;
        }
      }
    }
  }

  @media only screen and (min-width: 700px) {
    display: flex;

    .serviceImage {
      width: 45%;
      margin: 5px;
    }

    .infoSection {
      margin: 5px;
      width: 55%;
    }
  }
}
