@import "../../../mainColorLibrary.scss";

$smallSidebarHeight: 100%;
$smallSidebarWidth: 80px;
$iconFontSize: 25px;

@mixin pageLink() {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  color: white;

  text-decoration: none;

  // height: 40px;
  padding: 5px 0px;
  width: $smallSidebarWidth/1.1;

  border-radius: 8px;

  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .linkIcon {
      font-size: 20px;
    }

    i {
      font-size: $iconFontSize;
    }
  }

  .cartIconContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    i {
      font-size: $iconFontSize;
    }

    .cartTotalItems {
      position: absolute;
      color: $mainColor-1;

      font-size: 12px;
      font-weight: 700;

      margin-left: 3px;
      margin-top: -1px;
    }
  }

  .linkTextContainer {
    display: flex;
    justify-content: center;
    text-align: center;

    width: 100%;

    .linkText {
      font-size: 10px;
    }
  }
}

.smallSidebar {
  margin-top: 50px;

  position: fixed;

  background-color: $mainColor-1;
  color: white;

  border-top: 1px solid transparent;
  // border-top: 1px solid rgb(106, 143, 102);

  height: $smallSidebarHeight;
  width: $smallSidebarWidth;

  z-index: 2;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      // background: rgb(175, 175, 175);
      background-color: #a4ae9e;

      border-radius: 500px;
    }
  }

  .linksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .activePageLink {
      @include pageLink();
      background-color: $mainColor-2;
    }

    .pageLink {
      @include pageLink();
    }
    .pageLink:hover {
      background-color: $mainColor-2;
    }
  }

  .favoritesSectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-top: 1px solid rgba(156, 206, 149, 0.521);
    // padding: 10px 10px;

    margin-top: 5px;
    margin-bottom: 70px;

    .favoritesTitleContainer {
      .titleText {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .favorite {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      color: white;

      width: $smallSidebarWidth/1.1;

      padding-top: 4px;
      border-radius: 5px;

      text-decoration: none;

      margin-left: 5px;
      margin-bottom: 2px;

      &:hover {
        background-color: $mainColor-2;
      }

      .imageContainer {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 5px;

        overflow: hidden;

        img {
          width: 65px;
          aspect-ratio: 3/1.8;

          object-fit: cover;
          object-position: center center;

          text-shadow: -1px 1px 2px rgb(0, 0, 0);
          font-size: 12px;
          font-style: italic;
        }

        .closedTextContainer {
          position: absolute;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;
          width: 100%;

          background-color: rgba(0, 0, 0, 0.6);

          .closedText {
            transform: rotateZ(-8deg);

            background-color: rgba(255, 255, 255, 0.15);
            text-shadow: -1px 1px 2px black;
            border: 2px solid white;
            padding: 0px 4px;

            font-size: 12px;
          }
        }
      }

      .companyName {
        font-size: 11px;

        // margin: 2px 0px 5px 0px;

        text-align: center;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .companyLocation {
        font-size: 10px;

        text-align: center;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .activePageLink {
      background-color: $mainColor-2;
    }

    .seeMore {
      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: $mainColor-1;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 10px;

      width: $smallSidebarWidth/1.1;
      padding: 3px 0px;
      border-radius: 5px;

      margin-top: 10px;
      margin-bottom: 5px;

      &:hover {
        background-color: $mainColor-2;
      }

      :disabled {
        cursor: not-allowed;
      }

      div {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;

        font-size: 12px;
      }

      i {
        font-size: 16px;
      }
    }
  }
}
