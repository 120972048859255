@import "../../mainColorLibrary.scss";

.orderDriver {
  display: flex;
  justify-content: center;

  margin: 0px 5px;

  .pageContentContainer {
    width: 100%;
    max-width: 500px;

    .pageTitle {
      text-align: center;
    }

    .userInfoSection {
      margin-bottom: 30px;

      .dateOrdered {
        font-size: 18px;

        span {
          font-weight: 500;
        }
      }

      .totalPrice {
        font-size: 20px;
        font-weight: 500;

        margin: 10px 0;
      }

      .infoBlock {
        margin-bottom: 10px;

        .subtitle {
          font-size: 14px;
          font-weight: 500;
        }
        .infoText {
          font-size: 17px;

          span {
            font-weight: 500;
          }

          div {
            font-weight: 500;
          }

          .containerLink {
            display: flex;
            justify-content: flex-start;
            margin: 2px;

            a {
              margin-left: 10px;
              margin-right: 10px;

              text-decoration: none;

              .iconContainer {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 40px;
                width: 40px;

                border-radius: 7px;
              }

              .phoneIconContainer {
                background-image: linear-gradient(
                  to bottom,
                  #0073a7,
                  #006d9e,
                  #006695,
                  #00608c,
                  #005a83
                );

                text-shadow: -2px 1px 8px rgba(0, 0, 0, 0.5);

                .fa-phone {
                  color: white;
                  font-size: 22px;

                  transition: 100ms;
                }

                &:hover {
                  text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.75);

                  .fa-phone {
                    font-size: 26px;
                  }
                }
              }

              .whatsAppIconContainer {
                background-image: linear-gradient(
                  to bottom,
                  #2af314,
                  #28e813,
                  #26de13,
                  #25d312,
                  #23c911
                );

                text-shadow: -1px 2px 8px rgba(0, 0, 0, 0.5);

                .fa-whatsapp {
                  color: white;
                  font-size: 30px;
                  transition: 100ms;
                }

                &:hover {
                  text-shadow: -2px 3px 8px rgba(0, 0, 0, 0.75);

                  .fa-whatsapp {
                    font-size: 34px;
                  }
                }
              }
            }
          }
        }

        .additionalInsturctions {
          .instructionsText {
            font-weight: 400;
          }

          .readMoreBtn {
            color: black;
            background-color: transparent;
            border: transparent;

            font-weight: 550;
            font-size: 16px;

            margin-left: 5px;
          }
        }

        .marginBottom {
          margin-bottom: 10px;
        }

        .infoTextLarge {
          font-size: 20px;
        }
      }
    }

    .selectedProductModal {
      .background {
        position: fixed;
        top: 0;
        left: 0;

        background-color: rgba(0, 0, 0, 0.6);

        height: 100vh;
        width: 100vw;

        z-index: 100;
      }

      .content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        overflow-y: auto;

        background-color: white;

        width: 94%;
        max-width: 400px;

        height: 58%;
        border-radius: 5px;

        z-index: 101;

        .imgContainer {
          height: 200px;
          width: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .infoContainer {
          padding: 5px;

          .productName {
            font-size: 18px;
          }

          .productPrice {
            .discountPrice,
            .normalPrice {
              display: flex;

              .price {
                margin: 0 5px 0 0;
              }

              .contentPorcentageDisocunt {
                .porcentage {
                  margin-left: 5px;
                  color: rgb(20, 174, 20);
                }
              }
            }
          }

          .totalPrice {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 500;
          }

          .contentChangeStatus {
            .line {
              border-top: 1px solid rgb(252, 130, 130);
            }

            .labelDangerZone {
              color: red;
              font-size: large;
            }
            .formItemStatus {
              display: flex;
              align-items: center;
              margin-top: 4px;

              select {
                width: 150px;
                padding: 8px;
                border: 1px solid red;
                border-radius: 4px;
                background-color: #fff;
                font-size: 14px;
                color: #333;

                background-repeat: no-repeat;
                background-position: right 8px top 50%;
                background-size: 12px 12px;
              }
              button {
                margin-left: 8px;
                padding: 8px 16px;
                border: none;
                border-radius: 4px;
                background-color: $danger-color-2;
                color: white;
                font-size: 14px;
                cursor: pointer;
                transition: background-color 0.3s ease;
              }
              button:hover {
                background-color: #0056b3;
              }
              button:disabled {
                background-color: #fdaaaa;
              }

              p {
                font-style: italic;
                margin: 0;
                padding-left: 20px;
                border-left: 4px solid #ccc;
              }
            }
          }

          .contentStatus {
            display: flex;
            align-items: center;
            margin-top: 8px;
            label {
              font-size: 18px;
              margin-right: 8px;
              font-weight: 500;
            }
            .itemStatus {
              color: #005a83;
              font-size: large;
            }
          }
        }
      }

      .sectionAttribute {
        .contentTitleSection {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;

          margin-top: 5px;
          .titleSection {
            font-size: 22px;
          }
        }

        .wrapperAttributes {
          .contentAttribute:nth-child(odd) {
            background-color: $deliveryMainColor-3;
            color: black;
          }

          .contentAttribute:nth-child(even) {
            background-color: $deliveryMainColor-2;
            color: black;
          }

          .contentAttribute {
            .categoryTitle {
              font-size: 20px;
            }

            .li {
              display: flex;
              .attributeName {
                margin-right: 5px;
                font-size: 18px;
              }
              .attributePrice {
                margin-left: 2px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .companySection {
      margin-bottom: 30px;

      .header {
        background-color: $deliveryMainColor-2;
        color: white;
        padding: 5px;

        .title {
          text-align: center;
          font-size: 20px;
          font-weight: 500;
        }

        .bottomInfo {
          display: flex;
          justify-content: space-between;

          font-size: 18px;
          font-weight: 500;
        }
      }

      .companyDetails {
        margin-top: 5px;
        margin-bottom: 10px;

        .phoneNumberContainer {
          display: flex;
          align-items: center;
        }

        .infoText {
          span {
            font-weight: 500;
          }

          div {
            font-weight: 500;
          }

          .containerLink {
            display: flex;
            align-items: center;

            height: 28px;

            a {
              margin-left: 10px;
              margin-right: 10px;

              text-decoration: none;

              .iconContainer {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 28px;
                width: 28px;

                border-radius: 7px;
              }

              .phoneIconContainer {
                background-image: linear-gradient(
                  to bottom,
                  #0073a7,
                  #006d9e,
                  #006695,
                  #00608c,
                  #005a83
                );

                text-shadow: -2px 1px 8px rgba(0, 0, 0, 0.5);

                .fa-phone {
                  color: white;
                  font-size: 15px;

                  transition: 100ms;
                }

                &:hover {
                  text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.75);

                  .fa-phone {
                    font-size: 17px;
                  }
                }
              }
            }
          }
        }
      }

      .order {
        .title {
          font-size: 20px;
          font-weight: 500;
        }

        .productContainer {
          display: flex;
          align-items: center;

          padding: 3px 0px;
          border-bottom: 2px solid rgb(200, 200, 200);

          .inputContainer {
            $blue: $CTA-color-hover;

            display: flex;
            justify-content: center;
            width: 50px;

            .checkboxWrapper {
              display: inline-block;

              .iconClosed {
                color: red;
                z-index: 2;
                margin: 0px;
                height: 30px;
                width: 40px;

                &:hover {
                  color: $danger-color-2;
                }
              }

              input {
                cursor: pointer;
                height: 20px;
                margin: 0px;
                opacity: 0;
                position: absolute;
                width: 20px;
                z-index: 2;

                &:active {
                  + .checkbox {
                    &:before {
                      height: 50px;
                      width: 50px;
                    }
                  }
                }

                &:checked {
                  + .checkbox {
                    border: 2px solid $blue;

                    &:before {
                      animation: pulse 1s cubic-bezier(0.15, 0.8, 0.5, 1);
                    }

                    &:after {
                      height: 20px;
                      opacity: 1;
                      width: 20px;
                    }
                  }
                }
              }

              .checkbox {
                border: 2px solid black;
                border-radius: 2px;
                height: 16px;
                position: relative;
                transition: all 0.5s;
                width: 16px;

                &:before,
                &:after {
                  content: "";
                  left: 50%;
                  position: absolute;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  transition: all 0.5s;
                }

                &:before {
                  background-color: rgba(242, 242, 242, 0.9);
                  border-radius: 50%;
                  height: 5px;
                  width: 5px;
                  z-index: -1;
                }

                &:after {
                  background-color: $blue;
                  border-radius: 2px;
                  height: 5px;
                  opacity: 0;
                  width: 5px;
                }

                i {
                  color: transparent;
                  height: 18px;
                  left: 0px;
                  pointer-events: none;
                  position: absolute;
                  top: 0px;
                  width: 18px;
                  z-index: 2;
                }
              }
            }

            @keyframes pulse {
              0% {
                box-shadow: 0 0 0 0px rgba($blue, 1);
              }
              100% {
                box-shadow: 0 0 0 25px rgba(242, 242, 242, 0);
              }
            }
          }

          .infoContainer {
            display: flex;
            align-items: center;

            width: 100%;

            .contentImageProduct {
              display: flex;
              align-items: center;
              margin-right: 5px;

              img {
                border-radius: 5px;
                max-height: 120px;
                width: 100%;
                max-width: 180px;
                object-fit: cover;
              }
            }

            .rightSideContainer {
              width: 100%;

              .name {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }

              .price {
                font-size: 18px;

                .singlePrice {
                  .priceDiscount {
                    display: flex;
                    flex-direction: row;

                    .discountedPrice {
                      color: black;
                    }

                    .discount {
                      font-weight: 600;
                      color: rgb(20, 174, 20);
                      margin-left: 10px;
                    }
                  }
                }
              }
            }

            .sectionAttributes {
              .btnAttributes {
                border: 1px solid grey;
                font-size: large;
                padding: 2px;
                margin: 1px;
              }
            }
          }
        }

        .productContainer:last-child {
          border-bottom: none;
        }
      }
    }

    .sectionKeyWord {
      display: flex;
      gap: 10px;

      font-size: 22px;

      margin-bottom: 30px;

      .title {
        color: black;
        font-weight: 600;
      }

      .containerKeyword {
        color: black;
        border: 3px solid red;

        border-radius: 5px;
        padding: 2px 15px;
      }
    }

    .actionButtonsContainer {
      display: flex;
      justify-content: center;

      margin-bottom: 10px;

      .buttonsContainer {
        display: flex;
        justify-content: space-between;

        width: 100%;
        max-width: 500px;

        gap: 10px;

        button {
          background-color: $CTA-color;

          border: 1px solid $CTA-color;
          border-radius: 5px;

          color: white;
          font-size: 15px;
          font-weight: 500;

          height: 40px;
          width: 120px;

          transition: 100ms;
        }

        button:hover {
          background-color: $CTA-color-hover;
          border: 1px solid $CTA-color-darker-hover;
        }

        .orderFailedBtn {
          background-color: $deliver-danger-color;
          border: 1px solid $deliver-danger-color;

          transition: 100ms;
        }

        .orderFailedBtn:hover {
          background-color: $deliver-danger-color-hover;
          border: 1px solid $deliver-danger-color-darker-hover;
        }

        .orderDeliveredBtn {
          background-color: $CTA-color;
          border: 1px solid $CTA-color;

          transition: 100ms;
        }

        .orderDeliveredBtn:hover {
          background-color: $CTA-color;
          border: 1px solid $CTA-color;
        }
      }

      .logoutModal {
        .background {
          position: fixed;
          top: 0;
          left: 0;

          z-index: 4;

          background-color: rgba(0, 0, 0, 0.6);

          width: 400vw;
          height: 100vh;

          margin-left: -400px;
        }

        form {
          position: fixed;
          top: 50%;
          left: 50%;

          background-color: white;

          width: 90%;
          max-width: 450px;
          padding: 10px;

          border-radius: 15px;

          transform: translate(-50%, -50%);
          z-index: 5;

          .title {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
          }

          .textareaContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;

            textarea {
              width: 100%;
              min-height: 120px;

              border: 1px solid black;
              border-radius: 5px;

              resize: none;
            }

            .buttonsContainer {
              display: flex;
              justify-content: center;

              button {
                background-color: $CTA-color;

                border: 1px solid $CTA-color;
                border-radius: 5px;

                color: white;
                font-size: 15px;
                font-weight: 500;

                height: 40px;
                width: 120px;

                transition: 100ms;
              }

              button:hover {
                background-color: $CTA-color-hover;
                border: 1px solid $CTA-color-darker-hover;
              }

              .orderFailedBtn {
                background-color: $deliver-danger-color;
                border: 1px solid $deliver-danger-color;

                transition: 100ms;
              }

              .orderFailedBtn:hover {
                background-color: $deliver-danger-color-hover;
                border: 1px solid $deliver-danger-color-darker-hover;
              }
            }
          }

          button {
          }
        }
      }

      .adminBtns {
        display: flex;
        justify-content: center;

        width: 100%;
        max-width: 400px;

        gap: 10px;

        button {
          background-color: $CTA-color;

          border: 1px solid $CTA-color;
          border-radius: 5px;

          color: white;
          font-size: 15px;
          font-weight: 500;

          height: 40px;
          width: 120px;

          transition: 100ms;
        }

        button:hover {
          background-color: $CTA-color-hover;
          border: 1px solid $CTA-color-darker-hover;
        }

        .orderFailedBtn {
          background-color: $deliver-danger-color;
          border: 1px solid $deliver-danger-color;

          transition: 100ms;
        }

        .orderFailedBtn:hover {
          background-color: $deliver-danger-color-hover;
          border: 1px solid $deliver-danger-color-darker-hover;
        }

        .diverCTAButton {
          background-color: $delivery-CTA-color;
          border: 1px solid $delivery-CTA-color-darker;
          color: white;

          text-align: center;

          padding: 5px 10px;
          border-radius: 5px;

          cursor: pointer;

          transition: 100ms;
        }

        .diverCTAButton:hover {
          background-color: $delivery-CTA-color-hover;
          border: 1px solid $delivery-CTA-color-darker-hover;
        }

        select {
          text-align: center;
          background-color: $delivery-CTA-color;

          border: 1px solid $delivery-CTA-color-darker;
          border-radius: 5px;

          color: white;
          font-size: 15px;
          font-weight: 500;

          height: 40px;
          width: 120px;

          transition: 100ms;
        }

        option {
          background-color: rgb(222, 222, 222);
          color: black;
        }
      }
    }
  }
}
