@import "../../../mainColorLibrary.scss";

$modalMaxWidth: 450px;

.addReviewModal {
  .background {
    position: fixed;
    top: 0;

    z-index: 4;

    background-color: rgba(0, 0, 0, 0.6);

    width: 400vw;
    height: 100vh;

    margin-left: -400px;
  }

  .modalContent {
    position: fixed;
    top: 50%;
    left: 50%;

    background-color: white;

    max-height: 350px;
    width: 90%;
    max-width: $modalMaxWidth;
    padding: 10px;

    border-radius: 15px;

    transform: translate(-50%, -50%);
    z-index: 5;

    .error {
      color: $danger-color;
      text-align: center;
    }

    .topSection {
      display: flex;
      flex-wrap: nowrap;

      margin-bottom: 10px;

      .closeModalBtn {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 30px;
        width: 30px;
        font-size: 20px;

        background-color: transparent;
        border: 1px solid transparent;
      }

      .closeModalBtn:hover {
        font-size: 22px;
      }

      .editReviewTitle {
        text-align: center;
        font-weight: 500;
        font-size: 22px;

        height: 30px;
        width: 100%;
      }
    }

    .contentSection {
      display: flex;
      justify-content: center;
      align-items: center;

      .contentFormReview {
        width: 100%;

        .RatingContainer {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .ratingText {
            font-size: 17px;
            font-weight: 400;
          }

          .starRating {
            color: rgb(212, 176, 92);
            font-size: 22px;
          }

          .noStarRating {
            color: rgb(184, 184, 184);
          }
        }

        .reviewContainer {
          width: 100%;

          .titleText {
            font-size: 17px;
            font-weight: 400;
          }

          .reviewInput {
            height: 120px;
            width: 97%;
            max-width: $modalMaxWidth;
            border-radius: 7px;

            background-color: rgb(228, 228, 228);
            border: 1px solid transparent;

            font-size: 15px;

            padding: 5px;
            margin: 5px 0px;

            resize: none;

            &.error {
              border: 2px solid red;
            }
          }
        }
      }

      .submitReviewBtn {
        background-color: $CTA-color; // CTA
        color: rgb(255, 255, 255);
        border: 1px solid $CTA-color-darker;

        height: 40px;
        width: 100%;
        border-radius: 7px;

        font-size: medium;

        &:disabled {
          background-color: $skeleton-bg-color;
        }
      }
    }
  }
}
