@import "../../mainColorLibrary.scss";

.loadingText {
  font-size: 20px;
}

.expandedImageModal {
  .background {
    position: fixed;
    top: 0;

    background-color: rgba(0, 0, 0, 0.6);

    height: 100vh;
    width: 400vw;

    z-index: 100;

    margin-left: -400px;
  }

  .modalContent {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 101;

    background-color: rgba(0, 0, 255, 0.5);

    .switchImgButton {
      display: flex;
      align-items: center;
      justify-content: center;

      // background-color: rgba(0, 0, 0, 0.3);
      color: rgba(255, 255, 255, 0.7);

      height: 25%;
      width: 20%;
      max-width: 300px;

      i {
        font-size: 40px;
        text-shadow: 0px 0px 2px black;
      }
    }

    .leftSwitchImgButton {
      position: fixed;
      top: 50%;
      left: 0;
      transform: translate(-0%, -50%);
    }

    .rightSwitchImgButton {
      position: fixed;
      top: 50%;
      right: 0;
      transform: translate(-0%, -50%);
    }

    img {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      max-height: 900px;
      width: 96%;
      max-width: 900px;
      border-radius: 7px;

      object-fit: cover;
    }
  }
}

.productDetailsPage {
  @media only screen and (min-width: 901px) {
    display: none;
  }

  .topSection {
    margin: 0px 5px;
    margin-bottom: 10px;

    .visitBusinessLink {
      color: rgb(0, 0, 0);
      font-size: 13px;
      text-decoration: none;

      i {
        margin-right: 3px;
      }
    }

    .productName {
      font-size: 18px;
    }

    .starRatingContainer {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-left: -4px;

      .RemoveFavoritesBtn {
        background-color: #d0d9c800;
        color: rgb(184, 6, 6);
        font-size: large;

        border: 1px solid rgba(151, 151, 151, 0);
      }

      .addToFavoritesBtn {
        background-color: #80794400;
        font-size: large;

        color: rgb(24, 0, 0);
        border: 1px solid transparent;
      }

      .numRatingsText {
        display: flex;
        align-items: center;
        font-size: 14px;

        color: black;
      }
    }

    .exclusiveproductswithsubscription {
      display: flex;
      align-items: center;
      gap: 5px;

      font-size: 13px;

      .verified {
        color: $mainColor-3;
        font-size: 16px;
      }
    }
  }

  // =========================================
  // =========================================
  // =========================================

  .productImagesContainer {
    $imageOptionBtnHeight: 60px;

    .imageContainer {
      height: 300px;

      @media only screen and (min-width: 680px) {
        height: 340px;
      }
      @media only screen and (min-width: 740px) {
        height: 380px;
      }
      @media only screen and (min-width: 820px) {
        height: 420px;
      }

      .imageWrapper {
        background-color: rgb(225, 225, 225);
        border-top: 1px solid rgb(200, 200, 200);
        border-bottom: 1px solid rgb(200, 200, 200);

        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        overflow: hidden;

        .productImg {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .productImageBtnsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      gap: 10px;

      margin-top: 5px;

      .imageOption {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        height: $imageOptionBtnHeight;
        width: $imageOptionBtnHeight;

        overflow: hidden;

        border-radius: 5px;

        .background {
          position: absolute;

          height: 120%;
          width: 120%;

          background-color: rgb(222, 222, 222);
        }

        img {
          height: 100%;
          width: 100%;
          max-width: $imageOptionBtnHeight;
          background-color: rgb(222, 222, 222);
          object-fit: cover;
          object-position: center center;

          border-radius: 5px;
          z-index: 1;
        }
      }

      .activeImage {
        position: relative;

        overflow: hidden;

        border-radius: 5px;

        .background {
          position: absolute;

          height: 135%;
          width: 135%;

          background-image: linear-gradient(
            to right,
            #919191,
            #9f9f9f,
            #adadad,
            #bbbbbb,
            #c9c9c9,
            #c9c9c9,
            #c9c9c9,
            #c9c9c9,
            #bbbbbb,
            #adadad,
            #9f9f9f,
            #919191
          );
          animation: rotateBackground 4s linear infinite;
        }

        img {
          height: $imageOptionBtnHeight - 7;
          width: $imageOptionBtnHeight - 7;

          border-radius: 2px;

          z-index: 1;
        }
      }

      @keyframes rotateBackground {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .priceContainer {
    font-size: 26px;

    margin-top: 10px;
    margin-left: 10px;
  }

  .inStockTextContainer {
    .onlyAFewInStockText {
      color: red;
      font-size: 14px;
      font-weight: 500;

      margin-top: 3px;
      margin-left: 10px;
    }

    .inStockText {
      color: green;
      font-size: 14px;
      font-weight: 500;

      margin-top: 3px;
      margin-left: 10px;
    }
  }

  .addToCartSection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: 20px;

    .disclaimerText {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 95%;
      max-width: 100%;

      font-size: 18px;
      font-weight: 500;
      // text-decoration: underline;

      margin-bottom: 10px;
    }

    @mixin AddToCartBtn($bg-color, $text-color) {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 35px;
      width: 100%;

      margin: 0px 10px;

      background-color: $bg-color;
      color: $text-color;
      border: 1px solid transparent;

      font-size: 16px;
      text-decoration: none;
    }

    .addCartBtn {
      @include AddToCartBtn($bg-color: $CTA-color, $text-color: white);
    }

    .editMyProductBtn {
      @include AddToCartBtn($bg-color: $mainColor-1, $text-color: white);
    }

    .createCartBtn {
      @include AddToCartBtn($bg-color: #0091af, $text-color: black);
    }

    .outOfStock {
      @include AddToCartBtn($bg-color: grey, $text-color: black);
    }
  }

  .descriptionSection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: 20px;

    .sectionTitleText {
      width: 95%;
      max-width: 100%;

      font-size: 18px;

      margin-bottom: 10px;
    }

    .descriptionText {
      width: 95%;
      max-width: 100%;

      font-size: 15px;
    }
  }

  .ProductExtraOptionsSection {
    margin-top: 20px;
    width: 95%;

    margin-left: 10px;

    user-select: none;

    .nameSection {
      display: flex;
      align-items: center;

      height: 20px;

      max-width: 100%;

      font-size: 18px;

      margin-bottom: 15px;

      .iconQuestion {
        margin-left: 5px;
        color: rgba(0, 146, 175, 0.5921568627);

        cursor: pointer;
      }
    }

    .descriptionCategoryOption {
      position: absolute;
      padding: 5px;

      border-radius: 2px;

      background-color: #0091af;
      p {
        color: whitesmoke;
        font-weight: lighter;
        font-size: 0.8em;
      }
    }

    .warapperOption {
      .contentOption {
        .titleOption {
          display: flex;
          justify-content: space-between;
          align-items: center;

          margin-left: 2px;
          margin-right: 2px;

          margin-bottom: 10px;

          padding: 2px;

          border-top: 1px solid black;
          border-bottom: 1px solid black;

          .title {
            height: 20px;

            font-size: 20px;

            margin-bottom: 10px;
            margin-left: 10px;
          }
          .isRequired {
            margin-right: 10px;
            font-size: 15px;

            font-size: large;
          }
        }

        .warpperAttribute {
          margin-bottom: 10px;
          margin-left: 15px;

          .selected {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            border-radius: 5px;
            background-color: #0092af97;

            cursor: pointer;

            .attributeName {
              margin-left: 10px;
              font-size: large;
            }

            .attributePrice {
              margin-left: 10px;
              font-size: large;
            }
          }

          .contentAttribute {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .attributeName {
              margin-left: 10px;
              font-size: large;
            }

            .attributePrice {
              margin-left: 10px;
              font-size: large;
            }
          }
        }
      }
    }
  }
}

/* ===================================================== */
/* ====================== Desktop ====================== */
/* ===================================================== */

.desktopProductDetailsPage {
  display: flex;
  flex-wrap: nowrap;

  @media only screen and (max-width: 900px) {
    display: none;
  }

  .leftSection {
    $leftSectionMaxWidth: 45vw;

    height: 100vh;

    width: $leftSectionMaxWidth;
    max-width: 1000px;

    margin: 3px;

    .productImagesContainer {
      $imageOptionBtnHeight: 60px;

      .imageContainer {
        height: 380px;

        @media only screen and (min-width: 950px) {
          height: 380px;
        }
        @media only screen and (min-width: 1050px) {
          height: 420px;
        }
        @media only screen and (min-width: 1200px) {
          height: 480px;
        }
        @media only screen and (min-width: 1250px) {
          height: 520px;
        }
        @media only screen and (min-width: 1400px) {
          height: 560px;
        }
        @media only screen and (min-width: 1600px) {
          height: 600px;
        }

        .imageWrapper {
          background-color: rgb(225, 225, 225);
          border-top: 1px solid rgb(200, 200, 200);
          border-bottom: 1px solid rgb(200, 200, 200);

          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;

          .productImg {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .productImageBtnsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 10px;

        margin-top: 5px;

        .imageOption {
          display: flex;
          justify-content: center;
          align-items: center;

          height: $imageOptionBtnHeight;
          width: $imageOptionBtnHeight;

          position: relative;

          overflow: hidden;

          border-radius: 5px;

          .background {
            position: absolute;

            height: 120%;
            width: 120%;

            background-color: rgb(222, 222, 222);
          }

          img {
            height: 100%;
            width: 100%;
            max-width: $imageOptionBtnHeight;

            object-fit: cover;
            object-position: center center;

            border-radius: 5px;

            z-index: 1;
          }
        }

        .activeImage {
          position: relative;

          overflow: hidden;

          border-radius: 5px;

          .background {
            position: absolute;

            height: 140%;
            width: 140%;

            background-image: linear-gradient(
              to right,
              #9b9b9b,
              #a8a8a8,
              #b5b5b5,
              #c2c2c2,
              #cfcfcf,
              #cfcfcf,
              #cfcfcf,
              #cfcfcf,
              #c2c2c2,
              #b5b5b5,
              #a8a8a8,
              #9b9b9b
            );

            animation: rotateBackground 4s linear infinite;
          }

          img {
            height: $imageOptionBtnHeight - 7;
            width: $imageOptionBtnHeight - 7;

            border-radius: 2px;

            z-index: 2;
          }
        }

        @keyframes rotateBackground {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .addToCartSection {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      margin-top: 20px;

      .disclaimerText {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 95%;
        max-width: 100%;

        font-size: 18px;
        font-weight: 500;

        margin-bottom: 5px;
      }

      @mixin AddToCartBtn($bg-color, $text-color) {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 35px;
        width: 100%;
        max-width: 500px;

        margin: 0px 10px;

        background-color: $bg-color;
        color: $text-color;
        border: 1px solid transparent;

        font-size: 16px;
        text-decoration: none;
      }

      .addCartBtn {
        @include AddToCartBtn($bg-color: $CTA-color, $text-color: white);
      }

      .editMyProductBtn {
        @include AddToCartBtn($bg-color: $mainColor-1, $text-color: white);
      }

      .createCartBtn {
        @include AddToCartBtn($bg-color: #0091af, $text-color: black);
      }

      .outOfStock {
        @include AddToCartBtn($bg-color: grey, $text-color: black);
      }
    }
  }

  .rightSection {
    max-width: 55vw;
    margin-left: 10px;

    .topSection {
      width: 95%;

      margin: 0px 5px;
      margin-bottom: 10px;

      .productName {
        font-size: 22px;
        margin-bottom: -5px;
      }

      .visitBusinessLink {
        color: rgb(0, 141, 146);
        font-size: 15px;
        text-decoration: none;
        margin-bottom: 10px;
      }

      .starRatingContainer {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-left: -4px;

        .RemoveFavoritesBtn {
          background-color: #d0d9c800;
          color: rgb(184, 6, 6);
          font-size: large;
          border: 1px solid rgba(151, 151, 151, 0);
        }

        .addToFavoritesBtn {
          background-color: #80794400;
          font-size: large;
          color: rgb(24, 0, 0);
          border: 1px solid transparent;
        }

        .numRatingsText {
          display: flex;
          align-items: center;
          font-size: 14px;
          text-decoration: underline;
          color: black;
        }
      }

      .exclusiveproductswithsubscription {
        display: flex;
        align-items: center;
        gap: 5px;

        font-size: 13px;

        .verified {
          color: $mainColor-3;
          font-size: 16px;
        }
      }
    }

    .descriptionSection {
      display: flex;

      flex-wrap: wrap;

      max-width: 550px;
      margin-top: 20px;

      .sectionTitleText {
        height: 20px;
        width: 95%;
        max-width: 100%;

        font-size: 18px;

        margin-bottom: 10px;
      }

      .descriptionText {
        width: 95%;
        max-width: 100%;

        font-size: 15px;
      }
    }

    .ProductExtraOptionsSection {
      margin-top: 20px;
      width: 95%;

      user-select: none;

      .nameSection {
        display: flex;
        align-items: center;

        height: 20px;
        max-width: 100%;

        font-size: 18px;

        margin-bottom: 15px;

        .iconQuestion {
          margin-left: 5px;
          color: rgba(0, 146, 175, 0.5921568627);

          cursor: pointer;
        }
      }

      .descriptionCategoryOption {
        position: absolute;
        padding: 5px;

        border-radius: 2px;

        background-color: #0091af;
        p {
          color: whitesmoke;
          font-weight: lighter;
          font-size: 0.8em;
        }
      }

      .warapperOption {
        .contentOption {
          .titleOption {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 10px;

            padding: 2px;

            border-top: 1px solid black;
            border-bottom: 1px solid black;

            .title {
              font-size: large;

              height: 20px;
              max-width: 100%;

              font-size: 18px;

              margin-bottom: 10px;
            }
            .isRequired {
              font-size: large;
            }
          }

          .warpperAttribute {
            margin-bottom: 10px;
            margin-left: 15px;

            .selected {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 5px;

              border-radius: 5px;
              background-color: #0092af97;

              cursor: pointer;

              .attributeName {
                margin-left: 10px;
                cursor: pointer;
                margin-right: 10px;
              }

              .attributePrice {
                margin-left: 5px;
                cursor: pointer;
                margin-right: 10px;
              }
            }

            .contentAttribute {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              margin-bottom: 5px;
              cursor: pointer;

              .attributeName {
                margin-left: 10px;
                cursor: pointer;
                margin-right: 10px;
              }

              .attributePrice {
                margin-left: 5px;
                cursor: pointer;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
