@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

$profilePictureHeight: 120px;
$skeletonAnimationDuration: 2s;

@keyframes skeletonAnimation {
  0% {
    background-color: $skeleton-bg-color;
  }
  70% {
    background-color: $skeleton-bg-color;
  }
  100% {
    background-color: rgb(195, 195, 195);
    // background-color: rgb(200, 97, 97);
  }
}

@mixin skeletonText($textWidth, $textHeight) {
  background-color: $skeleton-bg-color;
  height: $textHeight;
  width: $textWidth;
  border-radius: 3px;

  animation-name: skeletonAnimation;
  animation-duration: $skeletonAnimationDuration;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.skeletonProfilePage {
  .profileSection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: 0px 15px;

    .leftSide {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      margin-top: 10px;

      .profilePictureContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        width: 100%;

        .profilePicture {
          box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
          background-color: $skeleton-bg-color;

          height: $profilePictureHeight;
          width: $profilePictureHeight;

          border-radius: 100%;

          object-fit: cover;
          object-position: center center;

          animation-name: skeletonAnimation;
          animation-duration: $skeletonAnimationDuration;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }

        .usernameText {
          display: flex;
          justify-content: center;

          width: 100%;
          margin-top: 8px;

          .skeletonText {
            @include skeletonText($textWidth: 120px, $textHeight: 20px);
          }
        }

        .updateProfileLink {
          margin-top: 6px;

          .skeletonText {
            @include skeletonText($textWidth: 70px, $textHeight: 13px);
          }
        }
      }
    }

    .rightSide {
      width: 100%;
      max-width: 450px;

      .titleText {
        margin-top: 4px;

        .skeletonText {
          @include skeletonText($textWidth: 55px, $textHeight: 17px);
        }
      }

      .infoSection {
        margin-top: 4px;

        .infoContainer {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          height: 60px;

          border-bottom: 1px solid rgb(204, 204, 204);

          .infoTitleText {
            width: 120px;

            .skeletonText {
              @include skeletonText($textWidth: 100px, $textHeight: 15px);
            }
          }
          .dataText {
            .skeletonText {
              @include skeletonText($textWidth: 120px, $textHeight: 15px);
            }
          }
        }
      }
    }
  }
}

// _________________________________________________________________
// _________________________________________________________________
// _________________________________________________________________

.profilePage {
  .profilePictureModal {
    .background {
      position: fixed;
      top: 0;

      z-index: 4;

      background-color: rgba(0, 0, 0, 0.6);

      width: 400vw;
      height: 100vh;

      margin-left: -400px;
    }

    .profilePicture {
      width: 80%;

      max-height: 400px;
      max-width: 400px;

      position: fixed;
      top: 50%;
      left: 50%;

      border-radius: 1000px;

      transform: translate(-50%, -50%);
      z-index: 5;

      object-fit: cover;
      object-position: center center;

      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8);
    }
  }

  .profileSection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: 0px 15px;

    .leftSide {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;

      margin-top: 10px;

      .profilePictureContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        width: 100%;

        .profilePicture {
          background-color: $skeleton-bg-color;
          box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);

          height: $profilePictureHeight;
          width: $profilePictureHeight;

          border-radius: 100%;

          object-fit: cover;
          object-position: center center;
        }

        .usernameText {
          text-align: center;
          font-size: 22px;

          width: 100%;
        }

        .updateProfileLink {
          display: flex;
          justify-content: center;
          font-size: 14px;

          color: $CTA-color-3;
        }
      }
    }

    .rightSide {
      width: 100%;
      max-width: 450px;

      .profileTitleContainer {
        display: flex;
        align-items: center;

        .titleText {
          font-size: 18px;
          font-weight: 500;
          margin-right: 10px;

          // background-color: red;
        }
      }

      .infoSection {
        .infoContainer {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          height: 60px;

          border-bottom: 1px solid rgb(204, 204, 204);

          font-size: 16px;

          .infoTitleText {
            width: 120px;
          }

          .btnLenguage {
            @include Button(transparent, $CTA-color-3, $CTA-color-3);
            display: flex;
            border-width: 2px;

            font-weight: 500;

            padding: 4px 10px;
            .iconContainer {
              margin-right: 2px;
              .fa-language {
                color: black;
              }
            }

            .language {
              margin-left: 2px;
              font-weight: 600;
            }

            &:hover {
              background-color: rgba(0, 149, 255, 0.08);
            }
          }

          .dataText {
            text-decoration: none;
            color: black;
            font-weight: 400;

            button {
              border: 2px solid black;
              border-radius: 2px;
              font-weight: 550;
              cursor: pointer;
              width: 10em;
            }
          }

          .addressBtn {
            @include Button(transparent, $CTA-color-3, $CTA-color-3);

            border-width: 2px;

            font-weight: 500;

            padding: 4px 10px;

            &:hover {
              background-color: rgba(0, 149, 255, 0.08);
            }
          }
        }

        .infoContainer__updateProfileContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          .updateProfileLink {
          }
        }
      }
    }
  }

  .logoutBtnContainer {
    display: flex;
    justify-content: center;

    margin: 30px 0px 20px 0px;

    .logoutBtn {
      height: 40px;
      width: 100%;
      max-width: 120px;

      font-weight: 600;
      font-size: 14px;

      color: red;
      background-color: transparent;
      border: 2px solid rgb(255, 0, 0);

      cursor: pointer;

      border-radius: 7px;

      &:hover {
        background-color: rgba(255, 0, 0, 0.05);
      }
    }
  }

  .logoutModal {
    .background {
      position: fixed;
      top: 0;

      z-index: 4;

      background-color: rgba(0, 0, 0, 0.6);

      width: 400vw;
      height: 100vh;

      margin-left: -400px;
    }

    .modalContent {
      position: fixed;
      top: 50%;
      left: 50%;

      background-color: white;

      height: 150px;
      width: 90%;
      max-width: 450px;
      padding: 10px;

      border-radius: 15px;

      transform: translate(-50%, -50%);
      z-index: 5;

      .textContainer {
        .titleText {
          text-align: center;
          font-size: 26px;
          font-weight: 500;
        }

        .questionText {
          text-align: center;
          font-size: 16px;
        }

        height: 50%;
      }

      .buttonsContainer {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        align-items: center;

        height: 50%;

        @mixin buttonsStyles {
          height: 40px;
          border-radius: 7px;

          font-size: 15px;

          padding: 8px;
          width: 100px;

          margin: 0px 10px;
          cursor: pointer;
        }

        .cancelBtn {
          @include buttonsStyles();
          background-color: rgb(228, 228, 228);
          border: 1px solid rgb(0, 0, 0);
        }

        .logoutBtn {
          @include buttonsStyles();

          right: 0;

          background-color: #64a747;

          color: white;
          border: 1px solid rgb(19, 46, 0);
        }
      }
    }
  }
}
