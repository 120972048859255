@import "../../mainColorLibrary.scss";

.websiteSettingsPage {
  padding: 20px;

  h2 {
    margin-top: 0px;
  }

  .delivery-container {
    display: flex;
    align-items: center;
    column-gap: 8px;

    p {
      width: 190px;
    }

    .toggle {
      font-size: 3em;
      margin-left: 20px;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .delivery-available {
      color: rgb(0, 187, 45);
    }

    .delivery-not-available {
      color: rgb(255, 0, 0);
    }
  }

  form {
    #sender_number_value {
      width: 180px;
    }

    button {
      background-color: $CTA-color;
      color: white;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      margin-top: 15px;
    }

    .input-container {
      margin-top: 10px;
      display: flex;
      align-items: center;

      label {
        width: 190px;
      }

      input {
        border: 1px solid rgb(141, 141, 141);
        height: 40px;
        width: 80px;
        max-width: 400px;
        border-radius: 5px;
        text-align: center;
        margin-left: 10px;
      }

      textarea {
        border-radius: 5px;
        height: 60px;
        resize: none;
      }
    }
  }

  .pastActionsLog {
    margin-top: 100px;

    .actionsLogTable {
      border-collapse: collapse;

      .actionsLogHeader {
        .tableBox {
          text-align: center;

          padding: 0px 20px;
          background-color: #40493b;
          color: white;

          &.tableBox--userId {
          }

          &.tableBox--username {
          }
          &.tableBox--dateAndTime {
          }

          &.tableBox--available {
          }
          &.tableBox--usdToPesoValue {
          }

          &.tableBox--cartMessage {
          }
        }
      }
    }

    .actionsLogBody {
      .tableBox {
        text-align: center;
        padding: 0px 20px;
        border: 1px solid lightgray;

        &.tableBox--userId {
          position: relative;

          .renderUsername {
            display: none;
          }

          &:hover {
            .renderUsername {
              $bgColor: #a4ae9e;

              position: absolute;
              top: 0;
              transform: translateY(calc(-100% + -5px));
              left: 24px;

              display: block;

              background-color: $bgColor;
              box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.2);

              padding: 10px;
              border-radius: 3px;

              .username {
                text-wrap: nowrap;
              }

              .triangle {
                position: absolute;
                bottom: -9px;
                left: 6px;

                .arrow-down {
                  width: 0;
                  height: 0;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 10px solid $bgColor;
                }
              }
            }
          }
        }
      }
    }
  }
}
