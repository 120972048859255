@import "../../mainColorLibrary.scss";

.SearchBar {
  margin: 0px 10px;

  @media only screen and (min-width: 1050px) {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    // justify-content: space-between;
  }

  .searchBarAndSuggestionsContainer {
    .searchBarContainer {
      display: flex;
      flex-wrap: nowrap;

      height: 32px;
      width: 100%;

      @media only screen and (min-width: 1050px) {
        min-width: 300px;
      }
      @media only screen and (min-width: 1110px) {
        min-width: 350px;
      }
      @media only screen and (min-width: 1200px) {
        min-width: 320px;
      }
      @media only screen and (min-width: 1250px) {
        min-width: 350px;
      }
      @media only screen and (min-width: 1290px) {
        min-width: 400px;
      }
      @media only screen and (min-width: 1330px) {
        min-width: 520px;
      }

      .searchBarInput {
        background-color: white;
        border: 1px solid rgb(241, 241, 241);
        border-right: 0px solid transparent;

        width: 100%;
        padding: 0px 10px;

        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
      .searchBarInput:focus {
        outline: none;
      }

      .cancelSearchBtn {
        background-color: white;
        border: 0px solid transparent;
        border: 1px solid rgb(241, 241, 241);
        border: 1px solid rgb(241, 241, 241);

        height: 100%;
        min-width: 30px;
        max-width: 30px;
        border-radius: 0px;

        font-size: 18px;
      }

      .searchBtn {
        background-color: rgb(252, 252, 252);
        border: 1px solid rgb(241, 241, 241);

        height: 100%;
        min-width: 60px;
        max-width: 60px;

        border-radius: 0px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
    }

    .searchSuggestionsContainer {
      position: relative;
      .suggestions {
        position: absolute;
        width: 100%;
        background-color: rgb(229, 229, 229);
        border: 1px solid rgb(213, 213, 213);
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        z-index: 100;

        .suggestionItem {
          display: flex;
          align-items: center;

          background-color: transparent;

          height: 25px;

          transition: background-color;

          .iconContainer {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 100%;
            width: 40px;

            font-size: 13px;
          }
        }
        .suggestionItem:hover {
          background-color: rgb(207, 207, 207);
          overflow: hidden;
        }
      }
    }
  }

  .allFiltersContainer {
    display: flex;
    justify-content: space-between;

    .filterDropdown {
      :hover {
        cursor: pointer;
      }

      .mainButton {
        color: black;
        border: 0px;

        transition: border-bottom 150ms;
        font-size: 14px;
        font-weight: 500;

        height: 32px;

        @media only screen and (min-width: 1050px) {
          min-width: 90px;
        }
      }

      .filterOptionsContainer {
        display: flex;
        justify-content: center;

        .filterOptionsForm {
          display: flex;
          flex-wrap: wrap;
          z-index: 100;

          position: absolute;

          background-color: white;
          border: 1px solid rgb(186, 186, 186);
          box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.33);

          width: 150px;
          border-radius: 5px;
          overflow: hidden;

          button {
            background-color: transparent;
            border: 0px solid transparent;

            border-radius: 0px;

            min-width: 150px;
            max-width: 150px;
          }

          button.active {
            background-color: $mainColor-2;
          }
        }
      }

      .leftFilterOptionsContainer {
        @media only screen and (max-width: 1050px) {
          display: flex;
          justify-content: flex-start;
        }
      }

      .rightFilterOptionsContainer {
        @media only screen and (max-width: 1050px) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
