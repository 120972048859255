@import "../../../../mainColorLibrary.scss";

.companyContact {
  width: 100%;

  .titleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    h2 {
      margin-left: 10px;
    }

    .updateAboutLink {
      background-color: $CTA-color;
      color: white;

      padding: 5px 8px;
      border-radius: 5px;

      text-decoration: none;
    }

    @media (max-width: 400px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 20px;

      h2 {
        text-align: center;
        height: 10px;
        width: 100%;

        margin-left: 0;
      }

      .updateAboutLink {
        background-color: $CTA-color;
        color: white;

        padding: 5px 12px;
        border-radius: 5px;

        text-decoration: none;
      }
    }
  }

  .contactDoesntExistTxt {
    text-align: center;
    font-weight: 500;
    font-size: 18px;

    margin-top: 10px;
  }

  .contactInformation {
    margin-left: 15px;

    p {
      a {
        color: #2d6e8a;
        text-decoration: none;
      }
    }

    .whatsappLink {
      color: #00a73d;
      text-decoration: none;

      i {
        color: #25d366;
        font-size: 28px;
      }
    }

    .callLink {
      color: rgb(0, 110, 255);
      text-decoration: none;
      font-size: 20px;

      i {
        margin-right: 5px;
      }
    }
  }
}
