.addressCard {
  background-color: white;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 7px;

  width: 92%;

  padding: 7px;

  margin: 5px;

  font-weight: 500;

  overflow: hidden;

  .sectionContainer {
    margin-bottom: 10px;

    .sectionTitle {
    }

    .infoTxt {
    }
  }

  .sectionContainer {
    margin-bottom: 10px;
  }
}
