.error-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.error-popup-content {
  background-color: white;
  margin: 8px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  font-weight: 400;
  font-size: 1.2em;

  opacity: 0.5;
  transform: scale(0.5);
  animation: scaleIn 0.3s ease both;

  .btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background-color: #f44336;
      color: white;
      border: none;
      font-size: 16px;
      padding: 5px 10px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
    }

    .link {
      background-color: #397420;
      color: white;
      font-weight: 600;
      border: none;
      font-size: 16px;
      padding: 5px 10px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
