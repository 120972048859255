@import "../mainColorLibrary.scss";

$bannerImageHeight-1: 114px;
$bannerImageHeight-2: 136px;
$bannerImageHeight-3: 158px;
$bannerImageHeight-4: 180px;
// ==============================
$bannerImageHeight-5: 186px;
$bannerImageHeight-6: 220px;
$bannerImageHeight-7: 234px;
$bannerImageHeight-8: 260px;
$bannerImageHeight-9: 290px;
$bannerImageHeight-10: 310px;
$bannerImageHeight-11: 360px;
// ==============================
$bannerImageHeight-12: 340px;
$bannerImageHeight-13: 400px;
$bannerImageHeight-14: 480px;
// ==============================

$bannerImageMaxHeight: 700px;

@mixin SponsorsBannerImage() {
  width: 100%;
  aspect-ratio: 16/5;

  object-fit: cover;
  object-position: center center;
}

.galeanaBizDriverContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 60px;

  height: 76vh;

  @media only screen and (max-width: 600px) {
    height: 62vh;
  }

  @keyframes btnGrow {
    0% {
      transform: translate(0px, 0px);
    }
    25% {
      transform: rotate(0.01turn) scale(1.1);
    }
    50% {
      transform: translate(0px, 0px) scale(1);
    }
    75% {
      transform: rotate(-0.01turn) scale(1.1);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }

  .goToDriverPage {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $CTA-color;
    color: white;
    text-decoration: none;
    font-weight: 500;

    // position: absolute;

    height: 50px;
    width: 140px;
    padding: 0px 10px;

    border-radius: 5px;

    animation-name: btnGrow;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
}

.contentHomePages {
  .contentCurrentPage {
    display: flex;
    justify-content: space-evenly;
    margin: 8px;
    .slectedCurrentPage {
      width: 180px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 5px;
      font-weight: 600;

      color: whitesmoke;
      border: none;
      background-color: #5d92a9e6;
    }
    .btnCurrentPage {
      width: 180px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 5px;
      font-weight: 600;

      color: whitesmoke;
      border: none;
      background-color: #2d6e8a;

      &:hover {
        background-color: #5d92a9e6;
      }
    }
  }

  .homePage {
    width: 100%;
    margin-bottom: 5rem;

    .sponsorsCarousel {
      position: relative;

      overflow: hidden;

      @include SponsorsBannerImage();

      background-color: lightgray;

      .sponsorBannerImgContainer {
        position: relative;

        img {
          @include SponsorsBannerImage();

          font-style: italic;
        }

        .sponsorTxt {
          position: absolute;
          right: 5px;
          bottom: 5px;

          color: rgba(255, 255, 255, 0.8);

          font-size: 14px;

          text-shadow: 0px 0px 8px black;

          @media only screen and (max-width: 800px) {
            font-size: 12px;
          }

          @media only screen and (max-width: 420px) {
            font-size: 10px;
          }
        }
      }

      .control {
        position: absolute;
        z-index: 2;
        top: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: transparent;

        height: 100%;
        width: 10%;
        min-width: 50px;
        padding: 0 5px;

        border: 0;

        cursor: pointer;

        overflow: hidden;

        span {
          display: inline-block;

          position: relative;

          transition: opacity 300ms ease;

          color: rgba(255, 255, 255, 0.3);
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

          font-size: 60px;
          font-weight: 600;

          @media only screen and (max-width: 800px) {
            font-size: 50px;
          }

          @media only screen and (max-width: 500px) {
            font-size: 40px;
          }
        }
      }

      .control.prev {
        left: 0;
        padding-left: 12px;
      }

      .control.next {
        right: 0;
        padding-right: 12px;
      }
    }

    .companyCardsTitle {
      margin-left: 10px;
    }

    .companyCardsSection {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      .companyCardsContainer {
        width: 100%;
        padding: 7px;

        transition: opacity 0.5s ease;

        @media only screen and (min-width: 600px) {
          padding: 10px;
        }

        transition: ease all 0.5s;

        .companyCardsWrapper {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          place-items: center;
          grid-gap: 10px;

          width: 100%;

          transition: opacity 0.2s ease;
        }
      }
      .laodingScroll {
        margin: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .productsCardsSection {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      .productsCardsContainer {
        display: flex;
        justify-content: center;
        transition: opacity 0.5s ease;

        width: 100%;
        padding: 5px;

        transition: ease all 0.5s;

        .productsCardsWrapper {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
          place-items: center;
          grid-gap: 10px;

          width: 100%;

          transition: opacity 0.2s ease;
        }
      }
    }

    .laodingScroll {
      margin: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loadMoreContent {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      .loadMoreButton {
        border: none;
        color: white;
        background-color: #2d6e8a;

        font-size: large;
        font-weight: bold;

        padding: 6px;
      }
    }
  }
}
