@import "../Navbar.scss";
@import "./BothSidebarsDelivery.scss";
@import "./DeliverySmallSidebar.scss";
@import "./DeliveryLargeSidebar.scss";

$screenSize-small: 600px;
$screenSize-medium: 601px;
$screenSize-large: 1200px;

.thePageDelivery {
  padding-top: $navbarHeight;

  @media only screen and (max-width: $screenSize-small) {
    padding-left: 0px;
  }

  @media only screen and (min-width: $screenSize-medium) {
    padding-left: $smallSidebarWidth;
  }
  @media only screen and (min-width: $screenSize-large) {
    padding-left: $largeSidebarWidth;
  }
}

.DeliverymobileFooterContainer {
  display: none;

  @media only screen and (max-width: $screenSize-small) {
    display: inline;
  }
}
