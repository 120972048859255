@import "../../mainColorLibrary.scss";

$screenSize-small: 700px;

$navbarHeight: 50px;
$navbarMaxWidth: 600px;

$iconsContainerWidth: 130px;
$iconsContainerWidth-Mobile: 120px;

.navbardelivery {
  position: fixed;

  background-color: $deliveryMainColor-1;
  color: white;

  height: $navbarHeight;
  width: 100%;

  font-family: "Oswald", sans-serif;
  font-weight: 200;

  z-index: 2;

  .contentContainer {
    display: flex;
    align-items: center;

    .logoContainer {
      display: flex;
      align-items: center;

      color: white;
      text-decoration: none;

      height: $navbarHeight;

      .logoImg {
        height: $navbarHeight - 5px;
        filter: invert(1);

        @media only screen and (max-width: $screenSize-small) {
          height: $navbarHeight - 10px;
        }
      }

      .titleContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &.title {
            height: 18px;
            font-size: 18px;
          }

          &.subtitle {
            height: 22px;
            font-size: 22px;
          }
        }
      }
    }

    .iconsContainer {
      display: flex;

      justify-content: flex-end;
      align-items: center;

      position: absolute;

      right: 0;

      margin-right: 10px;

      margin-left: 10px;

      .profilePicture {
        $borderRadius: 100%;

        height: 30px;
        width: 30px;

        border-radius: $borderRadius;

        .profilePictureImg {
          height: 100%;
          width: 100%;

          border-radius: $borderRadius;
        }
      }
    }
  }

  .searchBarContainer {
    $borderRadius: 100px;

    @media only screen and (max-width: $screenSize-small) {
      display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1;

    margin: 0px 50px;

    .searchBar {
      border: 1px solid transparent;

      height: 30px;
      width: 100%;
      max-width: $navbarMaxWidth;

      border-radius: $borderRadius;
    }
  }

  .mobileSearchbarContainer {
    $_screenSize: $screenSize-small + 1;

    @media only screen and (min-width: $_screenSize) {
      display: none;
    }

    display: flex;
    justify-content: center;

    width: 70%;

    z-index: 1;

    .searchBar {
      border: 1px solid transparent;

      height: 30px;
      width: 80%;
      max-width: $navbarMaxWidth;

      border-radius: 100px;
    }
  }

  .searchBackground {
    position: absolute;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.5);
    height: 300vh;
    width: 100vw;
  }
}

.activeMobileSearchbar {
  @media only screen and (min-width: $screenSize-small) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  margin: 0px 10px;

  .cancelBtn {
    font-size: 28px;
  }

  .searchBar {
    border: 1px solid transparent;

    height: 28px;
    width: 88%;
    max-width: $navbarMaxWidth;

    border-radius: 100px;
  }
}
