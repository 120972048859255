@use "sass:math";

@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

$cardHeight: 220px;
$imgAspectRatio: 12/16;
$imgBorderRadius: 5px;

.productCard {
  display: flex;
  gap: 5px;

  color: black;
  text-decoration: none;

  height: $cardHeight;
  width: 100%;

  .productImgContainer {
    position: relative;
    height: 100%;
    aspect-ratio: $imgAspectRatio;

    .memberOnlyTag {
      position: absolute;
      top: 5px;
      left: 5px;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $mainColor-1;
      box-shadow: 0px 0px 4px rgb(255, 255, 255);

      height: 30px;
      width: 30px;
      border-radius: 1000px;

      .LGMemberTagLogo {
        min-height: 28px;
        min-width: 28px;
      }
    }

    .productImg {
      height: 100%;
      width: 100%;
      border-radius: $imgBorderRadius;

      object-fit: cover;
    }
  }

  .infoSection {
    .infoText {
      text-align: start;
    }

    .productName {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .businessName {
      color: gray;
      font-size: 14px;
    }

    .productPrice {
      font-size: 22px;
      font-weight: 500;

      .productCurrency {
        font-size: 18px;
      }
    }

    .productTotalPrice {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 5px;

      .productCurrency {
        font-size: 16px;
      }
    }

    .productOldPrice {
      font-size: 18px;
      color: gray;
      text-decoration: line-through;
      line-height: 1;
      margin: 0;

      .productCurrency {
        font-size: 16px;
      }
    }

    .discountedPercentage {
      color: rgb(24, 145, 192);

      font-size: 20px;
      font-weight: bold;
    }

    .soldOutText {
      color: rgb(228, 15, 15);
      font-size: 18px;
    }

    .memberOnlyTag {
      display: flex;
      align-items: center;
      gap: 5px;

      .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 20px;
        width: 20px;
        padding: 1px;
        border-radius: 100px;

        background-color: green;

        .LGMemberTagLogo {
          height: 100%;
          width: 100%;
        }
      }

      .tagTitle {
        color: rgb(25, 170, 25);
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .infoSection__cart {
    width: 100%;

    .topSection {
      display: flex;
      flex-direction: column;
    }

    .bottomSection {
      padding-top: 5px;

      .removeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        background-color: transparent;
        color: #d12626;
        border: 2px solid #d12626;
        border-radius: 100px;

        font-size: 14px;
        padding: 0px 10px;

        transition: 120ms;

        &:hover {
          background-color: rgba(160, 0, 0, 0.1);
        }
      }

      .amountOfUnitsContainer {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        width: 100%;

        .amountOfUnits {
          display: flex;
          border: 2px solid lightgray;
          color: rgb(80, 80, 80);

          border-radius: 5px;

          font-size: 16px;

          .changeAmountBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            width: 35px;

            font-weight: 600;
          }

          .amountSelectedBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            padding: 5px 8px;
            min-width: 30px;
            border: 2px solid lightgray;
            border-top: 0px;
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.skeletonProductCard {
  display: flex;
  gap: 5px;

  color: black;
  text-decoration: none;

  height: $cardHeight;
  width: 100%;

  .imgContainer {
    @include skeletonAnimation;

    height: 100%;
    aspect-ratio: $imgAspectRatio;
    border-radius: $imgBorderRadius;
  }

  .infoSection {
    .productName {
      @include textSkeletonPrefab(16px);

      width: 100%;

      margin-bottom: 7px;
    }

    .businessName {
      @include textSkeletonPrefab(16px, 80px);
      margin-bottom: 20px;
    }

    .productPrice {
      @include textSkeletonPrefab(22px, 120px);
    }
  }
}
