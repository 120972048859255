@import "../mainColorLibrary.scss";

@mixin CategoryBtn($bg-color, $color, $border-color) {
  background-color: $bg-color;
  color: $color;
  border: 1px solid $border-color;

  border-radius: 5px;

  font-size: 14px;
  font-weight: 300;

  padding: 5px 8px;
  margin: 2px;

  filter: brightness(1.1);
  transition: filter 150ms ease;
}

.chipBar {
  .formContentButton {
    @media only screen and (min-width: 601px) {
      ::-webkit-scrollbar {
        background-color: rgba(255, 0, 0, 0);
        height: 7px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgb(157, 157, 157);
      }
    }

    .categoryButtonsContainer {
      white-space: nowrap;
      position: relative;

      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      .selectedCategoryBtn {
        @include CategoryBtn(
          $bg-color: #7e9870,
          $color: black,
          $border-color: rgba(15, 70, 5, 0)
        );
      }

      .categoryBtn {
        @include CategoryBtn(
          $bg-color: #cecece,
          $color: rgb(0, 0, 0),
          $border-color: rgba(155, 155, 155, 0)
        );
      }

      :hover {
        filter: brightness(1);
      }
    }
  }
}
