@import "../../mainColorLibrary.scss";

.companyCard_v3_container {
  position: relative;

  width: 100%;

  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  border-radius: 5px;
  overflow: hidden;

  .companyCard_v3 {
    width: 100%;

    color: rgb(139, 139, 139);

    text-decoration: none;

    .companyImgContainer {
      position: relative;

      background-color: rgb(238, 238, 238);
      border-bottom: 1px solid rgb(231, 231, 231);

      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;

      .companyLogoImg {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .closedTextContainer {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(0, 0, 0, 0.4);
        color: white;

        width: 100%;
        aspect-ratio: 16/9;

        .closedText {
          background-color: rgba(255, 255, 255, 0.2);
          border: 2px solid white;
          text-shadow: 0px 0px 2px black;

          padding: 2px 15px;

          font-size: 24px;
          font-weight: 500;

          transform: rotateZ(-7deg);
        }
      }
    }

    .infoSection {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;

      height: 70px;

      .adjustForPadding {
        position: absolute;
        top: -28px;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        padding: 10px;

        .businessName {
          text-align: center;

          background-color: #40493b;
          color: white;
          box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.75);

          padding: 2px 15px;
          border-radius: 100px;

          max-width: 95%;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .companyLocation {
          font-size: 14px;
        }

        .reviewsContainer {
          font-size: 16px;

          .reviewsTextContainer {
            font-size: 14px;
            line-height: 1;
          }
        }
      }
    }
  }

  .heartButtonContainer {
    position: absolute;
    bottom: 0px;
    right: 5px;

    font-size: 20px;

    &.heartBtnLiked {
      color: rgb(219, 54, 54);
    }

    &.heartBtnUnliked {
      color: rgb(156, 159, 161);
    }
  }
}

// .companyCardContainer {
//   position: relative;

//   width: 100%;

//   aspect-ratio: 3/1.8;
//   overflow: hidden;
//   box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);

//   .companyCard_v2 {
//     color: white;
//     text-shadow: -1px 1px 2px black;

//     width: 100%;
//     height: 100%;

//     .closedTextContainer {
//       position: absolute;
//       top: 0;
//       left: 0;

//       display: flex;
//       justify-content: center;
//       align-items: center;

//       background-color: rgba(0, 0, 0, 0.6);

//       height: 100%;
//       width: 100%;

//       .closedText {
//         transform: rotateZ(-10deg);

//         border: 3px solid white;
//         background-color: rgba(255, 255, 255, 0.2);

//         padding: 0px 12px;

//         font-size: 22px;

//         margin-bottom: 10px;
//       }
//     }

//     .companyLogoImg {
//       width: 100%;
//       height: 100%;

//       object-fit: cover;

//       transition: 300ms;
//     }

//     .infoContainer {
//       position: absolute;
//       bottom: 0;

//       background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), black);

//       width: 100%;

//       .adjustForPadding {
//         padding: 25px 5px 5px 5px;

//         .companyName {
//           font-size: 18px;

//           overflow: hidden;
//           display: -webkit-box;
//           -webkit-line-clamp: 2;
//           -webkit-box-orient: vertical;
//         }

//         .companyLocation {
//           color: rgb(214, 214, 214);
//           font-size: 16px;
//           font-weight: 500;
//           line-height: 1;
//         }

//         .bottomContainer {
//           display: flex;
//           justify-content: space-between;

//           .reviewsContainer {
//             display: flex;
//             align-items: flex-end;
//             font-size: 16px;

//             .reviewsTextContainer {
//               font-size: 14px;
//             }
//           }
//         }
//       }
//     }
//   }

//   .heartButtonContainer {
//     position: absolute;
//     right: 0;
//     bottom: 0;

//     display: flex;
//     justify-content: center;
//     align-items: center;

//     font-size: 20px;

//     height: 35px;
//     width: 35px;

//     // background-color: blue;

//     .fa-solid {
//       color: #ec4141;
//     }

//     .fa-regular {
//       color: white;
//     }
//   }
// }

// .companyCardContainer:hover {
//   .companyCard_v2 {
//     .companyLogoImg {
//       transform: scale(1.08);
//     }
//   }
// }

.companyCardSkeletonContainer {
  position: relative;

  width: 100%;

  aspect-ratio: 3/1.8;
  overflow: hidden;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);

  .companyCard_v2Skeleton {
    background-color: white;
    text-shadow: -1px 1px 2px black;

    width: 100%;
    height: 100%;

    .infoContainer {
      position: absolute;
      bottom: 0;

      width: 100%;

      .adjustForPadding {
        padding: 25px 5px 5px 5px;

        .companyName {
          @include textSkeletonPrefab(18px, 180px);
        }

        .companyLocation {
          margin-top: 5px;
          @include textSkeletonPrefab(16px, 80px);
        }

        .bottomContainer {
          .reviewsContainer {
            display: flex;
            align-items: flex-end;
            margin-top: 5px;
            gap: 5px;

            .starReviews {
              @include textSkeletonPrefab(16px, 96px);
            }

            .numReviewsText {
              @include textSkeletonPrefab(14px, 70px);
            }
          }
        }
      }
    }
  }
}
