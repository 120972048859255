@import "../../../mainColorLibrary.scss";

$height: 50px;
$iconFontSize: 21px;

$animationsSpeed: 300ms;

@mixin pageLink {
  display: grid;
  color: white;
  text-decoration: none;

  height: $height;
  width: 100%;

  .linkIcon {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  .fa-solid {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  .cartTotalItems {
    position: absolute;
    text-align: center;

    color: $mainColor-1;

    width: 20px;

    font-size: 12px;
    font-weight: 700;

    margin-left: 31px;
    margin-top: 7px;
  }

  i {
    display: flex;
    justify-content: center;

    font-size: 18px;

    width: 100%;

    margin-top: 8px;
  }

  .linkText {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    height: 15px;

    margin-bottom: 5px;
  }
}

.DeliverymobileFooter {
  display: flex;
  justify-content: space-between;

  position: fixed;
  bottom: 0;

  background-color: $deliveryMainColor-1;

  height: $height;
  width: 100%;

  z-index: 2;

  .activePageLink {
    @include pageLink();

    background-color: $deliveryMainColor-2;
  }

  .pageLink {
    @include pageLink();
  }
}

.extraSpacing {
  height: $height + 5;
}

.moreBtnContainer {
  width: 100%;

  .moreBtn {
    @include pageLink();
  }

  .dropDownContent {
    margin-bottom: 200px;
    color: red;
  }
}
