@import "../components/navigation/Navbar.scss";
@import "../components/navigation/FooterAndSidebars/FooterMediaQueries.scss";
@import "../components/navigation/FooterAndSidebars/SmallSidebar.scss";
@import "../components/navigation/FooterAndSidebars/LargeSidebar.scss";

$screenSize-small: 600px;
$screenSize-medium: 601px;
$screenSize-large: 1200px;

.thePage {
  padding-top: $navbarHeight;

  @media only screen and (max-width: $screenSize-small) {
    padding-left: 0px;
  }

  @media only screen and (min-width: $screenSize-medium) {
    padding-left: $smallSidebarWidth;
  }
  @media only screen and (min-width: $screenSize-large) {
    padding-left: $largeSidebarWidth;
  }
}

.mobileFooterContainer {
  display: none;

  @media only screen and (max-width: $screenSize-small) {
    display: inline;
  }
}
