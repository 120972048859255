@import "../../mainColorLibrary.scss";

.sponsor-form-submissions {
  padding: 20px;

  h2 {
    margin-top: 0px;
  }

  .header {
    display: flex;
    border-bottom: solid 1px #cecece;

    p {
      width: 25%;
      font-weight: 600;
    }
  }

  .data .row {
    display: flex;
    border-bottom: solid 1px #cecece;

    p {
      width: 25%;
    }

    button {
      border: none;
      background-color: $CTA-color;
      color: white;
      height: 30px;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;

      :disabled {
        background-color: #cecece;
        cursor: not-allowed;
      }
    }
  }
}
