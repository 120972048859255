// ↓↓↓↓↓↓↓↓↓↓↓↓ Main Pages ↓↓↓↓↓↓↓↓↓↓↓↓↓

$mainColor-1: #397420;
$mainColor-2: #649b4a;
$mainColor-3: #1eb910;
$mainColor-4: #93cf75;

$secondaryColor-1: #8d8966;
$secondaryColor-2: #f0efe7;
$secondaryColor-2-darker: #dbd9ca;

$green-blue: #008564;

$CTA-color: #0079a1; // CTA = Call To Action
$CTA-color-darker: #014a62;
$CTA-color-hover: #008fa0;
$CTA-color-darker-hover: #00636e;

$CTA-color-2: #007e48;
$CTA-color-2-hover: rgba(12, 142, 171, 0.13);

$CTA-color-3: #0089d6; // CTA = Call To Action
$CTA-color-3-darker: #014a62;
$CTA-color-3-hover: #007fc9;
$CTA-color-3-darker-hover: #065d8f;

$CTA-color-4: #f88826;

$danger-color: #c34242;
$danger-color-darker: #9c0017;
$danger-color-hover: #e54e4e;
$danger-color-darker-hover: #a91229;

$danger-color-2: #a00000;

$skeleton-bg-color: lightgray;

@mixin textSkeletonPrefab($_height, $_width) {
  @keyframes example {
    0% {
      background-color: $skeleton-bg-color;
    }
    10% {
      background-color: $skeleton-bg-color;
    }
    50% {
      background-color: rgb(199, 199, 199);
    }
    90% {
      background-color: $skeleton-bg-color;
    }
    100% {
      background-color: $skeleton-bg-color;
    }
  }

  background-color: $skeleton-bg-color;
  border-radius: 5px;

  height: $_height;
  width: $_width;

  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

$myBusinessMainColor-1: $mainColor-1;
$myBusinessMainColor-2: $mainColor-2;

// ↓↓↓↓↓↓↓↓↓↓ Delivery Pages ↓↓↓↓↓↓↓↓↓↓↓

$deliveryMainColor-1: #53502f;
$deliveryMainColor-2: #74704d;
$deliveryMainColor-3: #afac99;

$delivery-CTA-color: #588a65;
$delivery-CTA-color-darker: #588a65;
$delivery-CTA-color-hover: #629d72;
$delivery-CTA-color-darker-hover: #306e41;

$deliver-danger-color: #bc5a40;
$deliver-danger-color-darker: #97452e;
$deliver-danger-color-hover: #c8684e;
$deliver-danger-color-darker-hover: #97452e;
