@import "../../../mainColorLibrary.scss";

.socialCompany {
  .getSocial {
    .titleContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;

      .updateAboutLink {
        background-color: $CTA-color;
        color: white;

        padding: 5px 8px;
        border-radius: 5px;

        text-decoration: none;
      }

      @media (max-width: 450px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;

        h2 {
          text-align: center;
          height: 10px;
          width: 100%;
        }

        .updateAboutLink {
          background-color: $CTA-color;
          color: white;

          padding: 5px 12px;
          border-radius: 5px;

          text-decoration: none;
        }
      }
    }

    .checkIfTheyWorkTxt {
      text-align: center;
      color: #a56a24;
      font-weight: 500;
      max-width: 420px;

      margin-bottom: 20px;
    }

    .allSocialMediasContainer {
      overflow: hidden;
      margin: 0px 5px;

      .socialMediaLink {
        display: flex;
        align-items: center;

        gap: 10px;

        // color: rgb(0, 0, 150);
        text-decoration: none;

        max-width: 500px;
        margin-bottom: 10px;

        .urlText {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          // color: rgb(0, 0, 150);
          text-decoration: underline;
        }

        i {
          font-size: 28px;
        }

        .fa-square-facebook {
          color: #3b5998;
        }

        .fa-instagram {
          background-image: linear-gradient(
            45deg,
            #feda75,
            #fa7e1e,
            #d62976,
            #962fbf,
            #4f5bd5
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .tiktokLogo {
          height: 28px;
          width: 28px;
          object-fit: cover;
          object-position: center center;
        }

        .fa-twitter {
          color: #00acee;
        }

        .fa-youtube {
          color: #c4302b;
        }
      }
    }
  }
}
