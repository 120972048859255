@import "../../mainColorLibrary.scss";

$inputContainerMaxWidth: 400px;

// * {
//   outline: 1px solid red;
// }

.addProductPage {
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 1050px) {
    display: flex;
    flex-wrap: nowrap;
  }

  .dummyProductCardSection {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    width: 100%;

    @media only screen and (max-width: 1050px) {
      height: auto;
      padding: 50px 0px;
    }

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .dummyProductCardContainer {
      width: 100%;
      max-width: 400px;
      padding: 20px;
      border-radius: 10px;

      border: 1px dashed rgb(150, 150, 150);
    }
  }

  .productFormContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .updateProductForm {
      @mixin InputTitlePrefab() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        width: 94%;
        max-width: $inputContainerMaxWidth;

        .inputTitle {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          width: 100%;
          margin-bottom: 5px;

          .inputTitleText {
            text-align: start;

            width: 45%;

            font-size: 20px;
            font-weight: 500;
          }

          .inputIntrusctionText {
            display: flex;
            font-size: 15px;
            color: grey;
          }
        }

        .icon-container {
          .active {
            font-size: 3em;
            color: rgb(37, 205, 22);
          }
          .not-active {
            font-size: 3em;
            color: black;
          }

          .available {
            font-size: 3em;
            color: rgb(0, 187, 45);
          }

          .exhausted {
            font-size: 3em;
            color: rgb(255, 0, 0);
          }
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .allInputsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        width: 100%;
        max-width: 400px;

        @media only screen and (min-width: 880px) {
          display: grid;
          grid-template-columns: 1fr;
        }

        .imageInputContainer {
          @include InputTitlePrefab();
          width: 98%;

          .customFileUpload {
            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgb(230, 230, 230);
            color: rgb(152, 152, 152);
            border: 1px solid rgb(152, 152, 152);

            height: 40px;
            width: 100%;
            max-width: 400px;

            border-radius: 5px;

            font-size: 18px;

            cursor: pointer;

            input {
              display: none;
            }

            &.redError {
              border: 1px solid red;
              color: red;
            }
          }

          .error {
            &.redError {
              color: red;
            }
          }
        }

        .priceCurrencyContainer {
          width: 100%;
          display: flex;
          align-items: center;
          column-gap: 3px;
        }

        .currencyContainer {
          position: relative;

          .toggleContainer {
            display: flex;
            align-items: center;
            column-gap: 3px;

            background-color: white;
            border: 1px solid rgb(141, 141, 141);
            border-radius: 5px;

            height: 40px;
            padding: 1px 5px;

            cursor: pointer;
          }
        }

        .currencyDropdown {
          position: absolute;

          background-color: white;
          border: 1px solid rgb(141, 141, 141);
          border-radius: 5px;

          top: 42px;
          left: 0px;

          p {
            margin: 10px 0px;
            padding: 0px 12px;
            cursor: pointer;
          }
        }

        .inputContainer {
          @include InputTitlePrefab();

          width: 98%;

          input {
            border: 1px solid rgb(141, 141, 141);

            height: 40px;
            width: 100%;
            max-width: 400px;

            border-radius: 5px;
          }

          textarea {
            border: 1px solid rgb(141, 141, 141);

            height: 100px;
            width: 100%;
            max-width: 400px;

            border-radius: 5px;

            resize: none;
          }

          select {
            border: 1px solid rgb(141, 141, 141);

            height: 40px;
            width: 100%;
            max-width: 400px;

            border-radius: 5px;

            font-size: 16px;
          }

          .imageInput {
            border: none;

            height: 40px;
            width: 100%;
            max-width: 400px;

            border-radius: 0px;
          }
        }

        .visibleToSubscribersInputContainer {
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 100%;

          margin-top: 20px;

          .inputTitle {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 98%;
            margin-bottom: 5px;

            .inputTitleText {
              text-align: start;

              width: 100%;

              font-size: 20px;
              font-weight: 500;
            }

            .inputSubtitleText {
              text-align: start;

              width: 100%;

              font-size: 14px;
            }
          }

          .visibilityBtnsContainer {
            display: flex;
            justify-content: center;

            width: 90%;

            .visiblityBtn {
              display: flex;
              justify-content: center;
              align-items: center;

              background-color: rgb(200, 200, 200);
              color: black;
              border: 1px solid transparent;

              height: 30px;
              width: 920%;

              padding: 5px;
              border-radius: 5px;

              font-size: 18px;
            }
          }
        }
      }

      .saveAndCancelBtnsContainer {
        display: flex;
        justify-content: space-between;

        width: 80%;
        max-width: 400px;

        margin-bottom: 10px;

        .deleteBtnContainer {
          button {
            background-color: $danger-color;
            border: 1px solid $danger-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $danger-color-hover;
            border: 1px solid $danger-color-darker-hover;
          }
        }

        .saveBtnContainer {
          button {
            background-color: $CTA-color;
            border: 1px solid $CTA-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $CTA-color-hover;
            border: 1px solid $CTA-color-darker-hover;
          }
        }
      }
    }
  }
}
