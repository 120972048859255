@import "../../mainColorLibrary.scss";

.extraPagesNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;

  border-bottom: 1px solid rgb(200, 200, 200);

  height: 50px;
  width: 100%;
  gap: 4px;

  @mixin PageLink($txtColor) {
    color: $txtColor;
    border-bottom: 3px solid transparent;
    font-weight: 500;
    text-decoration: none;
    height: 30px;
    padding: 0px 5px;
  }

  .pageLink {
    @include PageLink(black);
  }

  .activePageLink {
    @include PageLink($mainColor-3);
    border-color: $mainColor-3;
  }
}
