/* OrdersInProgress.scss */

.contentOrdersInProgress {
  margin: 5px;

  .pageTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .ordersContainer {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    transition: opacity 0.2s ease;

    .orderInProgressCard {
      display: block;
      background-color: #ecebe7;
      color: black;
      border: 1px solid #c9c7b9;
      box-shadow: -2px 5px 8px rgba(122, 119, 102, 0.4);

      text-decoration: none;

      padding: 10px;

      transition: 300ms ease;

      border-radius: 15px;

      &:hover {
        background-color: #afac99;
        border: 1px solid #95927a;
      }

      .cardInfo {
        font-size: 18px;

        .title {
          font-weight: 500;
          margin-right: 5px;
        }

        .info {
        }
      }

      .cardInfo__fontSize {
        font-size: 16px;
      }

      .cardInfo__marginBottom {
        margin-bottom: 5px;
      }
    }
  }
}
