@import "../../pages/CompanyPage/CompanyHomePage.scss";

$inputContainerMaxWidth: 400px;

.updateCompanyPage {
  .dummyCompanyCardAndBannerContainer {
    .bannerImageContainer {
      .bannerImage {
        @include BannerImage();
      }
    }

    .companyCardContainerWrapper {
      display: flex;
      justify-content: center;

      .dummyCompanyCardContainer {
        max-width: 360px;
      }
    }
  }

  .companyFormContainer {
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    .contentUploadedImage {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;

      max-width: 450px;

      @mixin InputTitlePrefab() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

        width: $inputContainerMaxWidth;

        .inputTitle {
          display: flex;
          justify-content: center;

          width: 90%;
          margin-bottom: 5px;

          .inputTitleText {
            text-align: start;

            width: 100%;

            font-size: 20px;
            font-weight: 500;
          }
        }
      }

      .imageInputContainer {
        @include InputTitlePrefab();

        .customFileUpload {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: rgb(230, 230, 230);
          color: rgb(152, 152, 152);
          border: 1px solid rgb(152, 152, 152);

          height: 40px;
          width: 92%;
          max-width: 400px;

          border-radius: 5px;

          font-size: 18px;

          cursor: pointer;

          input {
            display: none;
          }
        }
        .customFileUpload.error {
          border: 2px solid red;
        }
      }
    }

    .updateCompanyForm {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      max-width: 450px;

      @mixin InputTitlePrefab() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        width: $inputContainerMaxWidth;

        .inputTitle {
          display: flex;
          justify-content: center;

          width: 90%;
          max-width: 400px;
          margin-bottom: 5px;

          .inputTitleText {
            text-align: start;

            width: 100%;

            font-size: 20px;
            font-weight: 500;
          }
        }
      }

      .inputContainer {
        @include InputTitlePrefab();

        input {
          border: 1px solid rgb(141, 141, 141);

          height: 40px;
          width: 90%;
          max-width: 400px;

          border-radius: 5px;

          &.error {
            border: 2px solid red;
          }
        }

        textarea {
          border: 1px solid rgb(141, 141, 141);

          height: 60px;
          width: 90%;
          max-width: 400px;

          border-radius: 5px;

          &.error {
            border: 2px solid red;
          }
        }

        select {
          border: 1px solid rgb(141, 141, 141);

          height: 40px;
          width: 91%;
          max-width: 400px;

          border-radius: 5px;

          &.error {
            border: 2px solid red;
          }
        }
      }

      .saveAndCancelBtnsContainer {
        display: flex;
        justify-content: space-between;

        width: 80%;
        max-width: 400px;

        margin-bottom: 10px;

        .deleteBtnContainer {
          button {
            background-color: $danger-color;
            border: 1px solid $danger-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $danger-color-hover;
            border: 1px solid $danger-color-darker-hover;
          }
        }

        .saveBtnContainer {
          button {
            background-color: $CTA-color;
            border: 1px solid $CTA-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $CTA-color-hover;
            border: 1px solid $CTA-color-darker-hover;
          }
        }
      }
    }
  }
}
