@import "../../mainColorLibrary.scss";

$bannerImageHeight-1: 100px;
$bannerImageHeight-2: 120px;
$bannerImageHeight-3: 150px;
$bannerImageHeight-4: 180px;
// ==============================
$bannerImageHeight-5: 165px;
$bannerImageHeight-6: 195px;
$bannerImageHeight-7: 230px;
$bannerImageHeight-8: 270px;
$bannerImageHeight-9: 315px;
// ==============================
$bannerImageHeight-10: 305px;
$bannerImageHeight-11: 360px;
$bannerImageHeight-12: 415px;
$bannerImageHeight-13: 485px;
$bannerImageHeight-14: 565px;
// ==============================

$bannerImageMaxHeight: 700px;

$profileImageHeightAndWidth: 80px;

$phoneWidth: 600px;

@mixin BannerImage() {
  width: 100%;
  aspect-ratio: 16/4.5;

  object-fit: cover;
  object-position: center center;
}

.companyHeader {
  .topSection {
    .bannerImageContainer {
      .placeholderBanner {
        background-color: #e0e0e0;
        text-align: center;
        padding: 10px;
      }
      .bannerImage {
        @include BannerImage();
      }
    }

    .companyHeader {
      .nameAndFollowBtnContainer {
        margin-top: 2px;
        margin-left: 5px;

        @media only screen and (min-width: 600px) {
          display: flex;
        }

        .profileImgAndNameContainer {
          display: flex;
          align-items: center;
          gap: 10px;

          .imageContainer {
            .companyProfileImage {
              height: $profileImageHeightAndWidth;
              width: 140px;
              border-radius: 5px;

              @media only screen and (max-width: 500px) {
                height: 70px;
                width: 120px;
              }

              @media only screen and (max-width: 360px) {
                height: 60px;
                width: 100px;
              }

              object-fit: cover;
              object-position: center center;

              border: 1px solid rgb(227, 227, 227);

              &.laod {
                display: none;
              }
            }
          }

          .logoImageModal {
            .background {
              position: fixed;
              top: 0;

              z-index: 4;

              background-color: rgba(0, 0, 0, 0.6);

              width: 400vw;
              height: 100vh;

              margin-left: -400px;
            }

            .logoImage {
              width: 90%;

              max-height: 600px;
              max-width: 600px;

              position: fixed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              border-radius: 10px;

              z-index: 5;

              object-fit: cover;
              object-position: center center;

              backdrop-filter: blur(2px);
              box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8);
            }
          }

          .nameAndReviewsContainer {
            .companyNameText {
              font-weight: 500;
              font-size: 25px;

              @media only screen and (max-width: 500px) {
                font-size: 20px;
              }

              @media only screen and (max-width: 360px) {
                font-size: 18px;
              }
            }

            .contentMsj {
              display: flex;
              align-items: center;

              .textMsj {
                font-weight: 600;
                color: red;
              }
            }

            .reviewsAndFollowBtnContainer {
              display: flex;

              .addOrRemoveFavoritesBtnsContainer {
                display: flex;
                justify-content: center;
                align-items: center;

                margin-left: -5px;

                @mixin AddOrRemoveBtn {
                  display: flex;
                  justify-content: space-evenly;
                  align-items: center;

                  border-radius: 5px;

                  padding: 5px 5px;

                  font-size: 20px;
                }

                .RemoveFavoritesBtn {
                  @include AddOrRemoveBtn();

                  background-color: #d0d9c800;
                  color: rgb(184, 6, 6);

                  border: 1px solid rgba(151, 151, 151, 0);
                }

                .addToFavoritesBtn {
                  @include AddOrRemoveBtn();

                  // background-color: #97916d;
                  background-color: #80794400;

                  color: rgb(24, 0, 0);
                  border: 1px solid transparent;
                }
              }

              .starReviewsContainer {
                display: flex;
                align-items: center;

                .numReviewsLink {
                  font-size: 14px;
                  font-weight: 400;

                  color: black;

                  @media only screen and (max-width: 330px) {
                    font-size: 12px;
                  }
                }

                .showReviewsLink {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  color: rgb(13, 0, 31);

                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.linksAndSearchBarContainer {
  @media only screen and (min-width: 600px) {
    margin: 0px 5px;
  }

  @media only screen and (min-width: 1050px) {
    display: flex;
    justify-content: space-between;
  }

  // background-color: rgba(26, 26, 28, 0.104);

  .companyLinksContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;

    height: 35px;
    width: 100%;

    font-size: 17px;

    // background-color: rgba(255, 42, 38, 0.142);

    @media only screen and (min-width: 1050px) {
      justify-content: space-between;
      width: 200px;
    }

    @mixin CompanyBtn() {
      background-color: none;
      color: black;
      border: 1px solid transparent;

      border-bottom: 3px solid transparent;

      font-size: 14px;
      font-weight: 500;

      cursor: pointer;

      transition: border-bottom 150ms;
    }

    .activeCompanyBtn {
      @include CompanyBtn();
      border-bottom: 3px solid $mainColor-1;
    }

    .companyBtn {
      @include CompanyBtn();
    }
    .companyBtn:hover {
      border-bottom: 3px solid $mainColor-1;
    }
  }

  .searchBarContainer {
    // background-color: rgba(48, 33, 255, 0.156);

    .searchBarWrapper {
    }
  }
}
