.ServicePage {
  .servicePinnedCardsContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    margin-bottom: 10px;

    overflow-y: hidden;
    overflow-x: auto;

    .servicePinnedCardsWrapper {
      display: flex;
      gap: 5px;

      width: 100%;

      @media only screen and (min-width: 800px) {
        justify-content: center;
      }

      transition: ease all 0.5s;
    }
  }

  .ServiceCardsContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .ServiceCardsWrapper {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      place-items: center;

      width: 100%;

      @media only screen and (min-width: 600px) {
        width: 98.6%;
      }

      transition: ease all 0.5s;
    }
  }
}

.loadMoreContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .loadMoreButton {
    border: none;
    color: white;
    background-color: #2d6e8a;

    font-size: large;
    font-weight: bold;

    padding: 6px;
  }
}
