@import "../../mainColorLibrary.scss";

.orderCompanyProducts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin: 10px 0px;

  .orderCardsWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    max-width: 600px;

    margin: 0px 5px;

    .notOrders {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }

    .orderCard {
      background-color: rgb(255, 255, 255);
      box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);

      border-radius: 5px;

      width: 100%;
      max-width: 600px;
      padding: 10px;

      margin-bottom: 10px;

      .topSectionInfo {
        display: flex;
        flex-direction: column-reverse;
        font-weight: 500;
      }

      .productList {
        margin-top: 10px;

        .subtitle {
          font-weight: 500;
        }

        .productItem {
          display: flex;
          flex-direction: column;

          margin-top: 5px;
          margin-left: 15px;
          border-bottom: 1px solid grey;

          .itemLabelContainer {
            display: flex;
            label {
              margin-right: 5px;
            }
            .itemDetail {
              width: 100%;
            }
          }

          .itemDetails {
            display: flex;
            flex-wrap: nowrap;
            .itemAmount {
              margin-right: 5px;
            }

            .itemPrice {
              display: flex;
              align-items: center;
              font-size: small;
              max-width: 85px;
              margin-left: 10px;

              .disocunt {
                font-size: 10px;
                color: green;
                margin-right: 5px;
                font-weight: bold;
              }

              .item_total {
                display: flex;
                font-size: 1.2em;

                .currency {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      .orderTotalPrice {
        font-size: 18px;
        font-weight: 500;

        margin-top: 10px;
      }
    }
  }

  .paginationBtns {
    $btnHeight: 30px;

    display: flex;
    justify-content: center;
    flex-wrap: nowrap;

    width: 100%;

    margin-top: 20px;

    @mixin PaginationBtn() {
      background-color: $CTA-color;
      color: white;
      border: 1px solid $CTA-color-darker;
      padding: 2px;

      height: $btnHeight;
      width: 68px;
    }

    @mixin PaginationBtn_Disabled() {
      background-color: #8da0a8;
      color: #59808f;
      border: 1px solid #59808f;
      padding: 2px;
      height: $btnHeight;
      width: 68px;
    }

    .paginationBtnBack {
      @include PaginationBtn();

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .paginationBtnNext {
      @include PaginationBtn();

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .paginationBtnBack:disabled {
      @include PaginationBtn_Disabled();

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .paginationBtnNext:disabled {
      @include PaginationBtn_Disabled();

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .currentPageTxt {
      background-color: rgb(230, 230, 230);
      border: 1px solid grey;

      height: $btnHeight;
    }
  }
}
