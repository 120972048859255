@import "../../mainColorLibrary.scss";

.contentLink {
  display: flex;
  justify-content: flex-start;
  .visitLink {
    text-decoration: none;
    color: $CTA-color-3;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.orderDetails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .orderDetailsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    width: 100%;
    max-width: 500px;

    margin-top: 5px;

    .orderDetails {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      background-color: white;
      box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);

      width: 100%;
      max-width: 500px;
      padding: 5px;

      border-radius: 7px;

      margin: 5px;
      margin-bottom: 10px;

      .topSection {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        width: 100%;
        margin-bottom: 10px;

        .orderUserName {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .orderInfo {
        width: 100%;

        .order-key-word {
          display: flex;
          flex-direction: row;
          .keyWord {
            font-weight: 500;
            margin-left: 5px;
            margin-right: 5px;
            color: $CTA-color-3;
          }
        }
      }

      .orderProducts {
        .companyInfo {
          width: 100%;
          margin-top: 10px;

          .companyItem {
            font-weight: 500;
            font-size: 18px;
          }

          .companyTotalPrice {
            font-weight: 500;
          }

          .wrapperItem {
            margin-left: 15px;
            border-bottom: 1px solid grey;
            .item {
              display: flex;
              flex-direction: column;
              cursor: pointer;
              user-select: none;
              margin-top: 5px;

              .itemLabelContainer {
                display: flex;
                align-items: center;
                label {
                  margin-right: 10px;
                }
                .itemDetail {
                  width: 100%;
                }
              }

              .itemDetails {
                display: flex;
                flex-wrap: nowrap;
                .itemAmount {
                  margin-right: 7px;
                }

                .itemPrice {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  margin-left: 7px;

                  .price {
                    color: black;
                  }

                  .discountedPriceContainer {
                    display: flex;
                    flex-direction: row;

                    .originalPrice {
                      color: black;
                      text-decoration: line-through;
                    }

                    .containerDiscount {
                      display: flex;
                      .discountedPrice {
                        color: black;
                        margin-left: 2px;
                      }
                    }
                  }
                  .currency {
                    margin: 2px;
                    margin-left: 2px;
                    font-size: 12px;
                  }

                  .wrapperIcon {
                    margin-left: 8px;
                    .contentIcon {
                      .fas {
                        font-size: 1.3em;
                      }
                    }
                  }
                }
              }
            }

            .warpperDropDown {
              user-select: none;
              background-color: whitesmoke;
              border-radius: 5px;
              .contentDropDown {
                .nameCategory {
                  /* */
                }
              }
            }
          }
        }
      }

      .orderTotal {
        width: 100%;
        margin-top: 20px;

        font-size: 18px;
        font-weight: 500;
      }
    }

    .orderMessage {
      background-color: rgb(255, 255, 255);
      width: 300px;
      height: 168px;
      border-radius: 5px;
      padding: 4px;

      .subtitle {
        margin-bottom: 5px;
        font-size: 17px;
      }

      .contentMessage {
        display: flex;
        justify-content: center;
        textarea {
          margin: 0 4px 0 4px;
          width: 100%;
          height: 100px;

          resize: none;
        }
      }
    }

    .orderAdress {
      margin: 5px;

      .subtitle {
        font-weight: 500;
      }

      .address-card {
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .cancelBtnContainer {
      display: flex;
      justify-content: center;
      width: 100%;

      margin-top: 20px;
      margin-bottom: 10px;

      .loadMoreBtn {
        background-color: $CTA-color;

        color: white;
        font-weight: 500;
        font-size: medium;

        border: 1px solid $CTA-color;
        border-radius: 5px;

        padding: 5px 10px;

        transition: 100ms;
      }

      .loadMoreBtn:hover {
        background-color: $CTA-color-hover;
        color: white;

        border: 1px solid $CTA-color-darker-hover;
        border-radius: 5px;

        padding: 5px 10px;
      }
    }
  }
}
