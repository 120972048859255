@import "../../../mainColorLibrary.scss";

.myProductsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  background-color: rgb(250, 250, 250);
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(221, 221, 221);

  min-width: 350px;
  max-width: 95%;
  border-radius: 7px;
  padding-bottom: 10px;

  margin: 10px;

  @media only screen and (min-width: 790px) {
    min-width: 90%;
    max-width: 1000px;

    padding: 0px 20px 10px 20px;
  }

  .title {
    text-align: center;
    font-size: 20px;

    width: 100%;
  }

  .contentPorductsPinned {
    width: 100%;
    margin-bottom: 20px;

    .productPinnedCardsContainer {
      display: flex;
      justify-content: center;
      overflow-y: hidden;
      overflow-x: auto;

      .productPinnedCardsWrapper {
        display: flex;
        gap: 5px;

        width: 100%;

        @media only screen and (min-width: 800px) {
          justify-content: center;
        }

        transition: ease all 0.5s;

        .productCard {
          display: flex;
          flex-direction: column;
          height: auto;

          .contentLink {
            display: flex;
            justify-content: center;

            .actionBtn {
              background-color: lightgray;
              color: black;
              text-decoration: none;

              padding: 4px 10px;
              border-radius: 0px;

              transition: 200ms;
            }

            .actionBtn:hover {
              background-color: $CTA-color;
              color: white;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }

  .contentLatestPorducts {
    width: 100%;

    .productsContainer {
      display: grid;
      grid-gap: 0.5em;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));

      width: 100%;
      padding: 10px 5px;

      .productInfoContainer {
        margin-bottom: 20px;

        .buttonsContainer {
          display: flex;
          gap: 10px;
          margin-top: 5px;

          .actionBtn {
            background-color: lightgray;
            color: black;
            text-decoration: none;

            padding: 4px 10px;
            border-radius: 0px;

            transition: 200ms;
          }

          .editBtn:hover,
          .optionsBtn:hover {
            background-color: $CTA-color;
            color: white;
            border-radius: 3px;
          }

          .deleteBtn:hover {
            background-color: $danger-color;
            color: white;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .moreProductsLinkContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    margin-top: 5px;

    .moreProductsLink {
      background-color: $CTA-color;
      border: 1px solid $CTA-color-darker;
      color: white;

      border-radius: 5px;
      padding: 5px 10px;

      text-decoration: none;

      cursor: pointer;
      transition: 100ms;
    }

    .moreProductsLink:hover {
      background-color: $CTA-color-hover;
      border: 1px solid $CTA-color-darker-hover;
    }
  }
}

.deleteProductModal {
  position: fixed;
  height: 100%;
  width: 100%;

  z-index: 100;

  .background {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 400vw;
    height: 100vh;
    margin-left: -400px;
  }

  .error {
    color: $danger-color;
  }

  .modalContent {
    position: fixed;
    top: 50%;
    left: 50%;

    background-color: rgb(241, 241, 241);
    border: 1px solid rgb(190, 190, 190);

    width: 90%;
    max-width: 400px;
    padding: 10px;

    border-radius: 7px;

    transform: translate(-50%, -50%);
    z-index: 101;

    .textContainer {
      text-align: center;
      .titleText {
        font-size: 18px;
      }
    }

    .productInfoContainer {
      display: flex;

      margin: 10px 0px;

      .productImgContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        $imgHeigt: 180px;

        height: $imgHeigt;
        width: $imgHeigt;

        img {
          height: $imgHeigt;
          width: $imgHeigt;
          border-radius: 7px;

          object-fit: cover;
          object-position: center center;
        }
      }

      .productName {
        font-size: 16px;
        margin-left: 5px;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }

    .buttonsContainer {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 10px;

      button {
        height: 40px;
        width: 100px;
        border-radius: 5px;
        font-size: 16px;
        transition: 100ms;
      }

      .cancelBtn {
        background-color: $CTA-color;
        color: white;
        border: 1px solid $CTA-color-darker;
      }

      .cancelBtn:hover {
        background-color: $CTA-color-hover;
        color: white;
        border: 1px solid $CTA-color-darker-hover;
      }

      .deleteBtn {
        background-color: $danger-color;
        border: 1px solid $danger-color-darker;
        color: white;
      }

      .deleteBtn:hover {
        background-color: $danger-color-hover;
        border: 1px solid $danger-color-darker-hover;
      }
    }
  }
}

.modalContent {
  .textContainer {
    text-align: center;

    .modalTitle {
      font-size: 18px;
    }
  }
  .optionSection {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100%;

    .contentDelete {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin: 2px;
      padding: 2px;

      .iconTrash {
        font-size: 1.4em;
        color: rgba(163, 25, 25, 0.851);
      }

      .warapperCardDelete {
        width: 150px;
        margin-top: 80px;
        position: absolute;

        .contentCardDelete {
          position: relative;
          background-color: rgb(170, 169, 169);
          height: 50px;

          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 4px;

          .contentButton {
            margin: 2px;
            padding: 4px;
            .btnCancel {
              background-color: rgba(0, 149, 255, 0.896);
              color: #ffffff;
              padding: 5px;
              margin-left: 3px;
              margin-right: 3px;
            }
            .btnDelete {
              background-color: rgba(177, 4, 4, 0.851);
              color: #ffffff;
              padding: 5px;
              margin-left: 3px;
              margin-right: 3px;
            }
          }
        }
      }
    }

    .btnCategory {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 300px;
      height: 80px;

      border-radius: 5px;
      margin: 5px;
      background-color: rgb(211, 209, 209);

      label {
        font-size: large;
      }

      .contentIcon {
        .fa-plus {
          font-size: 1.3em;
          color: black;
        }
      }
    }

    .optionWrapper {
      width: 100%;
      margin: 5px;

      border-radius: 5px;
      background-color: rgb(234, 231, 231);

      .optionContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 4px;

        .contentBtnSubmit {
          display: flex;
          justify-content: flex-end;

          margin: 2px;
          margin-right: 4px;

          .btnSave {
            padding: 4px;

            &:not(:disabled) {
              background-color: rgba(0, 149, 255, 0.896);

              color: #ffffff;
              cursor: pointer;
            }

            &:disabled {
              background-color: rgba(0, 149, 255, 0.234);
              color: #1515158a;
              cursor: not-allowed;
            }
          }
        }

        .sectionOption {
          border-radius: 5px;
          margin: 5px;
          background-color: #ffffff;

          .contentFormCategory {
            width: 100%;

            .sectionTitle {
              display: flex;
              flex-direction: column;
              align-items: center;
              label {
                font-size: large;
              }

              input {
                margin: 1px;
                border-radius: 2px;
                border: none;
                border-bottom: 1px solid black;
                background-color: #ffffff;
              }
            }

            .sectionIsRequired {
              .isRequiredLabel {
                margin: 5px;
                font-size: large;
              }
              .radioContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .radioOption {
                  margin-left: 5px;
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .attributeContent {
          display: flex;
          align-items: center;
          width: 250px;
          flex-direction: column;
          margin: 5px;

          .btnAddNewOption {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 180px;
            height: 50px;
            background-color: #ffffff;

            label {
              font-size: large;
            }

            .contentIcon {
              .fa-plus {
                font-size: 1.3em;
                color: black;
              }
            }
          }

          .attributeCard {
            background-color: #ffffff;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 10px;

            .contentDelete {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .iconTrash {
                color: rgba(177, 4, 4, 0.851);
                font-size: 1.2em;
              }
            }

            .sectionAvailable {
              label {
                font-weight: lighter;
              }

              .contentToggle {
                .toggleOn {
                  font-size: 2em;
                  color: rgb(23, 245, 3);
                }
                .toggleOff {
                  font-size: 2em;
                  color: red;
                }
              }
            }

            .inputAttribute {
              width: 100%;
              padding: 8px;
              box-sizing: border-box;
              margin-bottom: 8px;
              border: none;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }
}
