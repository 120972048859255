@import "../../../mainColorLibrary.scss";
@import "../../../commonStyles.scss";

.userPostAddressPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pageTitle {
    height: 32px;
    font-size: 24px;
    font-weight: 500;
    border-bottom: 2px solid $mainColor-1;

    margin: 10px 0px;
  }

  .inputContainer {
    width: 98%;
    max-width: 400px;
    margin-bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 18px;

      .optionalTxt::before {
        content: "(Optional)";
        color: grey;
      }
    }

    input,
    select,
    textarea {
      border: 1px solid black;
      border-radius: 5px;

      width: 97%;
      padding: 8px 5px;
    }

    select {
      width: 100%;
      font-size: 16px;
    }

    textarea {
      height: 160px;
      resize: vertical;
    }
  }

  .phoneNumberInputContainer {
    width: 98%;
    max-width: 400px;
    margin-bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 18px;

      .optionalTxt::before {
        content: "(Optional)";
        color: grey;
      }
    }

    .inputsContainer {
      display: flex;
      gap: 3px;

      input,
      select {
        border: 1px solid black;
        border-radius: 5px;

        padding: 8px 5px;
      }

      input {
        width: 90%;
      }
    }
  }

  .saveBtnContainer {
    display: flex;
    justify-content: center;

    gap: 20px;
    margin-bottom: 20px;

    button {
      @include Button($CTA-color, white, $CTA-color);
      width: 100px;

      &:hover {
        @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
      }
    }

    .cancelBtn {
      @include Button($danger-color, white, $danger-color);

      &:hover {
        @include Button($danger-color-hover, white, $danger-color-darker-hover);
      }
    }
  }
}
