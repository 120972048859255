@import "../../mainColorLibrary.scss";

.homeDriver {
  .titleContainer {
    font-weight: 500;

    .title {
      text-align: center;
      font-size: 22px;
    }

    .subtitle {
      text-align: center;
      font-size: 18px;
    }
  }

  .orderCardsSection {
    display: flex;
    justify-content: center;

    .orderCardsContainer {
      width: 100%;
      margin: 5px;

      .allOrdersWrapper {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;

        .orderCardContainer {
          position: relative;

          display: flex;
          justify-content: center;
          width: 100%;
          max-width: 400px;

          margin-bottom: 20px;

          .orderCard {
            background-color: $deliveryMainColor-3;
            color: black;

            width: 100%;
            max-width: 400px;
            border-radius: 5px;
            padding: 5px;

            text-decoration: none;

            .topSection {
              display: flex;
              justify-content: space-between;
              align-items: top;
              margin-bottom: 10px;

              .nameAndLocationContainer {
                .nameTxt {
                  font-size: 18px;
                  font-weight: 500;
                }

                .locationTxt {
                  i {
                  }
                }
              }

              .dateTxt {
                font-size: 14px;
                font-weight: 500;
              }
            }

            .businessesSection {
              margin-bottom: 10px;
              .title {
              }

              .contentCompanies {
                display: flex;
                flex-direction: column;

                .businessName {
                  font-size: 18px;
                  font-weight: 500;
                  margin: 2px;
                }
              }
            }

            .extraInfoSection {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;

              margin-bottom: 10px;
            }

            .priceContainer {
              display: flex;
              align-items: center;
              font-weight: 600;

              .dollarSign {
                font-size: 16px;
              }

              .priceTxt {
                font-size: 22px;
              }
            }
          }

          .takeOrderBtnContainer {
            position: absolute;
            bottom: -15px;
            right: 10px;

            // margin-right: 10px;
            border-radius: 10px;

            background-color: #f0f0f0;

            padding: 5px;

            .diverCTAButton {
              background-color: $delivery-CTA-color;
              border: 1px solid $delivery-CTA-color-darker;
              color: white;

              text-align: center;

              padding: 5px 10px;
              border-radius: 5px;

              cursor: pointer;

              transition: 100ms;

              font-size: 16px;

              option {
                background-color: white;
                color: black;
              }
            }

            .diverCTAButton:hover {
              background-color: $delivery-CTA-color-hover;
              border: 1px solid $delivery-CTA-color-darker-hover;
            }
          }
        }
      }
    }
  }
}
