@import "../../mainColorLibrary.scss";

.page-container {
  padding: 10px 20px;

  .user-review-container {
    border-bottom: 1px solid black;
    padding-bottom: 15px;

    .header-container {
      display: flex;
      align-items: center;
      column-gap: 10px;

      button {
        background-color: $CTA-color;
        color: white;
        text-decoration: none;
        padding: 5px 15px;
        border-radius: 5px;
      }
    }

    p {
      font-weight: 600;
      margin: 0px;
    }
  }

  .left-icon {
    margin-right: 5px;
  }

  .back-link {
    color: black;
  }

  .title-container {
    display: flex;
    column-gap: 10px;
    align-items: center;

    button {
      background-color: $CTA-color;
      color: white;
      text-decoration: none;
      padding: 5px 15px;
      border-radius: 5px;
    }
  }

  .rating-container {
    margin-top: 15px;

    .top-section {
      display: flex;
      align-items: center;

      .name-and-date-container {
        display: flex;
        column-gap: 10px;
        margin-left: 10px;
      }

      .stars {
        margin-left: 5px;
      }

      .img-container {
        width: 45px;
        height: 45px;
        overflow: hidden;
        border-radius: 100%;

        img {
          width: 100%;
        }
      }
    }

    .text {
      margin-top: 10px;
      margin-left: 3px;
    }
  }

  .pagination-container {
    display: flex;
    margin-top: 35px;

    p {
      padding: 5px 20px;
      margin: 0px;
    }

    button {
      background-color: $CTA-color;
      color: white;
      text-decoration: none;
      padding: 5px 5px;
      width: 100px;
      border-radius: 5px;

      &:disabled {
        background-color: $skeleton-bg-color;
        cursor: not-allowed;
      }
    }
  }
}
