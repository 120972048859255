@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

.cartPage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .scheduleModal {
    .background {
      position: fixed;
      top: 0;
      left: 0;

      z-index: 1000;

      background-color: rgba(0, 0, 0, 0.4);

      height: 100%;
      width: 100%;
    }

    .modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      z-index: 1000;

      padding: 8px;
      border-radius: 16px;

      background-color: white;

      .title {
        font-size: 20px;
        font-weight: 600;
      }

      .dayAndTimeContainer {
        margin: 16px 0px;

        .day {
        }

        .time {
        }
      }
    }
  }

  .cartSection {
    width: 100%;
    max-width: 500px;

    margin-bottom: 5px;

    .headerContainer {
      position: sticky;
      top: 50px;
      z-index: 2;

      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        background-color: white;
        border: 1px solid rgb(212, 212, 212);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

        width: 100%;

        .title {
          display: flex;
          justify-content: center;
          font-size: 22px;
          font-weight: 500;
        }

        .bottomSection {
          display: flex;
          justify-content: space-between;
          align-items: center;

          height: 40px;
          padding: 0px 10px;
          margin-top: 10px;

          .subtotalText {
            font-size: 20px;
          }

          .ContentCheckout {
            .checkoutBtn {
              @include Button($CTA-color, white, $CTA-color);
              font-size: 16px;
              padding: 4px 12px;

              .fa-lock {
                margin-left: 5px;
              }
            }

            .checkoutBtn.disabled {
              cursor: not-allowed;
              background-color: #a00000;
              border: 1px solid #a00000;
            }
          }
        }
      }

      .scheduleBtnContainer {
        width: 100%;

        .scheduleBtn {
          background-color: white;
          box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);

          text-align: center;
          line-height: 1.2;

          max-width: 140px;
          padding: 4px 8px;
          border-radius: 0px 0px 8px 8px;

          margin-top: -1px;
        }
      }

      .message {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        background-color: #f9e686;
        border: 1px solid #ecc76a;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

        max-width: 95%;
        min-width: 180px;
        padding: 4px 15px;
        border-radius: 5px;

        margin: 5px 0px;

        .text {
          font-size: 1em;
          font-weight: 600;
        }
      }
    }

    .cartItemsContainer {
      .cartItemsWrapper {
        .wereProductIsFromText {
          font-size: 20px;
          font-weight: 500;

          margin-top: 10px;
        }

        .productContainer {
          position: relative;

          padding: 5px 5px 5px 5px;

          .topSection {
            display: flex;
            flex-wrap: nowrap;

            color: black;
            text-decoration: none;

            .imgContainer {
              display: flex;
              justify-content: center;
              align-items: center;

              height: 100%;
              width: 110px;

              img {
                height: 100%;
                width: 100%;

                object-fit: cover;
                object-position: center center;
              }
            }

            .rightSide {
              width: 70%;
              margin-left: 5px;

              .productNameText {
                overflow: hidden;
                display: flex;
                flex-direction: row;
                text-align: center;

                .wrapperIcon {
                  margin-left: 5px;
                  .contentIcon {
                    .fas {
                      color: #079cdc;
                      font-weight: bold;
                      font-size: 1.5em;
                    }
                  }
                }

                .productName {
                  color: black;
                  margin-right: 5px;
                  font-size: large;
                }
              }

              .companyNameText {
                color: rgb(97, 97, 97);
                font-size: 13px;

                overflow: hidden;
                display: -webkit-box;

                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }

              .warpperDropDown {
                position: absolute;
                z-index: 100;
                background-color: #2d6e8a;

                border-radius: 5px;

                .closed {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  margin: 0 4px 0 4px;

                  .iconClose {
                    color: whitesmoke;
                    font-size: 1.1em;
                    font-weight: bold;
                  }
                }

                .contentDropDown {
                  position: relative;
                  width: 250px;
                  height: auto;

                  .nameCategory {
                    color: whitesmoke;
                    font-weight: bold;
                    font-size: large;
                  }

                  .contentAttribute {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .name {
                      color: whitesmoke;

                      font-size: 16px;
                    }
                    .price {
                      color: whitesmoke;
                      font-size: 18px;
                      margin-left: 5px;
                    }
                  }
                }
              }

              .priceAndMemberIconContainer {
                display: flex;
                align-items: center;
                gap: 5px;

                .priceText {
                  font-size: 18px;
                  font-weight: 500;

                  .additionalPrice {
                    display: flex;

                    .text {
                      font-size: large;
                    }

                    .formatAdditionalPrice {
                      margin-left: 10px;
                    }
                  }
                }

                .verifiedIcon {
                  color: $mainColor-3;
                  font-size: 18px;
                  margin-right: 5px;
                  margin-left: 15px;
                  margin-bottom: 15px;
                }
              }

              .totalPriceText {
                font-weight: 500;
              }
            }

            .warpperDropDown {
              position: absolute;
              z-index: 100;

              background-color: #2d6e8a75;
              background-color: #2d6e8a;

              padding: 5px;

              border-radius: 5px;

              .closed {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 0 4px 0 4px;

                .iconClose {
                  color: whitesmoke;
                  font-size: 1.1em;
                  font-weight: bold;
                }
              }

              .contentDropDown {
                position: relative;

                .nameCategory {
                  color: whitesmoke;
                  font-weight: bold;
                  font-size: large;
                }

                .contentAttribute {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  padding-left: 10px;

                  .name {
                    color: whitesmoke;
                    font-size: 16px;
                  }

                  .price {
                    color: whitesmoke;
                    font-size: 18px;
                    margin-left: 5px;
                  }
                }
              }
            }
          }

          .bottomSection {
            position: absolute;
            bottom: 0;

            display: flex;
            justify-content: space-between;

            margin: 0px 0px 5px 0px;

            @mixin btnStyles($color) {
              background-color: transparent;
              color: $color;
              border: 2px solid $color;
              border-radius: 3px;

              font-size: 14px;
              padding: 0px 10px;

              transition: 120ms;
            }

            .removeFromCartBtn {
              @include btnStyles(#ff0000);
              font-size: 16px;

              margin-right: 10px;

              &:hover {
                background-color: rgba(160, 0, 0, 0.1);
              }
            }

            .showProductsExtraOptions {
              display: flex;
              justify-content: center;
              align-items: center;

              background-color: transparent;
              color: gray;
              border: 2px solid lightgray;
              border-radius: 5px;

              font-size: 18px;
              font-weight: 600;
              padding: 0px 10px;

              transition: 120ms;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
