@import "../../../mainColorLibrary.scss";

$smallSidebarHeight: 100%;
$smallSidebarWidth: 80px;
$iconFontSize: 25px;

@mixin pageLink() {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  color: white;

  text-decoration: none;

  // height: 40px;
  padding: 5px 0px;
  width: $smallSidebarWidth/1.1;

  border-radius: 8px;

  margin-bottom: 8px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .linkIcon {
      font-size: 20px;
    }

    i {
      font-size: $iconFontSize;
    }
  }

  .linkTextContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .linkText {
      font-size: 10px;
    }
  }
}

.myBusinessSmallSidebar {
  margin-top: 50px;

  position: fixed;

  background-color: $myBusinessMainColor-1;
  color: white;

  border-top: 1px solid transparent;

  height: $smallSidebarHeight;
  min-height: 317px;
  width: $smallSidebarWidth;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  z-index: 2;

  .linksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    padding-bottom: 47px;

    .activePageLink {
      @include pageLink();
      background-color: $myBusinessMainColor-2;
    }

    .pageLink {
      @include pageLink();
    }
    .pageLink:hover {
      background-color: $myBusinessMainColor-2;
    }
  }
}
