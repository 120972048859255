@import "../../../mainColorLibrary.scss";

$largeSidebarHeight: 100%;
$largeSidebarWidth: 220px;
$sidebarBtnIconFontSize: 22px;

@mixin pageLink {
  display: flex;
  align-items: center;

  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;

  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
  }

  .cartIconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;

    .cartTotalItems {
      position: absolute;
      color: $mainColor-1;

      font-size: 12px;
      font-weight: 700;

      margin-left: 3px;
      margin-top: -1px;
    }
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

@mixin dropDownBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;

  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
  }

  .cartIconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;

    .cartTotalItems {
      position: absolute;
      color: $mainColor-1;

      font-size: 12px;
      font-weight: 700;

      margin-left: 3px;
      margin-top: -1px;
    }
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

@mixin button {
  display: flex;
  align-items: center;

  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;
  border: none;
  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
    margin: 8px;
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

.largeSidebar {
  margin-top: 50px;

  position: fixed;

  background-color: $mainColor-1;
  color: white;

  height: $largeSidebarHeight;
  width: $largeSidebarWidth;

  z-index: 2;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      // background: rgb(175, 175, 175);
      background-color: #a4ae9e;

      border-radius: 500px;
    }
  }

  .topLinksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: 10px;

    border-bottom: 1px solid rgba(156, 206, 149, 0.521);

    .dropDownBtn {
      @include dropDownBtn();
    }

    .dropDownBtn:hover {
      background-color: $mainColor-2;
    }

    .dropdown-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 10px;

      border-bottom: 1px solid rgba(156, 206, 149, 0.521);
    }

    .activePageLink {
      @include pageLink();
      background-color: $mainColor-2;
    }

    .pageLink {
      @include pageLink();
    }

    .pageLink:hover {
      background-color: $mainColor-2;
    }
  }

  .favoritesSectionContainer {
    justify-content: center;
    border-bottom: 1px solid rgba(156, 206, 149, 0.521);
    margin-top: 5px;
    padding: 0px 10px;

    .favoritesTitleContainer {
      width: $largeSidebarWidth / 1.15;

      .titleText {
        font-size: 17px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 5px;
      }

      .pageLink {
        @include pageLink();
      }

      .pageLink:hover {
      }
    }

    .favoritesContainer {
      .favorite {
        display: flex;

        color: white;
        text-decoration: none;

        padding: 3px 5px;
        border-radius: 8px;

        margin-bottom: 2px;

        &:hover {
          background-color: $mainColor-2;
        }

        .imageContainer {
          position: relative;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 4px;

          img {
            width: 70px;
            aspect-ratio: 3/1.8;

            border-radius: 5px;

            object-fit: cover;
            object-position: center center;

            text-shadow: -1px 1px 2px rgb(0, 0, 0);
            font-size: 15px;
            font-style: italic;
          }

          .closedTextContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 0;
            left: 0;

            background-color: rgba(0, 0, 0, 0.6);

            height: 100%;
            width: 100%;

            border-radius: 5px;

            .closedText {
              transform: rotateZ(-8deg);

              background-color: rgba(255, 255, 255, 0.15);
              border: 2px solid white;
              text-shadow: -1px 1px 2px black;

              padding: 0px 5px;

              font-size: 14px;
            }
          }
        }

        .companyName {
          display: flex;
          align-items: center;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          font-size: 15px;
        }

        .companyLocation {
          font-size: 14px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .activePageLink {
        background-color: $mainColor-2;
      }

      .seeMore {
        @include pageLink();
        background-color: transparent;
        border: none;

        &:hover {
          background-color: $mainColor-2;
        }

        :disabled {
          cursor: not-allowed;
        }

        i {
          display: flex;
          align-items: center;
          justify-content: center;

          margin: 0px 10px;
          margin-bottom: 2px;

          font-size: 16px;
        }

        .seeMoreTxt {
          display: flex;
          align-items: center;

          font-size: 15px;
          font-weight: 500;

          margin-left: 3px;
        }
      }
    }
  }

  .extraInfoContainer {
    text-align: center;
    font-size: 12px;

    margin-top: 10px;
    margin-bottom: 90px;

    a {
      color: white;
      text-decoration: none;
    }
  }
}
