@import "../mainColorLibrary.scss";

.sponsor-company {
  width: 50%;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);
  padding: 20px 0px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  .title {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
  }

  .description {
    text-align: center;
    font-size: 16px;
  }

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    opacity: 1;

    i {
      color: green;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-container {
      display: flex;
      flex-direction: column;
      width: 400px;
      margin-top: 20px;

      input {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border: 1px solid rgb(141, 141, 141);
        margin-top: 5px;
        padding-right: 5px;
        padding-left: 5px;
      }

      input[type="file"] {
        width: 100%;
        height: fit-content;
        border: none;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0px;
      }

      .input-with-icon {
        position: relative;

        i {
          position: absolute;
          top: 50%;
          right: 0px;
        }
      }

      .disclaimer {
        margin: 0px;
        font-size: 14px;
        opacity: .6;
      }
    }

    .error {
      margin: 0px;
      font-size: 14px;
      color: $danger-color;
      margin-top: 1px;
      margin-bottom: 1px;
    }

    .submit {
      cursor: pointer;
      background-color: $CTA-color;
      color: white;
      height: 40px;
      width: 150px;
      border: none;
      border-radius: 5px;
      padding-left: 7px;
      padding-right: 7px;
      margin-top: 20px;
    }

    .submit:disabled {
      opacity: .5;
    }
  }

  .hidden {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
}
