@import "../../components/navigation/FooterAndSidebars/LargeSidebar.scss";
@import "../../components/navigation/FooterAndSidebars/SmallSidebar.scss";

.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  .error-message {
    font-size: 0.9em;
    color: white;
  }
  .background {
    position: fixed;
    top: 0;

    background-image: radial-gradient(
      circle,
      #24242a,
      #212126,
      #1e1e21,
      #1b1b1d,
      #181819
    );

    height: 100vh;
    width: 100vw;

    z-index: -1;
  }

  .contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100vw;

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .contentBox {
      background-color: rgba(255, 255, 255, 0.05);
      color: rgb(255, 255, 255);
      border: 1px solid rgba(255, 255, 255, 0.1);

      max-width: 90vw;
      border-radius: 10px;

      padding: 10px;
      padding-bottom: 20px;

      margin: 10px 0px;

      z-index: 1;

      .galeanaBizContainer {
        display: flex;
        justify-content: center;

        color: white;
        text-decoration: none;

        $height: 40px;
        height: $height;

        margin-bottom: 10px;

        .logoImg {
          height: $height;
          margin-right: 5px;
          filter: invert(100%);
        }

        .titleText {
          font-size: 30px;
          font-weight: 500;
        }
      }

      .loginToYourAccountText {
        text-align: center;

        font-size: 20px;
        font-weight: 500;

        margin-bottom: 20px;
      }

      .invalidCredentials {
        display: flex;
        flex-wrap: nowrap;

        justify-content: space-between;
        align-items: center;

        background-color: rgba(212, 77, 77, 0.473);
        border: 1px solid rgb(212, 77, 77);

        height: 50px;
        border-radius: 7px;

        .text {
          font-size: 16px;
          font-weight: 500;
        }

        .closeBtn {
          display: flex;
          justify-content: center;
          align-items: center;

          // border: 1px solid transparent;

          height: 100%;
          width: 50px;
        }
      }

      .usernameAndPasswordForm {
        $inputWidth: 100%;
        width: 100%;

        .usernameInputContainer {
          width: $inputWidth;

          margin-bottom: 20px;

          .inputTitle {
            color: rgb(122, 122, 122);
          }

          input {
            background-color: rgba(250, 250, 250, 0.08);
            color: rgb(222, 222, 222);

            height: 50px;
            width: $inputWidth - 4;

            padding-left: 10px;

            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 5px;

            font-size: 16px;

            @media only screen and (min-width: 600px) {
              width: $inputWidth - 4;
            }
          }
          input.error {
            border: 2px solid red;
          }
        }

        .passwordInputContainer {
          width: $inputWidth;

          margin-bottom: 20px;

          .inputTitle {
            color: rgb(122, 122, 122);
          }

          .inputAndShowpasswordBtn {
            $height: 50px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            height: $height;

            input {
              background-color: rgba(250, 250, 250, 0.08);
              color: rgb(222, 222, 222);

              height: 100%;
              width: $inputWidth;

              padding-left: 10px;

              border: 1px solid rgba(0, 0, 0, 0.3);
              border-radius: 5px;

              font-size: 16px;

              @media only screen and (min-width: 600px) {
                width: $inputWidth - 4;
              }
            }
            input.error {
              border: 2px solid red;
            }

            .iconContainer {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              .showPasswordBtn {
                display: flex;
                justify-content: center;
                align-items: center;

                background-color: rgba(250, 250, 250, 0.08);
                color: rgb(222, 222, 222);
                border: 1px solid rgba(0, 0, 0, 0.3);

                border-radius: 5px;

                height: $height + 3px;
                width: 50px;

                font-size: 16px;
              }
            }
          }
        }

        .signInBtnContainer {
          display: flex;
          position: relative;

          $submitBtnHeight: 50px;

          background: rgba(188, 203, 216, 0.1);
          color: white;
          border: 1px solid transparent;

          height: $submitBtnHeight;
          width: 100%;
          border-radius: 7px;

          font-size: 16px;

          margin-top: 10px;

          .submitBtn {
            position: absolute;

            background-color: transparent;
            color: white;
            border: transparent;

            height: 100%;
            width: 100%;

            font-size: 16px;
          }

          .btnBackground {
            height: 100%;
            width: 0%;
            border-radius: 7px;

            transition: 300ms ease;

            background-image: linear-gradient(
              to right,
              #2e5c1a,
              #33671c,
              #397420,
              #3a7d1f,
              #3b851d,
              #3c8e1a,
              #3c9717,
              #3b9d15,
              #39a213,
              #37a810,
              #33ac10,
              #2db010,
              #27b510,
              #1eb910
            );
          }
        }

        .signInBtnContainer:hover {
          .btnBackground {
            width: 100%;
          }
        }
      }

      .noAccountText {
        text-align: center;
        margin-top: 20px;

        // background-color: red;

        a {
          color: $mainColor-3;
          text-decoration: none;
        }
      }
    }
  }
}
