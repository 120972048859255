$cardHeight: 260px;
$cardMaxWidth: 380px;

$cardBorderRadius: 7px;

.productCardV2Container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  aspect-ratio: 3/2;

  .productCard_V2 {
    position: relative;

    color: black;
    text-decoration: none;
    background-color: white;

    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      aspect-ratio: 3/2;

      object-fit: cover;
      object-position: center;
    }

    .soldOutOverlay {
      position: absolute;
      top: 0;
      left: 0;

      z-index: 2;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: rgba(0, 0, 0, 0.6);
      color: red;

      width: 100%;
      height: 100%;

      .soldOutText {
        font-size: 24px;
        border: 3px solid red;

        padding: 5px 10px;

        transform: rotateZ(-10deg);
      }
    }

    .discountContainer {
      position: absolute;
      top: 0;
      right: 20px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: rgb(0, 166, 255);
      color: white;
      box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);

      padding: 0px 8px;
      height: 80px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      font-weight: bold;

      .percentage {
        font-size: 24px;
      }

      .offText {
        font-size: 18px;
      }
    }

    .memberOnlyTag {
      position: absolute;
      top: 0;
      left: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      text-align: center;

      background-color: #397420;
      color: white;
      box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);

      height: 80px;
      max-width: 60px;

      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .logoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        margin-top: -5px;

        .LGMemberTagLogo {
          height: 100%;
          width: 100%;

          height: 40px;
          width: 40px;
        }

        .logoTitle {
          text-align: center;
          width: 100%;

          font-size: 9px;
          line-height: 0.8;
          margin-top: 0px;

          text-wrap: nowrap;
        }
      }

      .tagTitle {
        font-size: 13px;
        line-height: 1.2;
      }
    }

    .infoSection {
      position: absolute;
      bottom: 0;
      left: 0;

      display: flex;
      align-items: flex-end;

      background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      color: white;
      text-shadow: -1px 1px 2px rgb(0, 0, 0);

      width: 100%;
      height: 100%;
      padding-top: 10px;

      .infoContainer {
        padding: 5px;

        width: 100%;

        .productName {
          font-size: 18px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .productOwner {
          color: rgb(212, 212, 212);
          margin-top: -5px;
          margin-bottom: 4px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          max-width: 100%;
        }

        .productOwner__discount {
          max-width: 58%;
        }

        .bottomInfoContainer {
          position: relative;

          display: flex;
          justify-content: space-between;

          width: 100%;

          .reviewsContainer {
            display: flex;

            font-size: 18px;

            .ratingNum {
              display: flex;

              color: rgb(212, 212, 212);
              font-size: 16px;

              margin-left: 5px;
            }
          }

          .priceTagContainer {
            position: absolute;
            right: 5px;
            bottom: 0;

            text-shadow: -1px 1px 2px rgba(0, 0, 0);

            .priceContainer {
              text-align: end;

              .price {
                font-size: 24px;

                .decimal {
                  font-size: 16px;
                }
              }

              .currency {
                font-size: 15px;
                margin-left: 3px;
              }
            }

            .priceContainer__oldPrice {
              position: relative;
              color: lightgray;

              .lineThrough {
                position: absolute;
                top: 11px;
                right: 0;

                height: 2px;
                width: 78%;

                background-color: lightgray;
              }

              .price {
                font-size: 17px;

                .decimal {
                  font-size: 13px;
                }
              }

              .currency {
                font-size: 12px;
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
  }
}
