@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

.myCompanyPage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.noBusinessPage {
  .titleContainer {
    display: flex;
    justify-content: center;

    .subtitle {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }

    .title {
      text-align: center;

      font-size: 26px;
      font-weight: 500;

      margin: 0px 10px;
      margin-bottom: 30px;

      width: 100%;
    }
  }

  .createBusinessBtnContainer {
    display: flex;
    justify-content: center;

    .createBusinessBtn {
      @include Button($CTA-color, white, $CTA-color);
    }

    .createBusinessBtn:hover {
      @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
    }
  }
}
