@import "../../../mainColorLibrary.scss";

$wideProductCardHeight: 200px;

$tallProductCardHeight: 340px;
$tallProductCardWidth: 220px;
$tallBorderRadius: 5px;

.addProductContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(221, 221, 221);

  width: 95%;
  max-width: 440px;
  padding: 10px;
  border-radius: 7px;

  margin: 10px 5px 0px 5px;

  .title {
    text-align: center;
    font-size: 20px;

    width: 100%;

    margin-bottom: 15px;
  }

  .skeletonProductCard_V2 {
    // .imgContainer
  }

  @media only screen and (max-width: 790px) {
    .skeletonProductCardContainer {
      display: none;
    }

    .skeletonWideProductCardContainer {
      width: 100%;

      .skeletonProductCard {
        display: flex;
        flex-wrap: nowrap;

        background-color: rgb(240, 240, 240);
        color: black;
        border: 1px solid rgb(225, 225, 225);
        border-right: 0;
        border-left: 0;

        height: $wideProductCardHeight;
        width: 100%;

        text-decoration: none;

        .leftSide {
          background-color: $skeleton-bg-color;

          height: 100%;
          width: 35%;
          min-width: 150px;
          margin: 0px 1px;
        }

        @media only screen and (max-width: 410px) {
          height: 170px;

          .leftSide {
            min-width: 102px;
          }
        }

        .rightSide {
          width: 65%;
          margin: 0px 5px;

          .productName {
            @include textSkeletonPrefab(15px, 150px);
            margin-top: 2px;
          }

          .productCompany {
            @include textSkeletonPrefab(12px, 70px);
            margin-top: 4px;
          }

          .startReviewsContainer {
            display: flex;
            align-items: flex-end;
            margin-top: 5px;

            .starReviews {
              @include textSkeletonPrefab(18px, 90px);
            }

            .numRatings {
              @include textSkeletonPrefab(13px, 70px);
              margin-left: 5px;
            }
          }

          .productPrice {
            @include textSkeletonPrefab(22px, 70px);
            margin-top: 15px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 790px) {
    .skeletonWideProductCardContainer {
      display: none;
    }

    .skeletonTallProductCardContainer {
      display: flex;
      justify-content: center;
      width: 100%;

      .skeletonProductCard {
        background-color: white;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.1);
        // border: 1px solid rgb(213, 213, 213);

        height: $tallProductCardHeight;
        width: $tallProductCardWidth;
        border-radius: $tallBorderRadius;

        .topSection {
          height: $tallProductCardHeight / 2;
          width: $tallProductCardWidth;
          border-top-left-radius: $tallBorderRadius;
          border-top-right-radius: $tallBorderRadius;

          background-color: $skeleton-bg-color;
        }

        .infoSection {
          margin-top: 3px;
          margin-left: 3px;

          .productName {
            @include textSkeletonPrefab(15px, 150px);
          }

          .productCompany {
            @include textSkeletonPrefab(12px, 70px);
            margin-top: 5px;
          }

          .starReviewsContainer {
            display: flex;
            align-items: flex-end;

            margin-top: 5px;

            .starReviews {
              @include textSkeletonPrefab(16px, 80px);
            }

            .numRatings {
              @include textSkeletonPrefab(11px, 70px);
              margin-left: 3px;
            }
          }

          .productPrice {
            @include textSkeletonPrefab(20px, 70px);
            margin-top: 15px;
          }
        }
      }
    }
  }

  .actionBtnContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    margin-top: 10px;

    .actionBtn {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $CTA-color;
      border: 1px solid $CTA-color;
      color: white;

      height: 25px;
      padding: 5px 15px;
      border-radius: 5px;

      text-decoration: none;

      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;

      cursor: pointer;
      transition: 100ms;
    }

    .disabled {
      cursor: not-allowed;

      i {
        margin-left: 5px;
      }
    }

    .actionBtn:hover {
      background-color: $CTA-color-hover;
      border: 1px solid $CTA-color-darker-hover;
    }
  }
}
