@import "../../mainColorLibrary.scss";

.noAccountContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80vh;

  .noAccountContent {
    .title {
      display: flex;
      justify-content: center;
      font-size: 25px;
      font-weight: 400;
    }

    .loginLinkContainer {
      display: flex;
      justify-content: center;

      font-size: 30px;

      font-weight: 500;

      .loginLink {
        color: black;
        text-decoration-color: rgb(164, 94, 63);
      }
    }
  }
}
