@import "../mainColorLibrary.scss";
@import "../commonStyles.scss";

.favoritesPage {
  .navBar {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 20px;

    padding: 5px;

    .navbarBtn {
      border-bottom: 3px solid transparent;
      border-radius: 0;

      font-size: 18px;
      font-weight: 500;
    }

    .navbarBtn__active {
      color: $mainColor-3;
      border-color: $mainColor-3;
    }
  }

  .pageContent {
    .title {
      text-align: center;

      font-size: 20px;
      font-weight: 600;

      padding: 10px 0px;
    }
  }

  .companyCardsSection {
    display: flex;
    justify-content: center;

    .companyCardsContainer {
      width: 100%;
      padding: 7px;

      transition: ease all 0.5s;

      @media only screen and (min-width: 600px) {
        padding: 10px;
      }

      .companyCardsWrapper,
      .productCardsWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
        place-items: center;
        grid-gap: 10px;

        transition: ease all 0.5s;

        &.productCardsWrapper {
          grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
        }

        .companyCardAndBtnContainer,
        .productCardAndBtnContainer {
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 100%;

          margin-bottom: 20px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 80%;

            @include Button($CTA-color, white, $CTA-color);

            font-size: 16px;
            font-weight: 500;

            margin-top: 5px;

            &:hover {
              @include Button($danger-color, white, $danger-color);
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .loadMoreBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
    margin-bottom: 30px;

    .loadMoreBtn {
      @include Button($CTA-color, white, $CTA-color);

      &:hover {
        @include Button($CTA-color-hover, white, $CTA-color-hover);
      }
    }
  }
}
