@import "../../../commonStyles.scss";

.formPostAdressCompany {
  display: flex;
  flex-direction: column;
  align-items: center;

  .inputContainer {
    display: flex;
    flex-direction: column;

    width: 98%;
    max-width: 400px;

    margin-bottom: 15px;

    label {
      margin-bottom: 0px;
      font-weight: bold;

      width: 100%;
    }

    input,
    select,
    textarea {
      border: 1px solid #ccc;
      border-radius: 4px;

      padding: 8px;
    }

    textarea {
      height: 100px;

      resize: none;
    }
  }

  .phoneInputContainer {
    display: flex;

    label {
      width: 100%;
    }

    .selectAndInputContainer {
      display: flex;

      select {
        padding: 8px 4px;

        border-right: 1px solid transparent;

        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      input {
        width: 100%;

        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    gap: 10px;

    .cancelBtnContainer {
      button {
        @include Button($danger-color, white, $danger-color);
      }
    }

    .cancelBtnContainer:hover {
      button {
        @include Button($danger-color-hover, white, $danger-color-darker-hover);
      }
    }

    .saveBtnContainer {
      button {
        @include Button($CTA-color, white, $CTA-color);
      }

      button:hover {
        @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
      }
    }
  }
}
