@import "../../../commonStyles.scss";

.addressBusiness {
  .companyAddressWrapper {
    margin-top: 10px;

    .contentAddressTop {
      .sectionTopAddress {
        display: flex;
        align-items: center;
        gap: 10px;

        .titleCompanyAddress {
          font-size: 1.5em;
          margin: 10px;
          font-weight: bold;
        }

        .contentLink {
          .updateCompanyAddressLink {
            background-color: #2d6e8a;
            color: white;
            text-decoration: none;
            font-size: 17px;
            border: 1px solid #2d6e8a;
            border-radius: 5px;
            padding: 5px 8px;
            transition: 100ms;
          }
          .updateCompanyAddressLink:hover {
            background-color: rgb(43, 129, 222);
            border-color: #065fa3;
          }
        }
      }

      .addressCompanyContainer {
        display: flex;
        flex-direction: column;

        align-items: flex-start;
        justify-content: center;

        width: 100%;
        max-width: 450px;
      }
    }
  }
}

.addressCard {
  background-color: white;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 7px;

  width: 90%;
  padding: 10px;

  margin: 5px;

  overflow: hidden;

  .addressCardTitle {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .addressCardInfo {
    margin-top: 5px;
    .addressCardInfoItem {
      display: flex;
      align-items: center;

      margin-top: 5px;

      .addressCardInfoLabel {
        margin-left: 5px;
        font-size: large;
      }

      .addressCardInfoText {
        margin-left: 5px;
        font-size: 16px;
      }
    }

    .addressCardInfoTextAreaItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 5px;

      .addressCardInfoLabel {
        margin-left: 5px;
        font-size: large;
      }

      .addressCardInfoTextArea {
        margin-left: 10px;
        font-size: 16px;

        resize: none;
      }
    }
  }
}
