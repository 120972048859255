@import "../../../mainColorLibrary.scss";

.myCompanyProductsPage {
  margin-bottom: 10px;

  .myProductsHeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid rgb(200, 200, 200);

    height: 50px;
    width: 100%;
    gap: 10px;

    @mixin PageLink($txtColor) {
      color: $txtColor;
      border-bottom: 3px solid transparent;
      font-weight: 500;
      text-decoration: none;
      height: 30px;
      padding: 0px 5px;
    }

    .pageLink {
      @include PageLink(black);
    }

    .activePageLink {
      @include PageLink($mainColor-3);
      border-color: $mainColor-3;
    }
  }

  .productManagementContainer {
    .productsContainer {
      display: grid;
      grid-gap: 0.5em;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));

      width: 100%;
      padding: 10px 5px;

      .productInfoContainer {
        margin-bottom: 20px;

        .buttonsContainer {
          display: flex;
          gap: 10px;
          margin-top: 5px;

          .actionBtn {
            background-color: lightgray;
            color: black;
            text-decoration: none;

            padding: 4px 10px;
            border-radius: 0px;

            transition: 200ms;
          }

          .editBtn:hover,
          .optionsBtn:hover {
            background-color: $CTA-color;
            color: white;
            border-radius: 3px;
          }

          .deleteBtn:hover {
            background-color: $danger-color;
            color: white;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .switchPageContainer {
    $btnHeight: 30px;

    display: flex;
    justify-content: center;
    flex-wrap: nowrap;

    margin-top: 20px;

    @mixin SwitchPageBtn() {
      background-color: $CTA-color;
      color: white;
      border: 1px solid $CTA-color-darker;

      height: $btnHeight;
      width: 60px;
    }

    @mixin SwitchPageBtn_Disabled() {
      background-color: #8da0a8;
      color: #59808f;
      border: 1px solid #59808f;

      height: $btnHeight;
      width: 60px;
    }

    .switchPageBtnBack {
      @include SwitchPageBtn();

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .switchPageBtnNext {
      @include SwitchPageBtn();

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .switchPageBtnBack:disabled {
      @include SwitchPageBtn_Disabled();

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .switchPageBtnNext:disabled {
      @include SwitchPageBtn_Disabled();

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .currentPageTxt {
      background-color: rgb(230, 230, 230);
      border: 1px solid grey;
      height: $btnHeight;
    }
  }

  .total {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .notPorducts {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .deleteProductModal {
    position: fixed;
    height: 100%;
    width: 100%;

    z-index: 100;

    .error {
      color: $danger-color;
    }

    .background {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 400vw;
      height: 100vh;
      margin-left: -400px;
    }

    .modalContent {
      position: fixed;
      top: 50%;
      left: 50%;

      background-color: rgb(241, 241, 241);
      border: 1px solid rgb(190, 190, 190);

      width: 90%;
      max-width: 400px;
      padding: 10px;

      border-radius: 7px;

      transform: translate(-50%, -50%);
      z-index: 101;

      .textContainer {
        text-align: center;

        .titleText {
          font-size: 18px;
        }
      }

      .productInfoContainer {
        display: flex;

        margin: 10px 0px;

        .productImgContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          $imgHeigt: 180px;

          height: $imgHeigt;
          width: $imgHeigt;

          img {
            height: $imgHeigt;
            width: $imgHeigt;
            border-radius: 7px;

            object-fit: cover;
            object-position: center center;
          }
        }

        .productName {
          font-size: 16px;
          margin-left: 5px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
        }
      }

      .buttonsContainer {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;

        button {
          height: 40px;
          width: 100px;
          border-radius: 5px;
          font-size: 16px;
          transition: 100ms;
        }

        .cancelBtn {
          background-color: $CTA-color;
          color: white;
          border: 1px solid $CTA-color-darker;
        }

        .cancelBtn:hover {
          background-color: $CTA-color-hover;
          color: white;
          border: 1px solid $CTA-color-darker-hover;
        }

        .deleteBtn {
          background-color: $danger-color;
          border: 1px solid $danger-color-darker;
          color: white;
        }

        .deleteBtn:hover {
          background-color: $danger-color-hover;
          border: 1px solid $danger-color-darker-hover;
        }
      }
    }
  }
}
