@import "../../../mainColorLibrary.scss";

$height: 55px;
$iconFontSize: 21px;

$animationsSpeed: 300ms;

.extraLinksDropdown {
  position: fixed;
  right: 8px;
  bottom: 16px;

  display: flex;
  flex-direction: column;
  gap: 2px;

  width: 200px;
  padding: 8px;
  padding-bottom: $height;
  border-radius: 20px;

  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);

  .dropdownLink {
    display: flex;
    align-items: center;
    gap: 5px;

    padding: 8px;
    border-radius: 12px;

    color: black;
    text-decoration: none;

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 30px;
      aspect-ratio: 1;

      border-radius: 100px;

      i {
        font-size: 18px;
        color: #40493b;
      }
    }

    .linkName {
    }
  }
}

.mobileFooter_v2 {
  position: fixed;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100% - 16px);
  height: $height;
  border-radius: 100px;

  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);

  .pageLink {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    color: #40493b;
    text-decoration: none;
    line-height: 1;

    .linkIcon {
      font-size: 22px;
    }

    .cartTotalItems {
      position: absolute;
      top: -0px;
      left: 50%;
      transform: translate(-50%);

      font-size: 13px;

      color: white;
    }

    .linkName {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4;
    }

    .activeLinkLine {
      height: 2px;
      width: 30px;
      border-radius: 10px;
      background-color: transparent;
    }
  }

  .activePageLink {
    .linkIcon {
      color: $mainColor-1;
    }

    .activeLinkLine {
      background-color: $mainColor-1;
    }
  }
}
