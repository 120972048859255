@import "../../mainColorLibrary.scss";

$cardHeight: 230px;
$cardWidth: 165px;

$logoHeight: $cardHeight/1.8;

$borderRadius: 8px;

.productCardContainer {
  display: flex;
  justify-content: center;

  .productCard {
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);

    height: $cardHeight;
    width: $cardWidth;

    border-radius: $borderRadius;

    margin: 5px 0px;

    .productCardLink {
      text-decoration: none;
      color: black;

      .contenPinned {
        position: relative;

        .isPinned {
          position: absolute;

          border-radius: 50%;
          margin: 2px;

          font-size: 20px;

          color: whitesmoke;
          z-index: 1;
          box-shadow: 10px -8px 15px 0px rgba(0, 0, 0, 0.6) inset;
        }
      }

      .imgContainer {
        position: relative;
        display: inline-block;
        background-color: rgb(170, 169, 169);
        border-radius: 1px solid white;

        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        height: $logoHeight;
        width: 100%;

        overflow: hidden;

        .productImg {
          display: block;

          height: 100%;
          width: 100%;

          object-fit: cover;
          object-position: center center;

          border-top-left-radius: $borderRadius;
          border-top-left-radius: $borderRadius;
        }

        .soldOutOverlay {
          position: absolute;

          display: flex;
          justify-content: center;
          align-items: center;

          background-color: rgba(0, 0, 0, 0.6);

          height: 100%;
          width: 100%;

          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .overlayText {
            font-size: 24px;
            color: rgb(253, 0, 0);

            padding: 5px 10px;

            border: 3px solid red;

            transform: rotateZ(-10deg);
          }
        }
      }

      .bottomContainer {
        position: relative;
        padding: 3px;

        .contentVerfied {
          height: 16px;
          .GoVerified {
            margin: 2px;

            font-size: 15px;

            color: #17d546;

            z-index: 1;
          }
        }

        .nameAndBusinessContianer {
          display: flex;
          justify-content: space-between;
          .productName {
            font-weight: 600;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .rating {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            width: 10px;
            height: 10px;

            border-radius: 50%;
            padding: 5px;

            span {
              color: rgb(34, 33, 33);
              font-size: 0.9em;
              font-weight: bold;
            }

            border: 2px solid rgb(212, 176, 92);
            background-color: rgba(211, 211, 211, 0.74);

            box-shadow: 10px -8px 15px 0px rgba(0, 0, 0, 0.15) inset;
          }
        }

        .priceContainer__oldPrice {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .price {
            display: flex;
            align-items: center;
            flex-direction: row;
            .integer {
              font-size: 14px;
              text-decoration: line-through;
              color: #636060;
            }
          }

          .currency {
            margin-left: 4px;
            font-size: 14px;
            text-decoration: line-through;
            color: #636060;
          }
        }

        .priceContainerDiscount {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .price {
            display: flex;
            align-items: center;
            flex-direction: row;

            .integer {
              font-size: 15px;
            }
          }
          .currency {
            font-size: 12px;
            margin-left: 4px;
          }

          .porcentage {
            margin-left: 4px;
            font-size: 11px;

            color: #00a650; //color mercado libre
          }
        }

        .priceContainer {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          margin-top: 8px;

          .price {
            display: flex;
            align-items: center;
            flex-direction: row;

            .integer {
              font-size: 18px;
            }
          }
          .currency {
            font-size: 14px;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
