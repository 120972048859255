.pageNotFound {
  height: 80vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

  .contentContainer {
    width: 80%;
  }

  p {
    text-align: center;

    color: #000000;

    font-size: 24px;
    font-family: "Montserrat", sans-serif;
  }

  .goBackToHomeBtn {
    text-decoration: none;

    button {
      display: block;

      background-color: transparent;
      color: #000;
      border: none;

      opacity: 1;

      cursor: pointer;

      font-family: "Montserrat", sans-serif;
      font-size: 20px;

      margin: 0 auto;
      padding: 10px 20px;

      animation: pulse 2s infinite;
    }
  }

  .hanging404TextContainer {
    display: flex;
    justify-content: center;

    .box-not-found {
      display: flex;
      justify-content: center;
      text-align: center;

      width: 50%;
      margin: 0;
    }
  }

  .not-found-animation {
    display: inline-block;
    animation: hinge 1s 2s forwards;
    @media only screen and (min-width: 601px) {
      font-size: 128px;
    }
    font-size: 100px;

    background: -webkit-linear-gradient(#1f551f, #2f302e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hinge-falling {
    animation: hinge-falling 2s forwards infinite;
  }

  @keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes hinge {
    0% {
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }

    20%,
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 80deg);
      transform: rotate3d(0, 0, 1, 80deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }

    40%,
    80% {
      -webkit-transform: rotate3d(0, 0, 1, 60deg);
      transform: rotate3d(0, 0, 1, 60deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  }

  @keyframes hinge-falling {
    0%,
    50%,
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 80deg);
      transform: rotate3d(0, 0, 1, 80deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }

    25%,
    75% {
      -webkit-transform: rotate3d(0, 0, 1, 60deg);
      transform: rotate3d(0, 0, 1, 60deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  }

  @keyframes background {
    from {
      background-color: #fff;
    }

    to {
      background-color: #eee;
    }
  }
}
