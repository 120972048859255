@import "../../mainColorLibrary.scss";

$inputContainerMaxWidth: 400px;

.updateProductPage {
  @media only screen and (min-width: 1050px) {
    display: flex;
    flex-wrap: nowrap;
  }

  .dummyProductCardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media only screen and (min-width: 1050px) {
      width: 100%;
    }

    .tallAddProductCardContainer {
      width: 100%;
    }

    h3 {
      text-align: center;
      width: 100%;
    }

    .wideAddProductCardContainer {
      background-color: rgb(240, 240, 240);
      max-width: 500px;
    }
  }

  .productFormContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .updateProductForm {
      @mixin InputTitlePrefab() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        width: $inputContainerMaxWidth;

        .inputTitle {
          display: flex;
          justify-content: center;

          width: 90%;
          margin-bottom: 5px;

          .inputTitleText {
            text-align: start;

            width: 100%;

            font-size: 20px;
            font-weight: 500;
          }

          .inputIntrusctionText {
            font-size: 5px;
          }
        }

        .icon-container {
          .active {
            font-size: 3em;
            color: rgb(37, 205, 22);
          }
          .not-active {
            font-size: 3em;
            color: black;
          }

          .available {
            font-size: 3em;
            color: rgb(0, 187, 45);
          }

          .exhausted {
            font-size: 3em;
            color: rgb(255, 0, 0);
          }
        }
      }

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .allInputsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        max-width: 450px;

        @media only screen and (min-width: 880px) {
          display: grid;
          grid-template-columns: 1fr;
        }

        .imageInputContainer {
          @include InputTitlePrefab();

          .customFileUpload {
            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgb(230, 230, 230);
            color: rgb(152, 152, 152);
            border: 1px solid rgb(152, 152, 152);

            height: 40px;
            width: 92%;
            max-width: 400px;

            border-radius: 5px;

            font-size: 18px;

            cursor: pointer;

            input {
              display: none;
            }
          }
        }

        .inputContainer {
          @include InputTitlePrefab();

          input {
            border: 1px solid rgb(141, 141, 141);

            height: 40px;
            width: 90%;
            max-width: 400px;

            border-radius: 5px;
          }

          textarea {
            border: 1px solid rgb(141, 141, 141);

            height: 40px;
            width: 90%;
            max-width: 400px;

            border-radius: 5px;

            resize: none;
          }
          select {
            border: 1px solid rgb(141, 141, 141);

            height: 40px;
            width: 90%;
            max-width: 400px;

            border-radius: 5px;
          }

          .imageInput {
            border: none;

            height: 40px;
            width: 90%;
            max-width: 400px;

            border-radius: 0px;
          }
        }

        .visibleToSubscribersInputContainer {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          width: $inputContainerMaxWidth;

          .inputTitle {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            width: 90%;
            margin-bottom: 5px;

            .inputTitleText {
              text-align: start;

              width: 100%;

              font-size: 20px;
              font-weight: 500;
            }

            .inputSubtitleText {
              text-align: start;

              width: 100%;

              font-size: 14px;
            }
          }

          .visibilityBtnsContainer {
            @mixin buttonStyles($bgColor, $textColor) {
              display: flex;
              justify-content: center;
              align-items: center;

              background-color: $bgColor;
              color: $textColor;
              border: 1px solid transparent;

              height: 30px;
              width: 100%;
              padding: 5px;
              border-radius: 5px;

              font-size: 18px;
            }

            width: 90%;

            .visibleToAllBtn {
              cursor: pointer;
              @include buttonStyles(
                $bgColor: rgb(200, 200, 200),
                $textColor: black
              );
            }
            .onlyVisibleToSubsBtn {
              cursor: pointer;
              @include buttonStyles(
                $bgColor: rgb(200, 200, 200),
                $textColor: black
              );
            }
          }
        }
      }

      .saveAndCancelBtnsContainer {
        display: flex;
        justify-content: space-between;

        width: 80%;
        max-width: 400px;

        margin-bottom: 10px;

        .deleteBtnContainer {
          button {
            background-color: $danger-color;
            border: 1px solid $danger-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $danger-color-hover;
            border: 1px solid $danger-color-darker-hover;
          }
        }

        .saveBtnContainer {
          button {
            background-color: $CTA-color;
            border: 1px solid $CTA-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $CTA-color-hover;
            border: 1px solid $CTA-color-darker-hover;
          }
        }
      }
    }
  }
}
