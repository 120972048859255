.modalWrapperNotification {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 200;
  margin: 5px;
  margin-right: 2%;
}

.modalContainerNotification {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;

  padding: 4px;

  background-color: whitesmoke;

  border-radius: 5px;

  height: 50px;
  width: 180px;
}

.icon {
  font-size: 3em;
}
