@import "../../../../../styles/mainColorLibrary.scss";

.myBusinessServicePage {
  .servicePinnedCardsContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    margin-bottom: 10px;

    overflow-y: hidden;
    overflow-x: auto;

    .servicePinnedCardsWrapper {
      display: flex;
      gap: 5px;

      width: 100%;

      @media only screen and (min-width: 800px) {
        justify-content: center;
      }

      transition: ease all 0.5s;
    }
  }

  .ServiceCardsContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .ServiceCardsWrapper {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      place-items: center;

      width: 100%;

      @media only screen and (min-width: 600px) {
        width: 98.6%;
      }

      transition: ease all 0.5s;

      .warpperCard {
        width: 100%;
        height: 100%;

        .contentLink {
          display: flex;
          justify-content: center;

          .actionBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $CTA-color;
            border: 1px solid $CTA-color-darker;
            color: white;
            height: 30px;
            width: 90px;
            margin: 5px;
            border-radius: 5px;
            text-decoration: none;
            cursor: pointer;
            transition: 100ms;

            &:hover {
              background-color: $CTA-color-hover;
              border: 1px solid $CTA-color-darker-hover;
            }
          }
        }
      }
    }
  }
}

//Modal Delete

.modalDeleteService {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sectionLeft {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contentPhoto {
      img {
        width: 120px;
      }
    }
    .contentName {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 18px;

      .textQuestions {
        margin-right: 5px;
      }
      .textName {
        font-weight: 600;
      }
    }
  }

  .sectionRight {
    @mixin Button() {
      margin: 10px 10px;
      height: 40px;
      width: 100px;
      border-radius: 5px;
      transform: 100ms;
      font-size: 16px;
    }

    .btnCancel {
      @include Button;
      color: whitesmoke;
      background: $CTA-color-3;
      border: $CTA-color-3-darker;
    }
    .btnDelete {
      @include Button;

      color: whitesmoke;
      background: $danger-color;
      border: $danger-color-darker;
    }
  }
}
