@import "../../../mainColorLibrary.scss";

.myBusinessCardContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(221, 221, 221);

  max-width: 300px;
  padding: 0px 5px;
  border-radius: 7px;

  margin: 10px 10px 0px 10px;

  .title {
    text-align: center;
    font-size: 20px;

    width: 100%;
    margin-bottom: 15px;
  }

  .myCompanyCardContainer {
    width: 100%;
    margin: 0px 5px;

    .companyCardContainer {
      border-radius: 5px;

      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

      overflow: hidden;
    }

    .editBtnContainer {
      display: flex;
      justify-content: center;

      .editCardLink {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $CTA-color;
        border: 1px solid $CTA-color;
        color: white;

        border-radius: 5px;
        padding: 5px 15px;

        text-decoration: none;

        margin-top: 5px;
        margin-bottom: 10px;
      }

      .editCardLink:hover {
        background-color: $CTA-color-hover;
        border: 1px solid $CTA-color-darker-hover;
      }
    }
  }
}
