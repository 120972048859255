.containerDiscount {
  .discount-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .price {
    font-size: 22px;
    font-weight: 500;
    color: #2b2b2b;
  }

  .original-price {
    font-size: 16px;
    font-weight: 600;
    color: #535353;

    text-decoration: line-through;
  }

  .discount-details {
    .discount-percentage {
      font-size: 16px;
      margin-left: 4px;
      font-weight: 600;
      color: #27ac27; /* Color rojo para el porcentaje de descuento */
    }

    .discounted-price {
      font-size: 20px;
      font-weight: 600;
      color: black; /* Color verde para el precio con descuento */
    }
  }
}
