.productsPage {
  .hasMemberOnlyProducts {
    width: 100%;

    // margin: 10px 20px 30px 20px;
    margin: 10px 0px 25px 0px;

    .contentMsj {
      text-align: center;
      font-size: 15px;
      font-weight: 500;

      // width: 100%;

      .textMsj {
        color: red;
        text-decoration: none;
      }
    }
  }

  .productPinnedCardsContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    margin-bottom: 10px;

    overflow-y: hidden;
    overflow-x: auto;

    .productPinnedCardsWrapper {
      display: flex;
      gap: 5px;

      width: 100%;

      @media only screen and (min-width: 800px) {
        justify-content: center;
      }

      transition: ease all 0.5s;
    }
  }

  .productCardsContainer {
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 5px;

    .productCardsWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      place-items: center;
      grid-gap: 10px;

      width: 100%;

      transition: ease all 0.5s;
    }
  }
}

.loadMoreContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .loadMoreButton {
    border: none;
    color: white;
    background-color: #2d6e8a;

    font-size: large;
    font-weight: bold;

    padding: 6px;
  }
}
