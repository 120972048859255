@import "../../../../mainColorLibrary.scss";
@import "../../../../commonStyles.scss";

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
}

$inputContainerMaxWidth: 400px;

.putAbout {
  .imageAbout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    margin: 5px;
    .img {
      border: 5px;
      max-width: 50%;
      width: 50%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .imageInputContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .customFileUpload {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: rgb(230, 230, 230);
      color: rgb(152, 152, 152);
      border: 1px solid rgb(152, 152, 152);

      height: 40px;
      width: 90%;
      max-width: 400px;

      border-radius: 5px;

      font-size: 18px;

      cursor: pointer;

      input {
        display: none;
      }
    }
    .customFileUpload.error {
      border: 2px solid red;
    }
  }

  .putAboutForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @mixin InputTitlePrefab() {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;

      width: $inputContainerMaxWidth;

      .inputTitle {
        display: flex;
        justify-content: center;

        width: 90%;
        margin-bottom: 5px;

        .inputTitleText {
          text-align: start;

          width: 100%;

          font-size: 20px;
          font-weight: 500;
        }
      }
    }
    .inputContainer {
      @include InputTitlePrefab();
      margin-top: 10px;
      margin-bottom: 10px;

      input {
        border: 1px solid rgb(141, 141, 141);

        height: 40px;
        width: 90%;
        max-width: 400px;

        border-radius: 5px;

        &.error {
          border: 2px solid red;
        }
      }

      textarea {
        border: 1px solid rgb(141, 141, 141);

        height: 200px;
        width: 90%;
        max-width: 400px;

        border-radius: 5px;

        &.error {
          border: 2px solid red;
        }
      }
    }

    .saveBtnContainer {
      display: flex;
      justify-content: center;

      button {
        @include Button($CTA-color, white, $CTA-color);
      }

      button:hover {
        @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
      }
    }
  }
}
