@import "../../../mainColorLibrary.scss";

.ReviewsCompany {
  .yourRatingText {
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 5px;
  }

  .yourRatingContainer {
    margin-top: 5px;
    margin-left: 5px;

    .yourRating {
      width: 90%;
      max-width: 600px;
      padding: 5px;

      .topSection {
        display: flex;
        align-items: center;
        gap: 10px;

        .profileImgContainer {
          height: 45px;
          width: 45px;
          min-height: 45px;
          min-width: 45px;

          .profileImg {
            height: 100%;
            width: 100%;

            border-radius: 100%;

            object-fit: cover;
            object-position: center center;
          }
        }

        .submiterNameAndStarsContainer {
          .submiterDate {
            font-weight: 300;
            font-size: 0.8em;
          }
          .submiterName {
            font-weight: 500;
          }

          .starsContainer {
            font-size: 18px;
            display: flex;
            flex-wrap: nowrap;
          }
        }
      }

      .reviewText {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .editReviewBtnContainer {
      margin-left: 10px;

      .editReviewBtn {
        background-color: $CTA-color; // CTA
        color: rgb(255, 255, 255);
        border: 1px solid $CTA-color-darker;

        font-size: 16px;

        border-radius: 5px;
        padding: 5px 12px;
      }
    }
  }

  .addReviewBtnContainer {
    display: flex;
    justify-content: center;

    .addReviewBtn {
      background-color: $CTA-color; // CTA
      color: rgb(255, 255, 255);
      border: 1px solid $CTA-color-darker;

      height: 40px;
      width: 90%;
      max-width: 400px;
      border-radius: 7px;

      font-size: medium;
    }
  }
}
