@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

.aboutBusiness {
  height: 100%;

  .addBtnContainer {
    margin-top: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    .addBtn {
      @include Button($CTA-color, white, $CTA-color);
      box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);

      padding: 8px 13px;
      font-size: 18px;
    }

    .addBtn:hover {
      @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
      box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.5);

      padding: 10px 16px;
      font-size: 20px;
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    h2 {
      margin-left: 15px;
    }

    .updateAboutLink {
      @include Button($CTA-color, white, $CTA-color);
    }

    .updateAboutLink:hover {
      @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
    }
  }

  .companyAbout {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;

    .aboutDoesntExistTxt {
      text-align: center;
      font-weight: 500;
      font-size: 18px;

      margin-top: 10px;
    }

    .companyAboutContainer {
      display: flex;
      // max-height: 800px;

      .imgContainer {
        display: flex;
        justify-content: center;

        height: auto;
        width: 45%;

        .aboutUsImage {
          width: 95%;
          max-height: 1200px;

          height: auto;
        }
      }

      .companyAboutInfo {
        width: 55%;
        // background-color: red;

        .title {
          font-size: 20px;
          // font-weight: 500;
        }
      }
    }
  }

  /* Styles for phone screens */
  @media (max-width: 900px) {
    .companyAbout {
      height: 100%;
      width: 100%;

      .titleContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 10px;

        .updateAboutLink {
          @include Button($CTA-color, white, $CTA-color);
        }

        .updateAboutLink:hover {
          @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
        }
      }

      .companyAboutContainer {
        display: flex;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .imgContainer {
          display: flex;
          justify-content: center;

          height: auto;
          width: 100%;

          .aboutUsImage {
            width: 95%;
            height: auto;
            max-height: 1200px;
          }
        }

        .companyAboutInfo {
          height: 100%;
          width: 95%;

          .title {
            width: 100%;
            font-size: 22px;
          }

          p {
            width: 100%;
          }
        }
      }
    }
  }
}
