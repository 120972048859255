@import "../../../mainColorLibrary.scss";

$smallSidebarHeight: 100%;
$smallSidebarWidth: 80px;
$iconFontSize: 25px;

@mixin pageLink() {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  color: white;

  text-decoration: none;

  // height: 40px;
  padding: 5px 0px;
  width: $smallSidebarWidth/1.1;

  border-radius: 8px;

  margin-bottom: 8px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .linkIcon {
      font-size: 20px;
    }

    i {
      font-size: $iconFontSize;
    }
  }

  .cartIconContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    i {
      font-size: $iconFontSize;
    }

    .cartTotalItems {
      position: absolute;
      color: $deliveryMainColor-1;

      font-size: 12px;
      font-weight: 700;

      margin-left: 3px;
      margin-top: -1px;
    }
  }

  .linkTextContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .linkText {
      font-size: 10px;
    }
  }
}

.DeliverysmallSidebar {
  margin-top: 50px;

  position: fixed;

  background-color: $deliveryMainColor-1;
  color: white;

  border-top: 1px solid transparent;
  // border-top: 1px solid rgb(106, 143, 102);

  height: $smallSidebarHeight;
  width: $smallSidebarWidth;

  z-index: 2;

  .linksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .activePageLink {
      @include pageLink();
      background-color: $deliveryMainColor-2;
    }

    .pageLink {
      @include pageLink();
    }
    .pageLink:hover {
      background-color: $deliveryMainColor-2;
    }
  }
}
