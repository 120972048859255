.RatingAndCommentCompanies {
  .backToCompanyText {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    color: rgb(2, 38, 65);

    text-decoration: none;

    gap: 3px;
    margin: 2px 0px 0px 2px;

    i {
      font-size: 20px;
      // margin-right: 5px;
    }
  }

  .conetentLoadMoreBtn {
    .loadMoreBtn {
      margin: 10px;
      padding: 4px;
      font-weight: 580;
      border: none;
      color: white;
      background: #2d6e8a;
    }
  }

  .title {
    text-align: center;
  }

  .userRatingContainer {
    // display: flex;
    // justify-content: center;

    margin-bottom: 10px;

    .userRating {
      width: 90%;
      max-width: 600px;
      padding: 5px;

      margin-left: 5px;

      .topSection {
        display: flex;
        align-items: center;
        gap: 10px;

        .profileImgContainer {
          height: 45px;
          width: 45px;
          min-height: 45px;
          min-width: 45px;

          .profileImg {
            height: 100%;
            width: 100%;

            border-radius: 100%;

            object-fit: cover;
            object-position: center center;
          }
        }

        .submiterNameAndStarsContainer {
          .nameAndDateContainer {
            display: flex;
            flex-wrap: nowrap;
            gap: 5px;

            .submiterName {
              display: flex;
              align-items: center;

              font-weight: 500;
            }

            .submiterDate {
              display: flex;
              align-items: center;
              font-size: 13px;
            }
          }

          .starsContainer {
            font-size: 14px;
            display: flex;
            flex-wrap: nowrap;

            min-width: 80px;
            max-width: 80px;
          }
        }
      }

      .reviewText {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
