@import "../../mainColorLibrary.scss";

$inputContainerMaxWidth: 400px;

.CreatedCompanyPage {
  .error-message {
    font-size: 0.9em;
    font-weight: 400;
    margin: 2px 0px 5px 0px;
  }

  .dummyCompanyCardAndBannerContainer {
    margin-bottom: 20px;

    .bannerImageContainer {
      .bannerImage {
        width: 100%;
        aspect-ratio: 3/0.83;

        object-fit: cover;
        object-position: center center;
      }
    }

    .sectionTitle {
      text-align: center;
    }

    .companyCardContainerWrapper {
      display: flex;
      justify-content: center;

      .dummyCompanyCardContainer {
        position: relative;

        max-width: 350px;

        .companyCard_v2 {
          .infoContainer {
            position: absolute;
            bottom: 0;

            .adjustForPadding {
              padding: 25px 5px 5px 5px;

              .nameAndLocationContainer {
                .nameText {
                  font-size: 18px;

                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }

                .locationText {
                  color: rgb(214, 214, 214);
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 1;
                }
              }

              .bottomContainer {
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
  }

  .companyFormContainer {
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    .contentUploadedImage {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;

      max-width: 450px;

      @mixin InputTitlePrefab() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

        width: $inputContainerMaxWidth;

        .inputTitle {
          display: flex;
          justify-content: center;

          width: 90%;
          margin-bottom: 5px;

          .inputTitleText {
            text-align: start;

            width: 100%;

            font-size: 20px;
            font-weight: 500;
          }
        }
      }

      .imageInputContainer {
        .companyBannerImg {
          $bannerImageHeight-1: 100px;
          $bannerImageHeight-2: 120px;
          $bannerImageHeight-3: 150px;
          $bannerImageHeight-4: 180px;
          // ==============================
          $bannerImageHeight-5: 165px;
          $bannerImageHeight-6: 195px;
          $bannerImageHeight-7: 230px;
          $bannerImageHeight-8: 270px;
          $bannerImageHeight-9: 315px;
          // ==============================
          $bannerImageHeight-10: 305px;
          $bannerImageHeight-11: 360px;
          $bannerImageHeight-12: 415px;
          $bannerImageHeight-13: 485px;
          $bannerImageHeight-14: 565px;
          // ==============================

          $bannerImageMaxHeight: 700px;

          width: 100%;
          max-height: $bannerImageMaxHeight;

          object-fit: cover;
          object-position: center center;

          // ============================================
          // Desktop Banner
          // ============================================
          @media only screen and (max-width: 2370px) {
            height: $bannerImageHeight-14;
          }
          @media only screen and (max-width: 2060px) {
            height: $bannerImageHeight-13;
          }
          @media only screen and (max-width: 1800px) {
            height: $bannerImageHeight-12;
          }
          @media only screen and (max-width: 1570px) {
            height: $bannerImageHeight-11;
          }
          @media only screen and (max-width: 1380px) {
            height: $bannerImageHeight-10;
          }
          // ============================================
          // Tablet Banner
          // ============================================
          @media only screen and (max-width: 1200px) {
            height: $bannerImageHeight-9;
          }
          @media only screen and (max-width: 1100px) {
            height: $bannerImageHeight-8;
          }
          @media only screen and (max-width: 950px) {
            height: $bannerImageHeight-7;
          }
          @media only screen and (max-width: 820px) {
            height: $bannerImageHeight-6;
          }
          @media only screen and (max-width: 710px) {
            height: $bannerImageHeight-5;
          }
          // ============================================
          // Phone Banner
          // ============================================
          @media only screen and (max-width: 600px) {
            height: $bannerImageHeight-4;
          }
          @media only screen and (max-width: 560px) {
            height: $bannerImageHeight-3;
          }
          @media only screen and (max-width: 460px) {
            height: $bannerImageHeight-2;
          }
          @media only screen and (max-width: 380px) {
            height: $bannerImageHeight-1;
            width: 100vw;
          }
        }

        .customFileUpload {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: rgb(230, 230, 230);
          color: rgb(152, 152, 152);
          border: 1px solid rgb(152, 152, 152);

          height: 40px;
          width: 100%;
          max-width: 400px;
          padding: 0px 5px;

          border-radius: 5px;

          font-size: 18px;

          cursor: pointer;

          input {
            display: none;
          }
        }
        .customFileUpload.error {
          border: 2px solid red;
        }
      }

      .saveAndCancelBtnsContainer {
        display: flex;
        justify-content: center;

        width: 100%;
        max-width: 400px;

        .saveBtnContainer {
          button {
            background-color: $CTA-color;
            border: 1px solid $CTA-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $CTA-color-hover;
            border: 1px solid $CTA-color-darker-hover;
          }
        }
      }
    }

    .CreatedCompanyForm {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      max-width: 450px;

      @mixin InputTitlePrefab() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        width: $inputContainerMaxWidth;

        .inputTitle {
          display: flex;
          justify-content: center;

          width: 90%;
          margin-bottom: 5px;

          .inputTitleText {
            text-align: start;

            width: 100%;

            font-size: 20px;
            font-weight: 500;
          }
        }
      }

      .imageInputContainer {
        @include InputTitlePrefab();

        .companyBannerImg {
          max-width: 100%;
          height: auto;
        }

        .customFileUpload {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: rgb(230, 230, 230);
          color: rgb(152, 152, 152);
          border: 1px solid rgb(152, 152, 152);

          height: 40px;
          width: 100%;
          max-width: 400px;

          border-radius: 5px;

          font-size: 18px;

          cursor: pointer;

          input {
            display: none;
          }
        }
      }

      .inputContainer {
        @include InputTitlePrefab();

        input {
          border: 1px solid rgb(141, 141, 141);

          height: 40px;
          width: 90%;
          max-width: 400px;

          border-radius: 5px;

          &.error {
            border: 2px solid red;
          }
        }

        textarea {
          border: 1px solid rgb(141, 141, 141);

          height: 60px;
          width: 90%;
          max-width: 400px;

          border-radius: 5px;

          &.error {
            border: 2px solid red;
          }
        }

        select {
          border: 1px solid rgb(141, 141, 141);

          height: 40px;
          width: 91%;
          max-width: 400px;

          border-radius: 5px;

          &.error {
            border: 2px solid red;
          }
        }
      }

      .saveAndCancelBtnsContainer {
        display: flex;
        justify-content: space-between;

        width: 80%;
        max-width: 400px;

        .deleteBtnContainer {
          button {
            background-color: $danger-color;
            border: 1px solid $danger-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $danger-color-hover;
            border: 1px solid $danger-color-darker-hover;
          }
        }

        .saveBtnContainer {
          button {
            background-color: $CTA-color;
            border: 1px solid $CTA-color-darker;
            color: white;

            padding: 10px 15px;
            border-radius: 5px;

            font-size: 18px;
            font-weight: 500;

            margin-top: 5px;
          }

          button:hover {
            background-color: $CTA-color-hover;
            border: 1px solid $CTA-color-darker-hover;
          }
        }
      }
    }
  }
}
