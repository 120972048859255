@import "../../../mainColorLibrary.scss";
@import "../../../commonStyles.scss";

.putSocial {
  margin-top: 10px;

  .inputContainer {
    display: flex;
    justify-content: center;

    margin-bottom: 10px;

    label {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      width: 95%;
      max-width: 450px;

      font-size: 17px;

      input {
        background-color: rgb(235, 235, 235);
        border: 1px solid rgb(141, 141, 141);

        height: 30px;
        width: 100%;
        max-width: 400px;

        border-radius: 5px;
      }
    }
  }

  .saveBtnContainer {
    display: flex;
    justify-content: center;

    button {
      @include Button($CTA-color, white, $CTA-color);
      font-size: 16px;
    }

    button:hover {
      @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
    }
  }
}
