@import "../../../../styles/mainColorLibrary.scss";
@import "../../../../styles/commonStyles.scss";

.openingHoursContainer {
  .openButton {
    @include Button($CTA-color, white, $CTA-color);

    margin: 10px;
  }

  .weekdaysContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    place-items: center;
    gap: 10px;

    .businessHours {
      background: white;
      box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);

      height: 200px;
      width: 150px;
      padding: 5px;

      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        font-size: large;
      }

      .contentDay {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid gray;
        .day {
          font-weight: bold;
        }
      }

      .scheduleSection {
        padding-top: 2px;
        padding-bottom: 2px;
        border-bottom: 2px solid gray;
        .scheduleLabel {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          font-size: 0.9em;
        }

        .timeRange {
          .contentTime {
            display: flex;
            justify-content: space-evenly;
            .Time {
              display: flex;
              flex-direction: column;

              .label {
                font-size: 0.9em;
                color: black;
              }
            }
          }
        }
      }
    }
  }
}

.error {
  border-color: red;
  color: red;
}

.trMessageError {
  display: flex;
}

.contentModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 100;

  .background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .formPostModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #f0f0f0;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.3);

    max-width: 90%;
    padding: 10px;
    border-radius: 5px;

    .title {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 5px;

      font-weight: bold;
      font-size: large;

      border-bottom: 1px solid black;
    }

    .tableContainer {
      display: block;

      overflow-x: auto;
    }

    .contentButton {
      display: flex;
      justify-content: flex-end;
      text-align: center;
      gap: 10px;

      margin-top: 10px;

      .save {
        @include Button($CTA-color, white, $CTA-color);

        &:hover {
          @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
        }
      }

      .closed {
        @include Button($danger-color, white, $danger-color);

        &:hover {
          @include Button(
            $danger-color-hover,
            white,
            $danger-color-darker-hover
          );
        }
      }
    }
  }
}
