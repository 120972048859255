@import "../../../styles/mainColorLibrary.scss";
@import "../../../styles/commonStyles.scss";

$toggle-bg: #f0eb9d;
$toggle-fg: #ffd000;

$toggle-bg: #283452;
$toggle-fg: #00a6ff;

@mixin InputTitlePrefab() {
  .inputTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    margin-bottom: 5px;

    .inputTitleText {
      text-align: start;

      width: auto;

      font-size: 20px;
      font-weight: 500;

      .optionalTxt::before {
        content: "(optional)";
        color: grey;
      }

      .requiredTxt::before {
        font-size: 18px;
        content: "(required)";
        color: rgba(185, 11, 11, 0.773);
      }
    }
  }
}

@mixin InputConfigSizePrefab() {
  width: 98%;
  max-width: 400px;
  margin-bottom: 10px;
}

.formComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formInputPriceContainer {
  @include InputTitlePrefab();
  @include InputConfigSizePrefab();

  .priceCurrencyContainer {
    display: flex;
    align-items: center;
    input {
      border: 1px solid rgb(141, 141, 141);

      height: 40px;
      width: 78%;
      max-width: 400px;

      border-radius: 5px;
    }
    select {
      border: 1px solid rgb(141, 141, 141);

      height: 40px;
      width: 100%;
      max-width: 400px;

      border-radius: 5px;

      font-size: 16px;
    }
  }
}

.contentImg {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
  img {
    width: 300px;
    height: 200px;

    object-fit: cover;
  }
}

.formInputContainer {
  @include InputTitlePrefab();
  @include InputConfigSizePrefab();

  input,
  select,
  textarea {
    border: 1px solid black;
    border-radius: 5px;

    width: 97%;
    padding: 8px 5px;
  }

  select {
    width: 100%;
    font-size: 16px;
  }

  textarea {
    height: 160px;
    resize: vertical;
  }
}

.phoneNumberInputContainer {
  @include InputTitlePrefab();
  @include InputConfigSizePrefab();

  .inputsContainer {
    display: flex;
    gap: 3px;

    input,
    select {
      border: 1px solid black;
      border-radius: 5px;

      padding: 8px 5px;
    }

    input {
      width: 90%;
    }
  }
}

.formImageInputContainer {
  @include InputTitlePrefab();
  @include InputConfigSizePrefab();

  .customFileUpload {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(230, 230, 230);
    color: rgb(152, 152, 152);
    border: 1px solid rgb(152, 152, 152);

    height: 40px;
    width: 100%;
    max-width: 400px;

    border-radius: 5px;

    font-size: 18px;

    cursor: pointer;

    input {
      display: none;
    }
  }
}

.saveBtnContainer {
  display: flex;
  justify-content: center;

  gap: 20px;
  margin-bottom: 20px;

  button {
    @include Button($CTA-color, white, $CTA-color);
    width: 100px;

    &:hover {
      @include Button($CTA-color-hover, white, $CTA-color-darker-hover);
    }
  }

  .cancelBtn {
    @include Button($danger-color, white, $danger-color);

    &:hover {
      @include Button($danger-color-hover, white, $danger-color-darker-hover);
    }
  }
}

.toggleContainer {
  @include InputConfigSizePrefab();

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 15px;
  margin-top: 15px;

  .titleText {
    margin-left: 15px;

    width: auto;

    font-size: 20px;
    font-weight: 500;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff0000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: rgb(0, 187, 45);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px rgb(40, 171, 71);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.formContentSelect {
  @include InputTitlePrefab();
  @include InputConfigSizePrefab();

  .select {
    -webkit-box-align: center;
    display: flex;
    justify-content: space-between;
    border: 1px solid grey;
    border-radius: 5px;

    width: 100%;

    min-height: 30px;
    height: auto;

    padding: 5px 5px;
    overflow: hidden;
    box-sizing: border-box;

    select {
      width: 90%;
      padding: 0;
      border: none;
      outline: none;
    }

    .selectedOption {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      max-height: 100px;

      overflow-y: auto;

      .contentOption {
        display: flex;
        align-items: center;
        width: auto;
        white-space: nowrap;
        font-size: 85%;

        margin: 2px;

        .option {
          padding: 1px 2px;
          color: rgb(51, 51, 51);
          background: rgb(211, 211, 211);
          border-radius: 5px 0 0 5px;
        }

        button {
          padding: 1px 2px;
          background: rgb(211, 211, 211);
          border-radius: 0 5px 5px 0;
          align-items: center;
          padding-right: 5px;

          &:hover {
            background-color: rgb(255, 189, 173);
            color: rgb(222, 53, 11);
          }
        }
      }
    }
  }
}
