.errorMessage {
  color: red;
  font-weight: 500;
}

.wrapperSearchMessage {
  width: 100%;
  padding: 1%;
  background-color: rgb(225, 223, 223);

  border-radius: 5px;

  .contentMessage {
    display: flex;
    align-items: center;

    .sectionLeft {
      margin: 20px;
      transform: translate(35%, 15%);

      flex: 1;

      .iconSearchOff {
        color: #397420;
        font-size: 4em;
      }
    }

    .sectionRight {
      margin: 10px;
      padding: 10px;
      flex: 2;
      h1 {
        font-size: 1.6em;
      }

      ul {
        padding-left: 20px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .contentMessage {
      flex-direction: column;
      text-align: center;

      .sectionRight {
        margin-top: 10px;
      }

      .sectionLeft {
        margin-left: 1px;

        .iconSearchOff {
          font-size: 3em;
        }
      }
    }
  }
}
