@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

* {
  // outline: 1px solid rgb(255, 0, 0);
}

.checkoutPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pageTitle {
    text-align: center;
    font-weight: 500;
    font-size: 22px;

    padding: 5px 0px;

    margin-bottom: 10px;
  }

  .checkoutForm {
    width: 100%;
    max-width: 400px;
    margin: 0px 5px;

    .userAddressContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 10px;

      .title {
        font-weight: bolder;
        font-size: 18px;
        width: 95%;
      }

      .notCorrectAddressTxt {
        font-weight: bolder;
        font-size: 16px;
        width: 95%;

        .link {
          color: $CTA-color-3;
        }
      }
    }

    .inputContainer {
      margin-bottom: 12px;

      label {
        display: flex;
        flex-direction: column;
        align-items: center;

        .error-message {
          text-align: center;
          color: red;
        }

        .contentBtnLenguage {
          .wrapperBtn {
            display: flex;
            justify-content: center;
            align-items: center;

            .intruction {
              display: flex;
              align-items: center;
              color: black;
            }
            .btnMultilanguage {
              .multilanguage {
                display: flex;
                align-items: center;
                flex-direction: row;
                color: #007bff;

                .iconContainer {
                  font-size: 1.2em;
                  font-weight: 600;
                  margin-left: 5px;
                  margin-right: 5px;
                }
                .language {
                  margin-left: 2px;
                  margin-right: 2px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .inputTitle {
          display: flex;
          gap: 5px;

          font-weight: bolder;
          font-size: 16px;
          width: 95%;

          .optionalTxt::before {
            content: "(Optional)";
            color: rgb(154, 154, 154);
          }
        }

        .subtitleTxt {
          font-weight: 500;
          font-size: 14px;
          width: 95%;

          margin-bottom: 5px;
        }

        input {
          width: 95%;

          border: 1px solid #ccc;
          color: black;

          border-radius: 5px;

          padding: 8px 5px;

          font-size: 16px;
        }
        input.error {
          border: 2px solid red;
        }

        select {
          width: 98.5%;

          border: 1px solid #ccc;
          color: black;

          border-radius: 5px;

          padding: 8px 5px;

          font-size: 16px;
        }
        select.error {
          border: 2px solid red;
        }

        textarea {
          height: 75px;
          width: 95%;

          border: 1px solid #ccc;
          color: black;

          border-radius: 5px;

          padding: 8px 5px;

          font-size: 16px;

          resize: none;
        }

        .selectAndInputContainer {
          display: flex;
          gap: 2px;

          width: 98.5%;

          select {
            width: 80px;

            border: 1px solid #ccc;
            color: black;
            border-radius: 5px;

            padding: 8px 5px;

            font-size: 16px;
          }
          select.error {
            border: 2px solid red;
          }

          input {
            width: 100%;

            border: 1px solid #ccc;
            color: black;

            border-radius: 5px;

            padding: 8px 5px;

            font-size: 16px;
          }
          input.error {
            border: 2px solid red;
          }
        }

        .buttonsContainer {
          display: flex;
          justify-content: center;
          gap: 20px;

          width: 100%;

          margin-top: 10px;

          button {
            @include Button(
              rgb(190, 190, 190),
              rgb(0, 0, 0),
              rgb(190, 190, 190)
            );

            display: flex;
            justify-content: center;
            align-items: center;

            width: 45%;

            padding: 10px;

            font-size: 16px;

            cursor: pointer;

            &:hover {
              background-color: rgb(182, 182, 182);
              color: rgb(0, 0, 0);
              border-color: rgb(159, 159, 159);
            }
          }

          .btnGenerate {
            @include Button($CTA-color-3, rgb(255, 255, 255), $CTA-color-3);

            &:hover {
              @include Button(
                $CTA-color-3-hover,
                rgb(255, 255, 255),
                $CTA-color-3-darker-hover
              );
            }
          }
        }
      }
    }

    .submitBtnContainer {
      display: flex;
      justify-content: center;

      margin-top: 40px;
      margin-bottom: 20px;

      button {
        @include Button($CTA-color-3, white, $CTA-color-3);
        width: 95%;
        padding: 10px 0px;
      }
    }

    // ==========================

    .contentSelected {
      margin-bottom: 20px;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .selected {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
      }
    }

    .contentInstruction {
      margin-bottom: 20px;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .textareaInstrucution {
        width: 95%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        resize: vertical; /* Permite que el textarea sea redimensionable verticalmente */
      }
    }

    .contentGeneratedWord {
      margin-bottom: 20px;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .contentIntruction {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .contentKeyWord {
        width: 95%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .contentButton {
        display: flex;
        justify-content: space-between;

        .btnGenerate,
        .btnEmpty {
          width: 48%; /* Divide el espacio en dos para los botones */
          padding: 10px;
          font-size: 16px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .btnGenerate {
          background-color: #007bff;
          color: #fff;
        }

        .btnEmpty {
          background-color: #ccc;
          color: #333;
        }
      }
    }

    .contentBackupNumber {
      margin-bottom: 20px;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .btnTrueORFalse {
        margin-bottom: 10px;

        button {
          padding: 10px;
          font-size: 16px;
          border: none;
          border-radius: 5px;
          cursor: pointer;

          &.on {
            background-color: #007bff;
            color: #fff;
          }

          &.off {
            background-color: #ccc;
            color: #333;
          }
        }
      }

      .contentInput {
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }

    .contentBtnSubmit {
      text-align: center;
      margin-top: 10px;
      .btnSubmit {
        width: 100%;
        padding: 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;

        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
    }
  }
}
