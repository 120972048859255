@import "../../mainColorLibrary.scss";
@import "../../commonStyles.scss";

.extraImagesUploader {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 400px;

  .sectionTitle {
    text-align: center;

    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
  }

  .canAddNumMoreImagesTxt {
    color: rgb(210, 35, 35);

    text-align: center;

    font-weight: 500;
    font-size: 18px;
  }

  .addImagesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 90%;

    margin-top: 10px;
    margin-bottom: 20px;

    .dropTitle {
      font-size: 20px;
      font-weight: 500;
    }

    .customUploadFilesBtn {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #d7d7d7;
      color: rgb(92, 92, 92);
      border: 1px solid #b6b6b6;
      border-radius: 7px;

      width: 90%;
      max-width: 400px;
      padding: 8px 0px;

      font-weight: 500;

      cursor: pointer;

      transition: background 0.2s ease-in-out;

      input[type="file"] {
        display: none;
      }
    }
  }

  .bottomSection {
    margin-top: 10px;

    .imgWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      margin-bottom: 10px;

      img {
        height: 100%;
        width: 100%;
        max-height: 200px;
        max-width: 160px;

        border: 1px solid rgb(231, 231, 231);

        object-fit: cover;
        object-position: center center;
      }

      button {
        @include Button(#9b9b9b, white, #9b9b9b);
        border-width: 2px;
        font-size: 18px;
      }
    }

    .emptySlotsContainer {
      .emptySlot {
        display: flex;
        justify-content: center;
        align-items: center;

        border: 2px solid #555;

        width: 100%;
        padding: 3px;

        margin: 0 2px 0 2px;
      }
    }

    .progressBarContainer {
      width: 100%;
      height: 24px;
      background-color: #a9a9a9;
      border: 1px solid #ccc;
      border-radius: 4px;
      position: relative;
      margin-top: 10px;

      .progressBar {
        width: 5px;
        height: 100%;
        background-color: #55ad2e;
        border-radius: 4px;
        transition: width 0.3s ease-in-out;
      }

      .progressText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: #333;
        font-weight: bold;

        text-align: center;

        width: 150px;
      }
    }
  }
}
