.contentAdress {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.adressUserContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 400px;
}

.addAdressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 400px;
}

.updateAdressLink,
.addAdressLink {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #007bff;
  color: #ffffff;

  width: 70%;
  max-width: 300px;

  border-radius: 4px;

  padding: 8px 16px;
  margin-top: 10px;

  text-decoration: none;

  transition: background-color 120ms ease;

  &:hover {
    background-color: #0056b3;
  }
}
