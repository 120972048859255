@import "../../../mainColorLibrary";

.contentOrders {
  .title {
    font-size: 1.8em;
  }
  .sectionCradOrders {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    transform: opacity 0.2s ease;
  }

  .contentLoadMore {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 20px;
    .loadMoreButton {
      border: none;
      color: white;
      background-color: #2d6e8a;
      font-size: large;
      font-weight: bold;
      padding: 6px;
    }
  }
}

.wrapperCard {
  text-decoration: none;

  background-color: $deliveryMainColor-3;
  border-radius: 5px;

  margin: 5px;

  .contentCard {
    padding: 5px;

    .infoDate {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      .title {
        font-size: 18px;
        color: black;
      }

      .info {
        font-size: 18px;
        color: black;
      }
    }

    .infoContainer {
      .infoTxt {
        display: flex;
        flex-direction: column;
        margin: 5px;
        .title {
          font-size: large;
          color: black;
        }

        .info {
          margin-left: 10px;
          color: black;
        }
      }
    }
  }
}
