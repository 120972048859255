@import "../../../mainColorLibrary.scss";

$largeSidebarHeight: 100%;
$largeSidebarWidth: 220px;
$sidebarBtnIconFontSize: 22px;

@mixin pageLink {
  display: flex;
  align-items: center;

  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;

  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
  }

  .cartIconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;

    .cartTotalItems {
      position: absolute;
      color: $myBusinessMainColor-1;

      font-size: 12px;
      font-weight: 700;

      margin-left: 3px;
      margin-top: -1px;
    }
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

@mixin dropDownBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;

  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
  }

  .cartIconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;

    .cartTotalItems {
      position: absolute;
      color: $myBusinessMainColor-1;

      font-size: 12px;
      font-weight: 700;

      margin-left: 3px;
      margin-top: -1px;
    }
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

@mixin button {
  display: flex;
  align-items: center;

  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;
  border: none;
  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
    margin: 8px;
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

.myBusinessLargeSidebar {
  margin-top: 50px;

  position: fixed;

  background-color: $myBusinessMainColor-1;
  color: white;

  height: $largeSidebarHeight;
  width: $largeSidebarWidth;

  z-index: 2;

  .topLinksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: 10px;

    border-bottom: 1px solid rgba(156, 206, 149, 0.521);

    .dropDownBtn {
      @include dropDownBtn();
    }

    .dropDownBtn:hover {
      background-color: $myBusinessMainColor-2;
    }

    .dropdown-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 10px;

      border-bottom: 1px solid rgba(156, 206, 149, 0.521);
    }

    .activePageLink {
      @include pageLink();
      background-color: $myBusinessMainColor-2;
    }

    .pageLink {
      @include pageLink();
    }

    .pageLink:hover {
      background-color: $myBusinessMainColor-2;
    }
  }

  .categoriesSectionContainer {
    justify-content: center;

    border-bottom: 1px solid rgba(156, 206, 149, 0.521);

    margin-top: 5px;

    .categoriesTitleContainer {
      width: $largeSidebarWidth/1.15;

      .titleText {
        font-size: 17px;
        font-weight: 400;
        text-align: center;
        margin-left: 5px;
        margin-bottom: 5px;
      }

      .pageLink {
        @include pageLink();
      }

      .pageLink:hover {
        background-color: $myBusinessMainColor-2;
      }

      .showMoreBtn {
        display: flex;
        align-items: center;

        background-color: transparent;
        color: white;
        border: 1px solid transparent;
        text-decoration: none;

        height: 40px;
        width: $largeSidebarWidth/1.15;

        border-radius: 8px;

        margin-bottom: 1px;

        .iconContainer {
          display: flex;
          justify-content: center;

          // background-color: red;

          width: 22px;

          font-size: $sidebarBtnIconFontSize;

          margin-left: 10px;
        }

        .showMoreText {
          margin-left: 25px;
          font-size: 14px;
        }
      }

      .showMoreBtn:hover {
        background-color: $myBusinessMainColor-2;
        cursor: pointer;
      }
    }

    .formcontentbutton {
      ::-webkit-scrollbar {
        display: none;
      }

      .allCategoriesContainer {
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        max-height: 430px;

        .contentcategries {
          display: flex;
          align-items: center;
          flex-direction: column;

          .pageLink {
            background-color: $myBusinessMainColor-1;
            @include button();
          }

          .pageLink:hover {
            background-color: $myBusinessMainColor-2;
          }

          .activePageLink {
            background-color: $myBusinessMainColor-2;
            @include button();
          }
        }
      }
    }
  }

  .extraInfoContainer {
    text-align: center;
    font-size: 12px;

    margin-top: 10px;

    a {
      color: white;
      text-decoration: none;
    }
  }
}
